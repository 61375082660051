<template>
<header>
    <div class="navbar">
        <h1 v-if="!isMobile" class="logo" @click="goToMainPage"> Planner</h1>
        <nav>
            <ul>
                <li @click="detailUser" data-cy="user-details"><font-awesome-icon icon="user"/></li>
                <li @click="logout" data-cy="logout">Se déconnecter</li>
            </ul>
        </nav>
    </div>
</header>   
</template>

<script>

export default ({
  name: 'ProHeaderLoggedIn',
  data() {
      return {
        isMobile: this.$store.state.isMobile
      }
  },
  methods : {
      goToMainPage() {
          this.$router.push('/pros/sessions')
      },
      detailUser() {
        this.$router.push('/pros/user')
      },
      logout(e) {
        e.preventDefault()
        this.$store.dispatch('logout').then( 
            () => {
                localStorage.removeItem('jwtToken')
                this.$router.push('/pros')
            }
        )
      }
  }
})
</script>