<template>
	<button data-cy="add_patient" @click="redirectToAddPatient">Ajouter</button>
	<div v-if="showDeleteModal" class="modal">
		<div class="modal-content">
			<p>Etes vous sûr de vouloir supprimer ce patient ? </p>
			<div class="modal-buttons">
				<button name="cancel" @click="showDeleteModal = false" class="cancel">Annuler</button>
				<button name="submit" @click="deletePatient" class="button button-danger">OK</button>
			</div>
		</div>
	</div>
	<div class="empty-content-container" v-if="sortedPatients.length === 0 && $store.getters.patients === 0">
		Aucun patient répertorié pour l'instant
	</div>
	<div v-else>

		<form class="apform">
			<div id="patient-filters">
				<div>
					<label for="patient-filter">Patients: </label>
					<select v-model="patientFilterValue" name="patient-filter" class="select-patient-club-animal" @change="updatePatientTypeFilter">
						<option v-for="filter in patientTypesFilter" :key="filter" :value="filter.value" :selected="patientFilterValue === filter.value">{{filter.toDisplay}}</option>
					</select>
				</div>
				<div>
					<label for="patient-name-filter">Nom: </label>
					<input name="patient-name-filter" type="text" v-model="patientNameFilter" @input="filterPatientsByName"/>
				</div>
				<div @click="showFilters = !showFilters" class="pointer">
					<font-awesome-icon icon="sliders" title="filter" class="filter-icon"/>Filtres
				</div>
				<div v-if="showFilters" class="patients-filters-container">
					<div @click="showFilters = false" class="patients-filters-container-close-button">
						<font-awesome-icon icon="xmark" title="fermer"/>
					</div>
					<h4 class="patients-filters-titles">Type d'activité</h4>
					<div v-for="sessionType in sessionTypes" :key="sessionType.id" class="patients-filters-session-types-checkboxes">
						<input type="checkbox" :value="sessionType.id" v-model="filterSessionTypes" />
						<label :for="sessionType.id">{{ sessionType.name }}</label>			
					</div>
					<h4 class="patients-filters-titles">Ayant eu une activité </h4>
					<div id="filter-patients-date-selection">
						<p><label :for="filterStartDate">Entre le</label><input name="filterStartDate" type="date" v-model="filterStartDate"> <font-awesome-icon icon="xmark" title="effacer date début" class="pointer" @click="filterStartDate = ''"/></p>
						<p><label :for="filterEndDate">Et le</label><input name="filterEndDate" type="date" v-model="filterEndDate"> <font-awesome-icon icon="xmark" title="effacer date fin" class="pointer" @click="filterEndDate = ''"/></p>
					</div>
					<button @click="filterPatients">Filtrer</button>
				</div>	
			</div>
		</form>
		

		<table class="data-table">
			<thead class="data-table-header">
				<tr>
					<th @click="sort('lastname')" :class="defineCarretClass('lastname')">Nom</th>
					<th @click="sort('firstname')" :class="defineCarretClass('firstname')">Prénom</th>
					<th v-if="!isMobile" @click="sort('email')" :class="defineCarretClass('email')">E-mail</th>
					<th v-if="!isMobile">Téléphone</th>
					<th v-if="!isMobile" @click="sort('birthdate')" :class="defineCarretClass('birthdate')">Date de naissance</th>
					<th v-if="!isMobile">Adresse</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(patient, index) in sortedPatients"
					v-bind:key="index"
					@mouseover="hover=index"
					@mouseleave="hover=-1"
					@click="routeToDetails(patient.id)"
				>
					<td style="width: 10%">{{ patientTypes[patient.type] === patientTypes.INDIVIDUAL ? patient.individual.lastname.toUpperCase() : patient.firm.companyName }}</td>
					<td style="width: 10%">{{ patientTypes[patient.type] === patientTypes.INDIVIDUAL ? patient.individual.firstname.charAt(0).toUpperCase() + patient.individual.firstname.slice(1).toLowerCase() : ''}}</td>
					<td v-if="!isMobile">{{ patient.email }}</td>
					<td v-if="!isMobile">{{ patient.phoneNumber }}</td>
					<td v-if="!isMobile">{{ patientTypes[patient.type] === patientTypes.INDIVIDUAL && patient.individual.birthdate ? patient.individual.birthdate.join('-').replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, '$3-$2-$1') : '-' }}</td>
					<td v-if="!isMobile && patient.address && patient.address.zipCode !== 0 && patient.address.streetName !== '' && patient.address.city !== ''">{{ patient.address.streetName }} - {{ patient.address.zipCode }} {{ patient.address.city }}</td>
					<td v-else></td>
					<td v-on:click.stop="" class="data-table-edit">
						<div v-if="hover === index" class="update-item">
							<font-awesome-icon icon="pen" @click="goToEditPatientForm(patient)" class="data-table-edit-icon"/>
							<font-awesome-icon icon="trash-alt" @click="displayDeleteModal(patient.id)" class="data-table-edit-icon"/>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import { PATIENTS_TYPES } from '../../../services/patientTypes'
import { getSessionTypes } from '../../../services/personalization'
import { filterPatients } from '../services/patients'

export default {
	// eslint-disable-next-line
	name: 'Patients',
	data() {
		return {
			showDeleteModal: false,
			hover: -1,
			currentSort:'lastname',
			currentSortDir:'asc',
			patientTypes: PATIENTS_TYPES,
			patientTypesFilter: this.createPatientTypesFilter(),
			patientFilterValue: 'ALL',
			filteredPatientList: [],
			isMobile: this.$store.state.isMobile,
			patientNameFilter: '',
			showFilters: false,
			sessionTypes: [],
			filterStartDate: '',
			filterEndDate: '',
			filterSessionTypes: []
		};
	},
	methods: {
		initFiltersDate() {
			const today = new Date()
			this.filterEndDate = today.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3')
		},
		filterPatients(e) {
			e.preventDefault()
			filterPatients(this.patientNameFilter, this.filterStartDate, this.filterEndDate, this.filterSessionTypes).then((response) => {
				this.filteredPatientList = response	
			})
		},
		getAllSessionTypes() {
            getSessionTypes().then((response) => {
                this.sessionTypes = response
            })
        },
		updatePatientTypeFilter() {
			if (this.patientFilterValue === 'ALL')
				this.filteredPatientList=this.$store.getters.patients;
			else
				this.filteredPatientList=this.$store.getters.patients.filter(patient => {
					return this.patientTypes[patient.type].value === this.patientFilterValue;
				})

		},
		createPatientTypesFilter() {
			let patientTypesFilter = Object.keys(PATIENTS_TYPES).map(index => {
				return PATIENTS_TYPES[index];
			});
			patientTypesFilter.push({
					value: 'ALL',
					toDisplay: 'Tous'
			});
			return patientTypesFilter;
		},
		sort(s) {		
			if(s === this.currentSort) {
				this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
			}
			this.currentSort = s;
		},
		defineCarretClass(criteria) {
			if (criteria === this.currentSort) {
				if (this.currentSortDir === 'asc')
					return 'carret-up';

				return 'carret-down';
			}
			return '';
		},
		sendInitPatients() {
			this.$store.dispatch('initPatients')
			.then(() => {
                //everything's fine, nothing to do
				this.filteredPatientList = this.$store.state.patients;
            }).catch(response => {
                if (response.status === 401) {
                    this.$store.dispatch('logout');
					this.$router.push('/');
				}

            });
		},
		redirectToAddPatient() {
			this.$store.dispatch('resetPatient');
			this.$router.push({name: 'CreatePatient'});
		},
		goToEditPatientForm(patient) {
			this.$store.dispatch({
                type: 'getPatientDetails',
                id: patient.id
            }).then(()=> this.$router.push({ name: 'EditPatient', params: { id : patient.id } }))
		},
		displayDeleteModal(patientId) {
			this.selectedPatientId = patientId;
			this.showDeleteModal = true;
		},
		deletePatient() {
			this.$store.dispatch({
				type: 'deletePatient',
				id: this.selectedPatientId,
			}).then(() => {
				this.showDeleteModal = false;
				this.$toast.success(`Patient correctement supprimé`);
				this.sendInitPatients(); //TODO a revoir probleme avec le state
			}).catch(() => {
				this.$toast.error(`Erreur lors de la suppression du patient.`);
			});
		},
		routeToDetails(patientId) {
			this.$router.push({ name: 'PatientWithId', params: { id : patientId } })
		},
		filterPatientsByName() {
			if (this.patientNameFilter === '') {
				this.filteredPatientList = this.$store.state.patients
			} else 
				this.filteredPatientList = this.$store.state.patients.filter(p=> {
					return (p.type === this.patientTypes.INDIVIDUAL.value && p.individual.lastname.toLowerCase().includes(this.patientNameFilter.toLowerCase())) || 
					(p.type === this.patientTypes.FIRM.value && p.firm.companyName.toLowerCase().includes(this.patientNameFilter.toLowerCase()))
				})	
		}
	},
	computed: {
		...mapState(['patients']),
		sortedPatients() {
			let patients = this.filteredPatientList;
			return patients.sort((a,b) => {
				let modifier = 1;
				if(this.currentSortDir === 'desc') modifier = -1;
				let left = a[this.currentSort];
				let right = b[this.currentSort];
				if (this.currentSort === 'lastname') {
					left = PATIENTS_TYPES[a.type] === PATIENTS_TYPES.INDIVIDUAL ? a.individual.lastname : a.firm.companyName;
					right = PATIENTS_TYPES[b.type] === PATIENTS_TYPES.INDIVIDUAL ? b.individual.lastname : b.firm.companyName;
				} else if (this.currentSort === 'firstname') {
					left = PATIENTS_TYPES[a.type] === PATIENTS_TYPES.INDIVIDUAL ? a.individual.firstname : a.firm.companyName;
					right = PATIENTS_TYPES[b.type] === PATIENTS_TYPES.INDIVIDUAL ? b.individual.firstname : b.firm.companyName;
				} else if (this.currentSort === 'birthdate') {
					left = PATIENTS_TYPES[a.type] === PATIENTS_TYPES.INDIVIDUAL ? a.individual.birthdate : '';
					right = PATIENTS_TYPES[b.type] === PATIENTS_TYPES.INDIVIDUAL ? b.individual.birthdate : '';
				}

				return left.localeCompare(right) * modifier;
			});
		}
	},
	mounted() {
		this.sendInitPatients()
		this.getAllSessionTypes()
		this.initFiltersDate()
	}
};
</script>
<style scoped>
#patient-filters {
	margin: 2rem 0 2rem 2rem;
	display: flex;
	justify-content: space-around;
}

.patients-filters-container {
	position: fixed;
	top:0;
	right: 0;
	max-width: 25%;
	max-width: 25%;
	height: 100%;
	background-color: #FFFFFF;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 3%;
}

.patients-filters-container-close-button {
	right: 2rem;
	font-size: 24px;
	text-align: right;
	cursor: pointer;
}

.patients-filters-session-types-checkboxes {
	display: flex;
}

.patients-filters-session-types-checkboxes label {
	margin-left: 0.5rem;
}

.patients-filters-titles {
	margin: 1rem 0;
}

.filter-icon {
	margin-right: 0.35rem;
	font-size: 16px;
}
</style>