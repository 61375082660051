<template>
	<div v-if="!mobile">
		<div class="angles-div">
			<font-awesome-icon icon="angles-left" class='ap-angle' v-if="hideMenu===false" @click="hideMenu = !hideMenu"/>
			<font-awesome-icon icon="angles-right" class='ap-angle' v-else @click="hideMenu = !hideMenu"/>
		</div>
		<div class="menu" v-if="hideMenu===false">
			<h4>
				<router-link class="link" to="/pros/sessions" v-if="sessionsIsEnable"><font-awesome-icon icon="calendar-alt" title="gérez vos séances" class="icon-menu-before"/>Rendez-vous</router-link>
				<router-link class="link" to="/pros/animals" v-if="animalsIsEnable"><font-awesome-icon icon="horse" title="Animaux" class="icon-menu-before"/>Animaux</router-link>
				<router-link class="link" to="/pros/clubs" v-if="clubsIsEnable"><font-awesome-icon icon="globe-europe" title="Clubs" class="icon-menu-before"/>Clubs</router-link>
				<router-link class="link" to="/pros/patients" v-if="patientsIsEnable" data-cy="patients_menu"><font-awesome-icon icon="address-book" title="Patients" class="icon-menu-before"/>Patients</router-link>
				<router-link to="/pros/estimations/details" v-if="estimationIsEnable">
					<div class="menu-with-add link">
						<font-awesome-icon icon="comment-dollar" title="Devis" class="icon-menu-before"/>Devis
						<router-link to="/pros/estimations">
							<font-awesome-icon icon="plus" class="add-bill-estimation" title="créer un devis"/>
						</router-link>
					</div>
				</router-link>
				<router-link to="/pros/billings/details" v-if="billIsEnable">
					<div class="menu-with-add link">
						<font-awesome-icon icon="file-invoice-dollar" title="facturation" class="icon-menu-before"/>Factures
						<router-link to="/pros/billings">
							<font-awesome-icon icon="plus" class="add-bill-estimation" title="créer une facture"/>
						</router-link>
					</div>
				</router-link>	
				<router-link class="link" to="/pros/finance/forecast" v-if="isAdmin"><font-awesome-icon icon="receipt" title="Prévisionnel" class="icon-menu-before"/>Prévisionnel</router-link>
				<router-link class="link" to="/pros/administration" v-if="isAdmin"><font-awesome-icon icon="hammer" title="administration" class="icon-menu-before"/>Administration</router-link>
				<router-link class="link" to="/pros/team" v-if="isAdmin && isNotOfferStandard"><font-awesome-icon icon="user-plus" title="Equipe" class="icon-menu-before"/>Equipe</router-link>
				<router-link class="link" to="/pros/sessions-types" v-if="isAdmin"><font-awesome-icon icon="tools" title="Types de séances" class="icon-menu-before"/>Types séances</router-link>
			</h4>
		</div>
		<div class="menu" v-else>
			<h4>
				<router-link class="link" to="/pros/sessions" v-if="sessionsIsEnable"><font-awesome-icon icon="calendar-alt" alt="gérez vos séances"/></router-link>
				<router-link class="link" to="/pros/animals" v-if="animalsIsEnable"><font-awesome-icon icon="horse" alt="Animaux"/></router-link>
				<router-link class="link" to="/pros/clubs" v-if="clubsIsEnable"><font-awesome-icon icon="globe-europe" alt="Clubs"/></router-link>
				<router-link class="link" to="/pros/patients" v-if="patientsIsEnable"><font-awesome-icon icon="address-book" alt="Patients"/></router-link>
				<router-link class="link" to="/pros/estimations" v-if="estimationIsEnable"><font-awesome-icon icon="comment-dollar" alt="Devis"/></router-link>
				<router-link class="link" to="/pros/billings" v-if="billIsEnable"><font-awesome-icon icon="file-invoice-dollar" alt="Facturation" /></router-link>
				<router-link class="link" to="/pros/finance/forecast" v-if="isAdmin"><font-awesome-icon icon="receipt" alt="Prévisionnel"/></router-link>
				<router-link class="link" to="/pros/administration" v-if="isAdmin"><font-awesome-icon icon="hammer" alt="administration" /></router-link>
				<router-link class="link" to="/pros/team" v-if="isAdmin && isNotOfferStandard"><font-awesome-icon icon="user-plus" alt="Equipe" /></router-link>
				<router-link class="link" to="/pros/sessions-types" v-if="isAdmin"><font-awesome-icon icon="tools" title="Types de séances" /></router-link>
			</h4>
		</div>
	</div>
	<div v-else>
		<div class="sidenav" :class="{'sidenav': true, 'activeSidenav': showBurger}">
			<a id="closeBtn" href="#" class="close" @click="showBurger = false">×</a>
			<ul>
				<router-link class="link" to="/pros/sessions" v-if="sessionsIsEnable" @click="showBurger=false">Rendez-vous</router-link>
				<router-link class="link" to="/pros/animals" v-if="animalsIsEnable" @click="showBurger=false">Animaux</router-link>
				<router-link class="link" to="/pros/clubs" v-if="clubsIsEnable" @click="showBurger=false">Clubs</router-link>
				<router-link class="link" to="/pros/patients" v-if="patientsIsEnable" @click="showBurger=false">Patients</router-link>
				<router-link class="link" to="/pros/estimations/details" @click="showBurger=false" v-if="estimationIsEnable">Devis</router-link>
				<router-link class="link" to="/pros/billings/details" v-if="billIsEnable" @click="showBurger=false">Factures</router-link>
				<router-link class="link" to="/pros/finance/forecast" v-if="isAdmin">Prévisionnel</router-link>
				<router-link class="link" to="/pros/administration" v-if="isAdmin" @click="showBurger=false">Administration</router-link>
				<router-link class="link" to="/pros/team" v-if="isAdmin && isNotOfferStandard" @click="showBurger=false">Equipe</router-link>
				<router-link class="link" to="/pros/sessions-types" v-if="isAdmin">Types séances</router-link>
			</ul>
		</div>
		<a href="#" id="openBtn" @click="showBurger = true">
			<span class="burger-icon">
				<span></span>
				<span></span>
				<span></span>
			</span>
		</a>
	</div>

</template>
<script>
import { PRIVILEGES } from '../../services/privileges';
import { OFFERS } from '../../services/offers';

export default {
	// eslint-disable-next-line
	name: 'Menu',
	data() {
		return {
			hideMenu: false,
			mobile: this.$store.state.isMobile,
			showBurger: false,
			isAdmin: false,
			sessionsIsEnable: false,
			animalsIsEnable: false,
			clubsIsEnable: false,
			patientsIsEnable: false,
			estimationIsEnable: false,
			billIsEnable: false,
			offers: OFFERS,
			offerName: '',
			isNotOfferStandard: false
		}
	},
	mounted() {
		this.offerName = this.$store.getters.user.tenant.offer;
		this.isNotOfferStandard = (this.offerName.toLocaleUpperCase() !== this.offers.STANDARD.toString().toLocaleUpperCase());
		this.isAdmin = this.$store.getters.privileges.includes(PRIVILEGES.ADMINISTRATION);
		this.sessionsIsEnable = this.$store.getters.privileges.includes(PRIVILEGES.AGENDA);
		this.animalsIsEnable = this.$store.getters.privileges.includes(PRIVILEGES.ANIMALS);
		this.clubsIsEnable = this.$store.getters.privileges.includes(PRIVILEGES.CLUBS);
		this.patientsIsEnable = this.$store.getters.privileges.includes(PRIVILEGES.PATIENTS);
		this.estimationIsEnable = this.$store.getters.privileges.includes(PRIVILEGES.ESTIMATION);
		this.billIsEnable = this.$store.getters.privileges.includes(PRIVILEGES.BILL);		
	}
};
</script>

<style>
@import '../../assets/styles/menu.css';

</style>
