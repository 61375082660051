<template>
    
    <div class="flex-container">
        <div id="filter-therapist-container" v-if="isOfferUltime && therapists.length > 1" > 
            <label for="therapise-select">Practicien </label>
            <select
                class="zoo-input select-patient-club-animal"
                name="therapist"
                id="therapist-select"
                v-model="selectedTherapist"
                tabindex="1"
                @change="filterSessionTherapists"
            >
                <option value='Tous'>Tous</option>
                <option
                    v-for="therapist in therapists"
                    :value="therapist"
                    :key="therapist"
                >
                {{ therapist.lastname }} {{ therapist.firstname !== null ? therapist.firstname : therapist.email }}
                </option>
            </select>
        </div>
        <div id="admin-date-selection">
            <p>Entre le <input name="startDate" type="date" v-model="startDate" @change="getUserActivities"> et le <input name="startDate" type="date" v-model="endDate" @change="getUserActivities"></p>
        </div>
    </div>
</template>

<script>
import { getTherapists } from '../services/team'

export default ({
    name: 'SalesDetails',
    data() {
        return {
            therapists: [],
			selectedTherapist: 'Tous',
            isOfferUltime: false
        }
    },
    mounted() {
		this.offerName = this.$store.getters.user.tenant.offer
		this.isOfferUltime = this.offerName === 'ULTIME'
		if (this.isOfferUltime)
            getTherapists().then(team => {
				this.therapists = team.members
			})
    },
    methods: {
    }
})
</script>
