import axios from 'axios';
import authHeaders from '../services/auth-header';

const PERSONALIZE_COLOR_URL = process.env.VUE_APP_BASE_AP_URL+`/user/personalize/colors`;
const PERSONALIZE_SESSION_TYPES_URL = process.env.VUE_APP_BASE_AP_URL+`/tenant/personalize/session-types`;
const PERSONALIZE_CANCELLATION_URL = process.env.VUE_APP_BASE_AP_URL+`/user/personalize/cancellation`;
const PERSONALIZE_VACATIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/user/personalize/vacations`;

export async function saveColors(colorsContainer) {
	return axios.post(`${PERSONALIZE_COLOR_URL}`, colorsContainer, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error)) 
}

export async function getColors() {
	return axios.get(`${PERSONALIZE_COLOR_URL}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error))
}

export async function saveCancellation(cancellationBefore) {
	return axios.post(`${PERSONALIZE_CANCELLATION_URL}`, { 'cancellationBefore': cancellationBefore}, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error)) 
}

export async function getCancellation() {
	return axios.get(`${PERSONALIZE_CANCELLATION_URL}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error))
}


export async function saveVacations(from, to) {
	return axios.post(`${PERSONALIZE_VACATIONS_URL}`, {'from': from+'+00', 'to': to+'+00'}, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error)) 
}

export async function getVacations() {
	return axios.get(`${PERSONALIZE_VACATIONS_URL}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error))
}

export async function addSessionType(sessionType) {
	return axios.post(`${PERSONALIZE_SESSION_TYPES_URL}`, sessionType, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error))
}

export async function getSessionTypes() {
	return axios.get(`${PERSONALIZE_SESSION_TYPES_URL}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error))
}

export async function updateSessionType(sessionType) {
	return axios.put(`${PERSONALIZE_SESSION_TYPES_URL}/${sessionType.id}`, sessionType, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error))
}

export async function deleteSessionType(sessionTypeId) {
	return axios.delete(`${PERSONALIZE_SESSION_TYPES_URL}/${sessionTypeId}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => Promise.reject(error))
}
