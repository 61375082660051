<template>
    <LoginAndSignUp v-if="showSignupModal" :showUltimeSignUpModal="false" :defaultShowLoginModal="false" :defaultShowSignupModal="showSignupModal" @exit="closeModal"/>
    <div  id="greeting">
      <div>
        <div>
            <h1>Gérez votre emploi du temps facilement</h1>
            <p id="greeting-second-message">Solution de facturation et de gestion de planning</p>
            <p id="greeting-third-message">Suivi patient, suivi des animaux, rendez-vous, facturation... En un coup d'oeil, tout au même endroit.</p>
        </div>
        <div>
            <button id="free-trial-button" @click="showSignupModal=true" type="button">Essai gratuit 1 mois</button>
        </div>
      </div>
      <div id="ask-for-demo">
        <h2>Voyons voir!</h2>
        <div v-if="error !== '' " class="errorMessages">
              <div>{{error}}</div>
        </div>

        <div>
          <div id="ask-for-demo-name">
            <input type="text" placeholder="Prénom" name="firstname" v-model="firstname"/>
            <input type="text" placeholder="Nom" name="lastname" v-model="lastname"/>
          </div>
          <div>
            <input type="email" placeholder="Adresse e-mail" name="email" v-model="email"/>
          </div>  
        </div>
        <div id="ask-for-demo-buttons">
          <button id="ask-for-demo-button" @click="askForDemo">
            <div v-if="loading" class="loader init-bottom-margin"></div>
            <span>Demander une démo</span>
          </button>
        </div>
      </div>
    </div>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#f7f1d9" fill-opacity="1" d="M0,32L26.7,74.7C53.3,117,107,203,160,240C213.3,277,267,267,320,229.3C373.3,192,427,128,480,117.3C533.3,107,587,149,640,149.3C693.3,149,747,107,800,112C853.3,117,907,171,960,181.3C1013.3,192,1067,160,1120,128C1173.3,96,1227,64,1280,58.7C1333.3,53,1387,75,1413,85.3L1440,96L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path>
      </svg>
    </div>
    <section id="welcome-icons-section">
      <div class="welcome-items welcome-icons">
        <div>
            <font-awesome-icon icon="calendar-alt" class="greetings-icon" alt="gérez vos séances"/>
            <div>
              <h3>Calendrier</h3>
              <p>Libérez vous du temps grâce à la prise de rendez-vous en ligne</p>
            </div>
        </div>
        <div>
            <font-awesome-icon icon="address-book" class="greetings-icon"/>
            <div>
              <h3>Gestion patients</h3>
              <p>Un seul endroit pour gérer vos patients</p>
            </div>
        </div>
        <div>
            <font-awesome-icon icon="file-invoice-dollar" class="greetings-icon"/>
            <div>
              <h3>Gestion Devis/Facturation</h3>
              <p>Gagnez en confort en simplifiant la création de vos devis et factures</p>
            </div>
        </div>
      </div>
    </section>
    <section>
      <h2 class="section-title">Comment ca fonctionne ?</h2>
      <div class="how-to-card-container">
        <div>
          <h3>1. Prise de rendez-vous</h3>
          <p>Deux possibilités : </p>
          <ul>
            <li>Vous positionnez le rendez-vous depuis votre espace</li>
            <li>Vous validez un rendez-vous positionné par le patient</li>
          </ul>
        </div>
        <div>
          <p><img src="../../assets/imgs/undraw_calendar_re_ki49.svg"/></p>
        </div>
      </div>
      <div class="how-to-card-container">
        <div>
          <img src="../../assets/imgs/undraw_content_creator_re_pt5b.svg">
        </div>
        <div>
          <h3>2. La séance est effectuée</h3>
          <p>Je prends les notes dont j'ai besoin.</p>
          <p>Le suivi entre les séances est simplifié.</p>
        </div>
      </div>
      <div class="how-to-card-container">
        <div>
          <h3>3. Gestion administrative</h3>
          <p>La facture est générée à partir des séances.</p>
          <p>Les devis, commentaires de consultation, peuvent être générés tout aussi simplement </p>
        </div>
        <div>
          <img src="../../assets/imgs/undraw_resume_re_hkth.svg">
        </div>
      </div>
      <div class="how-to-card-container">
        <div>
          <img src="../../assets/imgs/undraw_data_report_re_p4so.svg">
        </div>
        <div>
          <h3>4. Vision globale</h3>
          <p>Vous avez une vision globale de votre activité en seul coup d'oeil.</p>
        </div>
      </div>
    </section>
    <section id="help-section">
      <h2 id="section-title">Besoin d'aide ?</h2>
      <p>Notre service client est à votre écoute.</p>
      <p>Vous pouvez nous écrire à contact@animal-planner.com.</p>
    </section>
</template>

<script>
export default ({
  // eslint-disable-next-line
  name: 'Greetings',
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      error: '',
      loading: false,
      showSignupModal : false,
    }
  },
  methods: {
    closeModal() {
        this.showSignupModal = false;
    },
    askForDemo(e) {
      e.preventDefault();
      this.error = '';
      if (this.checkForm()) {
        this.loading = true;
        this.$store.dispatch({
          type: 'askForDemo',
          newEntry: {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email
          },
        
        }).then(()=> {
          this.$router.push('/pros/demoThanks');
          this.loading = false;
        }, () => {
           this.error = 'Veuillez vérifier vos informations';
           this.loading = false;
        }); 
      } else {
           this.error = 'Veuillez vérifier vos informations';
      }
    },
    checkForm() {
      return this.firstname !== '' && this.lastname !== '' && this.email !== '';
    }
  }
})
</script>

<style scoped>
#greeting {
  margin-left: 3rem;
  display: flex;
  justify-content: space-between;
  color: var(--secondary-color);
  font-size: 16px;
  letter-spacing: 1px;
}

#greeting h1 {
  font-size: 46px;
  padding-top: 5rem;
  margin-bottom: 2rem;
  font-family: 'Roboto', sans-serif;
}

#greeting-second-message {
  font-size: 24px;
}

#greeting-third-message {
  font-size: 16px;
  color: var(--light-color);
  font-family: 'Lato', sans-serif;
  font-weight: 800;
}

#free-trial-button {
  font-weight: 600;
  font-size: 18px;
  padding: 1rem;
  margin: 2rem 0 0 0;
}

#ask-for-demo {
  color: var(--secondary-color);
  margin-top: 5rem;
  margin-right: 6rem;
  padding: 1rem;
  text-align: center;
  border: 1px solid var(--light-color);
  border-top-width: 4px !important;
}

#ask-for-demo h2{
  margin-bottom: 2rem;
}

#ask-for-demo input {
  height: 2rem;
  margin-right: 0.2rem;
  border:none;
  border-bottom: 1px solid var(--light-gray);
}

#ask-for-demo input[type=email] {
  width: 100%;
}

#ask-for-demo-name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

#ask-for-demo-buttons {
  display: flex;
  justify-content: center;
  align-content: center;
}

#ask-for-demo-button {
  background-color: var(--light-color);
  color: #FFFFFF;
  font-size: 18px;
  padding: 1rem 2rem;
  margin: 1rem;
  cursor: pointer;
  border: 2px double var(--light-color);
  border-radius: 8px;
  /*background-image: linear-gradient(#5E747F, #000000), radial-gradient(circle at left top, var(--light-color), #B6BE9C);*/
  background-origin: border-box;
  background-clip: padding-box, border-box;
  opacity: 0.8;
}
#ask-for-demo-button:hover{
  opacity: 1;
}

.how-to-card-container {
  display: flex;
  text-align: left;
  justify-content: space-around;
  width: 60%;
  margin: 2rem auto;
}

.how-to-card-container img {
  width: 14rem;
}

#help-section {
  text-align: center;
  padding: 1rem;
  background-color: #FFFFFF;
}

.section-title {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin: 1rem auto;
}


@media (max-width: 800px) {
  #greeting {
    display: block;
    margin-left: 1rem;
  }

  #greeting h1 {
    font-size: 42px;
    padding-top: 3rem;
    margin-bottom: 2rem;
    font-family: 'Lato', sans-serif;
  }

  #free-trial-button {
    margin: 1rem auto;
  }

  #ask-for-demo {
    margin-top: 2rem;
    margin-right: 1rem;
  }

  #ask-for-demo-name {
    display: block;
  }

  #ask-for-demo-button {
    font-size: 16px;
  }

  .how-to-card-container {
    display: block;
  }

}

</style>
