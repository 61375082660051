<template>
    <div id="patient-patient-infos">
        <div id="patient-details">
            <h3 class="header-title" v-if="patient.type === patientTypes.INDIVIDUAL.value">{{patient.individual.lastname}} {{patient.individual.firstname}}</h3>
            <h3 class="header-title" v-if="patient.type === patientTypes.FIRM.value">{{ patient.firm.companyName }}</h3>
            <div v-if="patient.birthdate"><span class="contact-details-title">Date de naissance: </span>{{ patient.birthdate.join('-') }}</div>
            <div><span class="contact-details-title">Email: </span>{{patient.email}}</div>   
            <div v-if="patient.address && patient.address.zipCode !== 0 && patient.address.streetName !== '' && patient.address.city !== ''"><span class="contact-details-title">Adresse : </span>{{ patient.address.streetName }} - {{ patient.address.zipCode }} {{ patient.address.city }}</div>
        </div>

        <div class="edit-patient-infos">
            <button @click="editPatient"><font-awesome-icon icon="pen" style="margin-right: 8px;"/> Editer les informations</button>
        </div>
    </div>
    <div v-if="patient.type === patientTypes.FIRM.value" class="firm-patients">
        <div v-if="sortedCorporatePatients.length > 0">
            <h4>Patients rattachés à l'entreprise</h4>
            <table class="data-table">
                <thead class="data-table-header">
                    <tr>
                        <th @click="sort('lastname')">Nom</th>
                        <th @click="sort('firstname')">Prénom</th>
                        <th @click="sort('email')">E-mail</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(patient, index) in sortedCorporatePatients"
                        v-bind:key="index"
                        @mouseover="hover=index"
                        @mouseleave="hover=-1"
                        @click="routeToDetails(patient.id)"
                    >
                        <td style="width: 10%">{{ patient.lastname }}</td>
                        <td style="width: 10%">{{ patient.firstname}}</td>
                        <td>{{ patient.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <p>Aucun patient rattaché à l'entreprise</p>
            <br/>
        </div>    
    </div>
    <div v-if="answers.length > 0" id="patient-questions-answers">
        <h3>Questionnaire lors du premier rendez-vous</h3>
        <div v-for="answer in answers" :key="answer" class="question-answer-content">
            <h4>{{ answer.question }}</h4>
            <p>{{ answer.answer }}</p>
        </div>
	</div>
    <div class="no-session-registered" v-if="!patient.sessions || patient.sessions.length===0">
        <p>Aucune séance enregistrée pour l'instant.</p>
    </div>

    <div v-else>
        <div id="patient-sessions-filter">
                <p>Séances entre le <input name="filterStartDate" type="date" v-model="filterStartDate" @change="filterSessions()"> et le <input name="filterEndDate" type="date" v-model="filterEndDate" @change="filterSessions()"></p>
        </div>
        <div v-if="filteredSessions.length > 1">
            {{ filteredSessions.length }} séances
        </div>    
        <div id="filter-download-container">
            <div class="nb-hours-details-paragraph">
                <p v-if="patient.type === patientTypes.INDIVIDUAL.value && patient.individual.nbHoursToDo !== undefined && patient.individual.nbHoursToDo > 1">
                    Plage de {{ patient.individual.nbHoursToDo }} heures programmées pour ce patient
                </p>
            </div>
            <div v-if="filteredSessions.length > 0">
                <font-awesome-layers class="fa-lg pointer">
                    <font-awesome-icon v-if="loading" icon="spinner" class="spinner-rotation" />
                    <font-awesome-icon icon="download" title="télécharger la liste en PDF" id="download-session-comments" @click="downloadAsPDF"/>
                </font-awesome-layers>
                
            </div>
        </div>
        <table class="data-table">
            <thead class="data-table-header">
                <tr>
                    <!--th @click="sort('date')" :class="defineCarretClass('date')">Date</th-->
                    <th class="max-width-column" v-if="patient.type === patientTypes.INDIVIDUAL.value && patient.individual.nbHoursToDo !== undefined && patient.individual.nbHoursToDo > 1"></th>
                    <th @click="sort('date')" :class="defineCarretClass('date')">Date</th>
                    <th>Commentaire</th>
                    <th>Payée <input type="checkbox" v-model="paidSelectAll" @change="selectUnselectAllPayment" /></th>
                    <th>Patient présent <input type="checkbox" v-model="presentSelectAll" @change="selectUnselectAllPresent" /></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(session, index) in filteredSessions"
                    v-bind:key="index"
                    @mouseover="hover=index"
                    @mouseleave="hover=-1"
                    @click="redirectToSessionDetails(session.id)"
                >
                    <td class="max-width-column" v-if="patient.type === patientTypes.INDIVIDUAL.value && patient.individual.nbHoursToDo !== undefined && patient.individual.nbHoursToDo > 1">{{ (index%patient.individual.nbHoursToDo+1) +"/"+ patient.individual.nbHoursToDo }}</td>
                    <td style="width: 10%">{{ session.date }}                
                        <div v-if="hover === index" class="patientSessionDetails">
                            <p>a eu lieu chez {{ session.club !== null ? session.club.name : '-' }}</p>
                            <p v-if="session.animal">avec {{ session.animal !== null ? session.animal.name : '-'}}</p>
                        </div>
                    </td>
                    <td class="session-comment">{{ session.comments}}</td>
                    <td style="width: 10%"><input type="checkbox" v-model="session.paid" @click.stop @change="updateSessionPayment(session.id)"/></td>
                    <td style="width: 10%"><input type="checkbox" v-model="session.patientPresent" @click.stop @change="updatePatient"/></td>

                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { PATIENTS_TYPES } from '../../../services/patientTypes'
import { downloadComments } from '../../../services/downloads'
import { getQuestionsAndAnswersByPatientId } from '../services/questions'
import { updatePaymentSession } from '../../../services/session'
import _ from 'lodash'

export default ({
    // eslint-disable-next-line
    name: 'Patient',
    data() {
        return {
            patient: {},
            newFirstname: '',
            newLastname: '',
            hover: -1,
			patientTypes: PATIENTS_TYPES,
            sortedCorporatePatients: [],
            currentSort:'date',
			currentSortDir:'desc',
            filterStartDate: new Date().toISOString().substring(0,10),
            filterEndDate: new Date().toISOString().substring(0,10),
            filteredSessions: [],
            loading: false,
            paidSelectAll: false,
            presentSelectAll: false,
            answers: []
        };
    },
    methods: {
        updateSessionPayment(sessionId) {
            const session = this.filteredSessions.filter(s => s.id === sessionId)[0]
            updatePaymentSession(sessionId, session.paid).then(() => {
                this.$toast.success(`Information mises à jour`);
            }).catch(() => {
                this.$toast.error(`Mise à jour paiement impossible`);
            })
        },
        selectUnselectAllPayment() {
			let self = this;
			this.filteredSessions = this.filteredSessions.map(function(elt){
				return {
					...elt,
					paid : self.paidSelectAll
				}
			});
            
            this.patient.sessions.forEach(s => {
                let foundSession = _.find(this.filteredSessions, filteredSession => s.id === filteredSession.id);
                if (foundSession !== undefined)
                    s.paid = foundSession.paid;
            });
            this.updatePatient();
		},
        selectUnselectAllPresent() {
			let self = this;
			this.filteredSessions = this.filteredSessions.map(function(elt){
				return {
					...elt,
					patientPresent : self.presentSelectAll
				}
			});
            
            this.patient.sessions.forEach(s => {
                let foundSession = _.find(this.filteredSessions, filteredSession => s.id === filteredSession.id);
                if (foundSession !== undefined)
                    s.patientPresent = foundSession.patientPresent;
            });
            this.updatePatient();
        },
        sort(s) {		
			if(s === this.currentSort) {
				this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
			}
			this.currentSort = s;
            this.filterSessions();
		},
		defineCarretClass(criteria) {
			if (criteria === this.currentSort) {
				if (this.currentSortDir === 'asc')
					return 'carret-up';

				return 'carret-down';
			}
			return '';
		},
        editPatient() {
            this.$router.push({ name: 'EditPatient', params: { id : this.patient.id } });
        },
        getComments(comments) {
            let displayedComments = comments;
            if (comments.length > 10)
                displayedComments = `${comments.substring(0, 10)}...`; 
            return displayedComments;
        },
        getPatientDetails(patientId) {
			this.$store.dispatch({
                type: 'getPatientDetails',
                id: patientId
            }).then(patientDetails => {
                this.patient=patientDetails;
                this.newLastname = patientDetails.lastname;
                this.newFirstname = patientDetails.firstname;
                this.sortedCorporatePatients = this.sortedPatients();
                let allPaid = true;
                let allPresent = true;
                this.patient.sessions.forEach(session => {
                    let sessionDate = new Date(session.date);
                    let sessionDateTime = sessionDate.getTime();
                    let sessionDateString = sessionDate.toISOString().substring(0,10);

                    if (sessionDateTime < new Date(this.filterStartDate).getTime())
                        this.filterStartDate = sessionDateString;
                    if (sessionDateTime > new Date(this.filterEndDate).getTime())
                        this.filterEndDate = sessionDateString;

                    if (session.paid === false) {
                        allPaid = false;
                    }

                    if (session.patientPresent === false)
                        allPresent = false;
                });
                this.paidSelectAll = allPaid;
                this.presentSelectAll = allPresent;
                this.filterSessions();
                getQuestionsAndAnswersByPatientId(patientId).then((response) => {
                    this.answers = response
                })
            });
		},
        updatePatient(){
            this.$store.dispatch({
                type: 'updatePatient',
                patient: this.patient
            }).then(() => {
                this.$toast.success(`Informations mises à jour`);
            }).catch(() => {
                this.$toast.error(`Mise à jour informations patient impossible`);
            });
        },
        sortedPatients() {
            if (this.patient.firm?.corporatePatients) {
                let patients = this.patient.firm.corporatePatients;
                return patients.sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'desc') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;

                    return 0;
                });
            }
            return null;
        },
        routeToDetails(patientId) {
            this.getPatientDetails(patientId);
			this.$router.replace({ name: 'PatientWithId', params: { id : patientId }});
		},
        filterSessions() {
            this.filteredSessions = this.patient.sessions.filter(session => {
                let sessionDate = new Date(session.date).getTime();
                return sessionDate >= new Date(this.filterStartDate).getTime() 
                    && sessionDate <= new Date(this.filterEndDate).getTime();
            });
            this.filteredSessions = this.filteredSessions.sort((a,b) => {
				let modifier = 1;
				if(this.currentSortDir === 'desc') modifier = -1;
                let left = new Date(a.date).getTime();
                let right = new Date(b.date).getTime();

                if(left < right) return -1 * modifier;
				if(left > right) return 1 * modifier;

				return 0;
			});
        },
        downloadAsPDF() {
            this.loading = true;
            downloadComments(this.filteredSessions.map(s => s.id))
                .then((response) => {
                    let docUrl = document.createElement('a');
                    docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }));
                    docUrl.download="patient-comments.pdf";
                    docUrl.click();
                })
                .catch((response) => {
                    const blob = new Blob([response.data], {type: 'text/plain; charset=utf-8'});
                    
                    blob.text().then((response) => {
                        let error = JSON.parse(response);
                        if (error.errorCode === 'E127')
                            this.$toast.error('Aucune session avec commentaires à convertir');
                        else
                            this.$toast.error('Erreur lors de la génération du PDF');
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        redirectToSessionDetails(sessionId) {
            this.$router.push({ name: 'EditSession', params: { id : sessionId } });
        }
    },
    mounted() {
        this.getPatientDetails(this.$route.params.id);
    }
})
</script>
<style scoped>

.max-width-column:first-child {
    width: 5rem !important;
}

#patient-patient-infos {
    display: flex;
}

.nb-hours-details-paragraph {
  margin: 2rem 0 2rem 2rem;    
}

#patient-details {
    margin-bottom: 2rem;
}

.edit-patient-infos {
    margin-left: 5rem;
}

.patientSessionComments {
    margin-bottom: 2rem;
    padding-left: 1rem;
    max-width: 65rem;
    word-wrap: break-word;
}

.patientSessionTitle{
    border-bottom: 1px solid gray;
    cursor: pointer;
}

.patientSessionDetails {
    position: absolute;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border:1px solid var(--secondary-color);
    border-radius: 5px;
    -webkit-box-shadow: 3px 3px 16px 3px rgba(0,0,0,0.95); 
    box-shadow: 3px 3px 16px 3px rgba(0,0,0,0.95);
    padding: 1rem;
}

.no-session-registered {
    margin-top: 2rem;
}

.session-comment {
    overflow-wrap: break-word;
    max-width:20rem;
}

.firm-patients {
    margin-bottom: 2rem;
}

#patient-sessions-filter {
    margin-bottom: 2rem;
}

#filter-download-container {
    display: flex;
    margin : 1rem 0 1rem 0;
    justify-content: space-between;
    width: 90%;
    align-items: center;
}

#download-session-comments {
    font-size: 24px;
}

.spinner-rotation {
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

#patient-questions-answers {
    margin: 2rem 0;
}
</style>