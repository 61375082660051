<template>
  
  <div id="about-container">
    <p>Créé en 2022, AnimalPlanner a pour but de simplifier la gestion de votre emploi du temps en tant que thérapeute.</p>
    <p id="second-p-about">Chez nous, nous sommes conscients que chaque professionnel travaillant avec des animaux a des besoins spécifiques.<br/>
      Que vous soyez un coach, un médiateur animalier ou un praticien ayant des rendez-vous avec des animaux, nous nous efforçons de nous adapter à vos besoins uniques.<br/>
      Nous sommes là pour vous aider à offrir à vos clients/patients la meilleure expérience possible en fournissant des solutions adaptées et personnalisées qui répondent à vos besoins professionnels.<br/>
      Notre objectif est de vous aider à vous concentrer sur votre travail avec vos clients/patients et à établir des relations durables avec vos animaux, tout en nous occupant de tout ce qui est en rapport avec la gestion de votre entreprise.
    </p>
    <ul id="list-services-about">
        <li>Rendez vous, partagés entre plusieurs praticiens</li>
        <li>Création de devis</li>
        <li>Création de factures</li>
        <li>Suivi du patient</li>
        <li>Suivi de l'animal</li>
        <li>Et bien d'autres fonctionnalités à venir :)</li>
    </ul>
    <p>Des questions, des remarques, des besoins ? Contactez nous à l'adresse contact@animal-planner.com</p>
  </div>
</template>

<script>
export default ({
  // eslint-disable-next-line
  name: 'About'
})
</script>

<style scoped>
#about-container {
  margin-top: 5rem;
  margin-left: 5rem;
}

#second-p-about {
  margin-top : 1rem;
}

#list-services-about {
  margin: 1rem;
  padding-left: 1rem;
  list-style: circle;
}
</style>
