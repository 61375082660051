<template>
  <div id="not-found-container">
      <div>Oops... Vous vous êtes perdu ?</div>
      <div>La page que vous recherchez n'existe pas.</div>
  </div>
</template>

<script>
export default ({
  // eslint-disable-next-line
  name: 'PageNotFound'
})
</script>

<style scoped>
#not-found-container {
  text-align: center;
  position: relative;
  top: 50%;
}
</style>