let exports = {};

exports.days = () => {
    let days = [];
    Array.from({length: 31},(_,x) => days.push({index: x+1, value: x+1 < 10 ? `0${x+1}`: `${x+1}`}));
    return days;
}

exports.months = () => {
    let months = [];
    Array.from({length: 12},(_,x) => months.push({index: x+1, value: x+1 < 10 ? `0${x+1}`: `${x+1}`}));
    return months;
}

exports.years = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    Array.from({length: 100}, (_,x) => years.push( {index: x-100+1+currentYear, value: x-100+1+currentYear}))
    return years;
}

export default exports;