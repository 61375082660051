<template>
    <PublicHeader/>
    <div id="public-main-container" v-if="shouldDisplaySearch()">
        <h2>Rechercher un praticien</h2>
        
        <form id="for-search-therapists">
            <input id="therapist-name-input" type="text" placeholder="Nom du praticien" v-model="therapistName"/>
            <input id="therapist-location-input" type="text" placeholder="Ville ou code postal" v-model="location"/>
            <button id="search-therapists" @click="searchTherapists" type="submit">Rechercher</button>
        </form>
    </div>
    <router-view/>
</template>

<script>

import PublicHeader from './PublicHeader.vue'
export default {
    name: 'PublicIndex',
    components: {
        PublicHeader,
    },
    data() {
        return {
            therapistName: '',
            location: ''
        }
    },
    methods: {
        shouldDisplaySearch() {
            let route = this.$router.currentRoute.value.name;
            return !(route === 'PublicAbout' || route === 'PublicCgu')
        },
        searchTherapists(e) {
            e.preventDefault()
            if (this.therapistName !== '' || this.location !== '')
            this.$router.push({path:'/therapists', query: {name: this.therapistName, location: this.location}})
           
        }
    }
}
</script>
<style scoped>

#public-main-container {
    padding-left: 2rem;
    margin-top: 2rem;
    margin-left: 30%;
}

#public-main-container h3 {
    font-size: 32px;
}

#for-search-therapists {
  display: flex;
}

#therapist-name-input {
    border: 1px solid gray;
    border-radius: 0.5rem 0 0 0.5rem;
    height: 3rem;
    width: 20rem;
    padding: 0.4rem;
}

#therapist-location-input {
    border: 1px solid gray;
    border-left: 0;
    padding: 0.4rem;
}

#search-therapists {
    margin: 0;
    border-radius: 0 0.5rem 0.5rem 0;
    font-weight: 600;
}

@media (max-width: 800px) {
    #public-main-container {
        width: 80%;
        margin: 1rem auto;
        text-align: center;
    }

    #for-search-therapists {
        display: flex;
        flex-direction: column;
        padding: 0;
        align-items: center;
    }

    #therapist-name-input {
        border: 1px solid gray;
        border-radius: 0.5rem 0.5rem 0 0;
        height: 3rem;
        width: 20rem;
    }

    #therapist-location-input {
        border: 1px solid gray;
        border-radius: 0 0 0.5rem 0.5rem;
        height: 3rem;
        width: 20rem;
    }

    #search-therapists {
        margin-top: 1rem;
        font-weight: 600;
        border-radius: 0;
        height: 3rem;
        width: 20rem;
        align-items: center;
    }
}
</style>