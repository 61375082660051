import axios from 'axios';
import authHeaders from './auth-header';

const DOWNLOAD_COMMENTS_URL = process.env.VUE_APP_BASE_AP_URL+`/download/session-comments`;

export async function downloadComments(sessionsIds) {
	return axios.post(`${DOWNLOAD_COMMENTS_URL}`, {'sessionIds' : sessionsIds}, {responseType: 'arraybuffer', headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => {
      return Promise.reject(error.response);
	}); 
}
