<template>
  <div v-if="isComponentReady" class="logged-main">
    <Menu id="menuContainer"/>
    <div id="content">
        <router-view></router-view>
    </div>
  </div>
  <div v-else>
    <!-- Loading placeholder or spinner -->
    Loading...
  </div>
    
</template>

<script>
import Menu from './Menu.vue';

export default ({
  // eslint-disable-next-line
  name: 'APMain',
  components: {
    Menu
  },
  data() {
    return {
      isComponentReady: false
    }
  },
  beforeCreate() {
		this.$store.dispatch({
				type: 'getUserDetails',
			}).then(() => {
				this.isComponentReady = true
			});
  }
})

</script>

<style scoped>
#content {
  background-color: #FFFFFF;
  color: var(--secondary-color);
  padding-left: 2rem;
  width: 100%;
  flex: 9
}

@media (max-width: 800px) {
  #content {
    padding-left: 0.5rem;
  }
  
  #menuContainer {
    position: absolute;
    top: 1rem;
    left: 0.3rem;
  }
}
</style>
