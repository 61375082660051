import axios from 'axios';

const THERAPISTS_URL = process.env.VUE_APP_BASE_AP_URL+`/therapists`;

export async function getTherapistById(therapistId) {
	let calledURL = `${THERAPISTS_URL}/${therapistId}`;
	return axios.get(calledURL)	
        .then(response => {
			if(response.data) {
				return Promise.resolve(response.data);
			}
		})
		.catch(error => Promise.reject(error));
} 

export async function getTherapists(name, location) {
	let calledURL = `${THERAPISTS_URL}?name=${name}&location=${location}`;
	return axios.get(calledURL)	
        .then(response => {
			if(response.data) {
				return Promise.resolve(response.data);
			}
		})
		.catch(error => Promise.reject(error));
} 

export async function getTherapistActivities(id, startDate, endDate) {
	let calledURL = `${THERAPISTS_URL}/${id}/sessions?startDate=${startDate}&endDate=${endDate}`;
	return axios.get(calledURL)	
        .then(response => {
			if(response.data) {
				return Promise.resolve(response.data);
			}
		})
		.catch(error => Promise.reject(error));
} 

export async function getTherapistSessionTypes(therapistId) {
	let calledURL = `${THERAPISTS_URL}/${therapistId}/session-types`;
	return axios.get(calledURL)	
        .then(response => {
			if(response.data) {
				return Promise.resolve(response.data)
			}
		})
		.catch(error => Promise.reject(error))
}

export async function getTherapistCancellationBefore(therapistId) {
	let calledURL = `${THERAPISTS_URL}/${therapistId}/cancellation-before`;
	return axios.get(calledURL)	
        .then(response => {
			if(response.data) {
				return Promise.resolve(response.data)
			}
		})
		.catch(error => Promise.reject(error))
}