<template>

	<div>
		<button @click="redirectToCreation">Ajouter</button>
	</div>
	<h3>Liste des devis édités</h3>
	<div v-if="estimations.length > 0">
		<div>
			<table class="data-table">
			<thead class="data-table-header">
				<th>Numéro</th>
				<th>Date d'émission</th>
				<th>Nom patient</th>
				<th>Montant H.T</th>
				<th>Ré-éditer</th>
			</thead>
			<tbody>
			<tr class="default-cursor" v-for="(estimation, index) in estimations" :key="index">
				<td>{{ estimation.estimationNumber }}</td>
				<td>{{ estimation.createdAt }}</td>
				<td>{{ estimation.patient ? estimation.patient.type=== patientTypes.INDIVIDUAL.value ? estimation.patient.individual.lastname + ' ' + estimation.patient.individual.firstname : estimation.patient.firm.companyName : ''}}</td>
				<td>{{computeTotalEstimationPrice(estimation)}}</td>
				<td @click="getGeneratedEstimation(estimation.id)" class="pointer"><font-awesome-icon icon="file-pdf" class="estimations-pdf-icon" alt="Rééediter l'estimation'"/></td>
			</tr>
			</tbody>
		</table>
		</div>
		<div class="pages-index-container">
				<vue-awesome-paginate
					:total-items="totalItems"
					:items-per-page="itemsPerPage"
					:max-pages-shown="maxPagesShown"
					v-model="estimations"
					:on-click="onClickPaginationHandler"
					:hide-prev-next="true" 
					v-cloak
				/>
				<!-- hide-prev-next to prevent paginate component bug -->
		</div>
	</div>
	<div v-else>
		Aucun résultat trouvé pour votre recherche
	</div>
</template>

<script>
import { PATIENTS_TYPES } from '../../services/patientTypes'
import { getEstimationsDetails } from './services/estimations'

export default {
	name: 'EstimationsList',
	components: {
	},
	data() {
		return {
			estimations: [],
			patientTypes : PATIENTS_TYPES,
			totalItems: 0,
			itemsPerPage: 1,
			maxPagesShown: 1
		};
	},
	methods: {
		redirectToCreation() {
			this.$router.push({name: 'Estimations' });
		},
		getEstimationDetails() {
			this.onClickPaginationHandler(0)
		},
		onClickPaginationHandler(pageNumber) {
			let realPageNumber = pageNumber == 0 ? pageNumber : pageNumber - 1
			getEstimationsDetails({
					askedInfos: {
						startDate: this.startDate,
						endDate: this.endDate,
						page: realPageNumber
					}
			}).then((response) => {
				this.estimations = response.content
				this.totalItems = response.totalElements
				this.itemsPerPage = response.itemsPerPage
				this.maxPagesShown = response.totalPages
			}).catch(() => {});
		},
		getGeneratedEstimation(estimationId) {
			this.$store.dispatch({
				type: 'getEstimation',
				estimationId: estimationId
			}).then((response) => {
                let docUrl = document.createElement('a');
                docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }));
                docUrl.download="estimation.pdf";
                docUrl.click();
			}).catch(() => {
				this.$toast.error('Erreur pendant la récupération du PDF');
			});
		},
		computeTotalEstimationPrice(estimation) {
			return estimation.nbHoursToDo * estimation.unitaryPrice;
		}
	},
	mounted() {
		this.getEstimationDetails();
	}};
</script>
<style scoped>
	.estimations-pdf-icon {
		font-size: 24px;
	}
</style>