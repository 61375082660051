export const ROLES = {
    ADMIN: {
        value: 'ROLE_ADMIN',
        toDisplay: 'Particulier'
    },
    THERAPIST: {
        value: 'ROLE_THERAPIST',
        toDisplay: 'Thérapeute'
    },
    PATIENT: {
        value: 'ROLE_PATIENT',
        toDisplay: 'patient'
    },
    ASSISTANT: {
        value: 'ROLE_ASSISTANT',
        toDisplay: 'Assistant'
    }
};