import { PRIVILEGES } from './privileges';
import store from '../store';

export function goToFirstAccessibleRoute (router) {
    let privileges = store.getters.privileges;
    let url = '';
    
    if (privileges.includes(PRIVILEGES.AGENDA))
      url = '/pros/sessions';
    if (url === '' && privileges.includes(PRIVILEGES.ANIMALS))
      url = '/pros/animals';
    if (url === '' && privileges.includes(PRIVILEGES.CLUBS))
      url = '/pros/clubs';
    if (url === '' && privileges.includes(PRIVILEGES.PATIENTS))
      url = '/pros/patients';
    if (url === '' && privileges.includes(PRIVILEGES.ESTIMATION))
      url = '/pros/estimations';
    if (url === '' && privileges.includes(PRIVILEGES.BILL))
      url = '/pros/billings';
    if (url === '' && privileges.includes(PRIVILEGES.ADMINISTRATION))
      url = '/pros/administration';            
    
    router.push(url);
}

export function PaymentException(message) {
  this.message = message;
  this.name = "PaymentException";
}

export function goToMainPage(router) {
  store.state.isPro = false
  router.push('/')
}