<template>
  
  <div id="cgu-container">
    <p>Politique de confidentialité de Rising dev</p>
  
    <p>La présente Politique de confidentialité décrit la façon dont vos informations personnelles sont recueillies, utilisées et partagées <br/>lorsque vous vous rendez sur https://animal-planer.netlifty.app (le « Site ») ou que vous y effectuez un achat.</p>
  
    <h3 class="cgu-head">INFORMATIONS PERSONNELLES RECUEILLIES</h3>
  
    Lorsque vous vous rendez sur le Site, nous recueillons automatiquement certaines informations comme votre adresse IP.<br/>
    En outre, lorsque vous parcourez le Site, nous recueillons des informations sur les pages web que vous consultez, les sites web ou les termes de recherche qui vous ont permis d'arriver sur le Site, ainsi que des informations sur la manière dont vous interagissez avec le Site. Nous désignons ces informations collectées automatiquement sous l'appellation « Informations sur l'appareil ».
  
    <h3 class="cgu-head">COOKIES</h3>
  
    <p>Lorsque nous utilisons l'expression « Informations personnelles » dans la présente Politique de confidentialité, nous faisons allusion à la fois aux Informations sur l'appareil et à la consultation du site.</p>
  
    <h3 class="cgu-head">COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES ?</h3>
  
    <p>En règle générale, nous utilisons les Informations pour traiter vos informations de paiement, et suivre le parcours utilisateur.  En outre, nous utilisons ces informations pour:
      <ul>
        <li>communiquer avec vous;</li>
        <li>évaluer les fraudes ou risques potentiels;</li>
      </ul>
    </p>
    Nous utilisons les Informations sur votre adresse IP pour évaluer les risques potentiels et, de manière plus générale, pour améliorer et optimiser notre Site (par exemple, en générant des analyses sur la façon dont nos clients parcourent et interagissent avec le Site.
  
    <p>Enfin, il se peut que nous partagions aussi vos Informations personnelles pour respecter les lois et règlementations applicables, répondre à une assignation, à un mandat de perquisition ou à toute autre demande légale de renseignements que nous recevons, ou pour protéger nos droits.</p>
  
    <h3 class="cgu-head">VOS DROITS</h3>
    <p>Si vous êtes résident(e) européen(ne), vous disposez d'un droit d'accès aux informations personnelles que nous détenons à votre sujet et vous pouvez demander à ce qu'elles soient corrigées, mises à jour ou supprimées. Si vous souhaitez exercer ce droit, veuillez nous contacter au moyen des coordonnées précisées ci-dessous.</p>
    <p>Par ailleurs, si vous êtes résident(e) européen(ne), notez que nous traitons vos informations dans le but de remplir nos obligations contractuelles à votre égard ou de poursuivre nos intérêts commerciaux légitimes, énumérés ci-dessus.</p>
  
  
    <h3 class="cgu-head">RÉTENTION DES DONNÉES</h3>
    Vos données sont persistées jusqu'à suppression de votre compte.
  
    <h3 class="cgu-head">CHANGEMENTS</h3>
    Nous pouvons être amenés à modifier la présente politique de confidentialité de temps à autre afin d'y refléter, par exemple, les changements apportés à nos pratiques ou pour d'autres motifs opérationnels, juridiques ou réglementaires.
  
    <h3 class="cgu-head">NOUS CONTACTER</h3>
    <p>Pour en savoir plus sur nos pratiques de confidentialité, si vous avez des questions ou si vous souhaitez déposer une réclamation, veuillez nous contacter par e-mail à contact@animal-planner.com, ou par courrier à l'adresse suivante:</p>
    <p>Rising Dev</p>
    <p>18, kernastellec</p>
    <p>56270 PLOEMEUR</p>
    <p>France</p>
  </div>

</template>

<script>

export default ({
  // eslint-disable-next-line
  name: 'Cgu',
})
</script>

<style scoped>
#cgu-container {
  width: 75%;
  margin: auto;
}

.cgu-head {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
