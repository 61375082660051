import { Buffer } from 'buffer';

export const privileges = state => {
    return parseJwt(state.jwtToken).privileges;
}

export const roles = state => {
    return parseJwt(state.jwtToken).roles;
}


function parseJwt(token) {
	var base64Payload = token.split('.')[1];
	var payload = Buffer.from(base64Payload, 'base64');
	return JSON.parse(payload.toString());
}