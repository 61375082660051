<template>
	<div v-cloak>
		<div>
			<button @click="dateClick">Ajouter</button>
		</div>
		<div class="align-items-center large-gap">
			<div id="filter-therapist-container" v-if="isOfferUltime && therapists.length > 1" > 
				<label for="therapise-select">Practicien </label>
				<select
					class="zoo-input select-patient-club-animal"
					name="therapist"
					id="therapist-select"
					v-model="selectedTherapist"
					tabindex="1"
					@change="filterSessionTherapists"
				>
					<option value='Tous'>Tous</option>
					<option
						v-for="therapist in therapists"
						:value="therapist"
						:key="therapist"
					>
					{{ therapist.lastname }} {{ therapist.firstname !== null ? therapist.firstname : therapist.email }}
					</option>
				</select>
			</div>

			<div id="session-type-container" v-if="sessionTypes.length > 1">
				<label for="session-type-select">Type de séance </label>
				<select
					name="session-type-select"
					v-model="sessionType"
					tabindex="2"
					@change="filterSessionTypeTherapists"
					class="select-patient-club-animal"
					>
						<option value='Tous'>Tous</option>
						<option
							v-for="sessionType in sessionTypes"
							:key="sessionType"
							:value="sessionType"
						>
						{{ sessionType.name }}
						</option>
				</select>
			</div>
		</div>
		<div id="full-calendar">
			<FullCalendar ref="fullCalendar" :options="calendarOptions" />
		</div>
	</div>
</template>


<script>
import _ from 'lodash';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { COLORS_TYPES } from '../../../services/colorsType';
import { getColors } from '../../../services/personalization';
import { mapState } from 'vuex';
import { getSessions } from '../../../services/session'
import { getFirstAndLastDaysOfWeek } from '../../../services/apdate'
import { getTherapists } from '../services/team'
import { getTherapistSessionTypes } from '../../../services/therapists'

export default ({
	// eslint-disable-next-line
    name: 'Sessions',
	components: {
		FullCalendar,
	},
	metaInfo: {
		title: 'Sessions',
		meta: [
			{ vmid: 'description', name:'description', content: 'liste des séances de zoothérapie'}
		]	
	},
    data() {
		return {
			isOfferUltime: false,
			therapists: [],
			selectedTherapist: 'Tous',
			calendarEvents: [],
			errors: [],
			calendarOptions: {
				plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
				headerToolbar: {
					left: 'prev,next today',
					center: 'title',
					right: screen.width < 800 ? '':'timeGridDay,timeGridWeek,dayGridMonth'
				},
				initialDate: this.$route.params ? this.getInitialDate() : new Date().toUTCString(),
				initialView: screen.width < 800 ? 'timeGridDay':'timeGridWeek',
				dateClick: this.dateClick,
				eventClick: this.sessionClick,
				eventContent: this.customEventContent,
				events: this.calendarEvents,
				customButtons: {
					prev: {click: () => {
						let calendarApi = this.$refs.fullCalendar.getApi()
						calendarApi.prev()
						let date = new Date(this.$refs.fullCalendar.calendar.getCurrentData().currentDate)
						let dates = getFirstAndLastDaysOfWeek(date)
						this.getSessionsByDate(dates.startDate, dates.endDate)
					}},
					next: {click: () => {
						let calendarApi = this.$refs.fullCalendar.getApi()
						calendarApi.next()
						let date = new Date(this.$refs.fullCalendar.calendar.getCurrentData().currentDate)
						let dates = getFirstAndLastDaysOfWeek(date)
						this.getSessionsByDate(dates.startDate, dates.endDate)
					}},
				},
				locale: frLocale,
				scrollTime: '08:00:00',
				allDaySlot: false,
				height: '45rem',
				editable: true
			},
			currentState: false,
			colorPersonalization: {
				colors: null,
				type: "DEFAULT"
			},
			sessions: [],
			sessionType: 'Tous',
			sessionTypes: [],
		};
	},
	computed: {
		...mapState(['patients', 'animals', 'clubs'])
	},
	mounted() {
		if (screen.width < 800) {
			this.calendarOptions.initialView = 'dayGridDay'
		}
		getColors().then((colorPersonalization) => {
			this.colorPersonalization = colorPersonalization
		})
		this.offerName = this.$store.getters.user.tenant.offer
		this.isOfferUltime = this.offerName === 'ULTIME'
		if (this.isOfferUltime)
			this.callGetTherapists()

		let dates = getFirstAndLastDaysOfWeek(this.getInitialDate())
		this.getSessionsByDate(dates.startDate, dates.endDate)
		this.getSessionTypeByTherapist()	
	},
	methods: {
		customEventContent(info) {
			let isSessionPaid = this.sessions.filter( s => s.id === info.event.id)[0].paid

			return {
				html: `<div class="event-content">
					    <div style="">
							<div>${info.timeText}</div>
							${isSessionPaid ? `<div style="cursor: pointer"title="payée"><span style="position:absolute;top:-4px;right:5px;font-size:20px;">\u20AC</span></div>` : ''}
						</div>
						
						<div>${info.event.title}</div>
						</div>`
			};
		},
		getSessionsByDate(startDate, endDate) {
			getSessions(startDate, endDate).then((sessions) => {
				this.sessions = sessions
				this.updateEvents(this.sessions)
			}).catch((response) => {
				if (response.data.errorCode !== 'E123') {
					this.$router.push('/');
					this.$store.dispatch('logout')
				}
			})
		},
		getSessionTypeByTherapist(){
			getTherapistSessionTypes(this.$store.state.user.id).then(response => {
				this.sessionTypes = response
			});
		},
		callGetTherapists() {
			getTherapists().then(team => {
				this.therapists = team.members
			})
		},
		getInitialDate() {
			let initialDate = new Date();
			if (this.$router.options.history.state && this.$router.options.history.state.initialDate !== undefined) {
				initialDate = new Date(this.$router.options.history.state.initialDate );
			}
			return initialDate
		},
		getFormattedDate(dateToFormat) {
			return `${dateToFormat.toISOString().split('T')[0]}T${dateToFormat.toLocaleTimeString('fr-FR')}`
		},
		filterSessionTherapists(){
			if(this.selectedTherapist === 'Tous') {
				this.updateEvents(this.sessions)		
			} else {
				this.updateEvents(this.sessions.filter(elt => elt.therapistId === this.selectedTherapist.id))
			}
		},
		filterSessionTypeTherapists() {
			if(this.sessionType === 'Tous') {
				this.updateEvents(this.sessions)		
			} else {
				this.updateEvents(this.sessions.filter(elt => elt.sessionTypeId === this.sessionType.id))
			}
		},
		updateEvents(sessions) {
			let defineColor = this.defineColor;
			this.calendarOptions.events = sessions.map(function(elt){	
				return {
					title : elt.title, 
					start: new Date(elt.date + " " + elt.startTime), 
					end: new Date(elt.date + " " + elt.endTime),
					id: elt.id, 
					color: defineColor(elt)
				};
			});
		},
		defineColor(elt) {
			let color;
			if (elt.validated === false)
				return "#FF0000"
			if (this.colorPersonalization.type === COLORS_TYPES.CLUBS.value) {
				color =  _.find(this.colorPersonalization.colors, el => el.objectId === elt.clubId)
			} else if (this.colorPersonalization.type === COLORS_TYPES.THERAPISTS.value){
				color = _.find(this.colorPersonalization.colors, el => el.objectId === elt.therapistId)
			}
			
			if (color)
				return color.hexa
			
			return this.colorPersonalization.type !== COLORS_TYPES.DEFAULT.value ? 'darkblue' : elt.paid ? (elt.patientPresent ? 'green':'orange') :'darkblue'
		},
		dateClick(event) {
			let initialDate = event.dateStr
			if (event instanceof PointerEvent)
				initialDate = new Date()

			this.$router.push({name: 'CreateSession', state: { initialDate }})
		},
		sessionClick(event) {
			this.$router.push({ name: 'EditSession', params: { id : event.event._def.publicId } })
		}
	}
})
</script>
<style scoped>
#full-calendar * {
	margin-right: 1rem;
}

#filter-therapist-container {
	margin: 2rem 0 2rem 0;
}
</style>