<template>
 <header>
    <div class="navbar">
        <h1 v-if="!isMobile" class="logo" @click="redirectToMainPage"> Planner</h1>
        <nav>
            <ul>
                <li @click="detailUser" data-cy="user-details"><font-awesome-icon icon="user"/></li>
                <li @click="logout" data-cy="logout">Se déconnecter</li>
            </ul>
        </nav>
    </div>
</header>   
</template>

<script>
import { goToMainPage } from '../../services/route-utils';

export default ({
  name: 'PatientHeader',
  data() {
      return {
        isMobile: this.$store.state.isMobile
      }
  },
  methods: {
    detailUser() {
      this.$router.push('/patients/user')
    },
    logout(e) {
        this.$store.dispatch('logout').then( 
            () => {
                localStorage.removeItem('jwtToken');
                this.$router.push('/');
            },
            ()=> {}
        );
        
        e.preventDefault();
      },
    redirectToMainPage() {
        goToMainPage(this.$router)
    }
  }
})
</script>
