<template>

<header>
    <div class="navbar">
        <h1 v-if="!isMobile" class="logo" @click="redirectToMainPage()"> Planner</h1>
        <nav>
            <ul>
                <li @click="showProLoginModal = true" class="highlight">Connexion praticien</li>
                <li @click="showLoginModal = true">Connexion patient</li>
            </ul>
        </nav>
    </div>
    <LoginPublicModal v-if="showLoginModal" @exit="closeModal"/>
    <LoginAndSignUp v-if="showProLoginModal" :showUltimeSignUpModal="false" :defaultShowLoginModal="showProLoginModal" :defaultShowSignupModal="false" @exit="closeModal"/>
</header>   
</template>

<script>
import { mapState } from 'vuex'
import { goToMainPage } from '../../services/route-utils'
import LoginPublicModal from './LoginPublicModal.vue'

export default ({
  name: 'PublicHeader',
  components: {
    LoginPublicModal
  },
  data() {
    return {
      showLoginModal: false,
      showProLoginModal: false
    }
  },
  methods : {
    redirectToMainPage() {
      goToMainPage(this.$router)
    },
    goToProsWebpage() {
      this.$store.state.isPro = true
      this.$router.push('/pros')
    },
    closeModal() {
        this.showLoginModal = false;
        this.showProLoginModal = false;
    },
  },
  computed: {
		...mapState(['jwtToken', 'isMobile', 'isPro']),
	},
})
</script>

<style scoped>
.highlight {
  background-color: var(--light-color);
  border-radius: 5px;
  color: #FFFFFF;
  margin-right:   2rem;
  font-weight: 700;
}
</style>
