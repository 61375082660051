<template>

	<div>
		<button @click="redirectToCreation">Ajouter</button>
	</div>
	<h3>Liste des factures éditées</h3>
	<br/>
	<div v-if="showConfirmPayment" class="modal">
		<div class="modal-content">
			Vous allez marquer cette facture comme "payée".
			Cette opération est irréversible.
			Etes-vous sûr(e) ?
			<div class="modal-buttons">
				<button type="cancel" @click="cancelConfirmPayment">Annuler</button>
				<button @click="confirmPayment">Marquer comme payée</button>
			</div>
		</div>
	</div>
	
	<form id="bills-date-selection" class="apform">
		<label for="patient-name-filter">Nom:</label>
		<label for="startDate">Entre le:</label>
		<label for="endDate">Et le:</label>
		<label for="status-filter">Statut:</label>
		<input name="patient-name-filter" type="text" v-model="patientNameFilter" @input="filterBillsByPatientsName"/>
		<input name="startDate" type="date" v-model="startDate" @change="onClickPaginationHandler(0)">
		<input name="endDate" type="date" v-model="endDate" @change="onClickPaginationHandler(0)">
		
		<select v-model="statusFilterValue" name="status-filter" @change="updateStatusFilter" class="apform-fix-select">
			<option v-for="filter in billStatusFilter" :key="filter" :value="filter.value" :selected="statusFilterValue === filter.value">{{filter.toDisplay}}</option>
		</select>
	</form>
	<div v-if="errorMsg !== ''" class="bills-date-error-msg align-center">
		<p class="errorMessage">{{ errorMsg }}</p>
	</div>
	<div v-if="bills.length > 0" class="bill-cards-container">
		<div v-for="(bill, index) in bills" :key="index" class="bill-card">
			<div class="flex-container space-between">
				<div>
					<div class="bill-card-number">{{bill.billNumber}}</div>
					<div>{{ bill.patient.type=== patientTypes.INDIVIDUAL.value ? bill.patient.individual.lastname + ' ' + bill.patient.individual.firstname : bill.patient.type === null ? bill.patient.contactFirstname + ' ' + bill.patient.contactLastname : bill.patient.firm.companyName }}</div>
				</div>
				<div>
					<div v-if="!bill.fullyPaid" @click="showPaybillModal(bill)">
						<input name="pay-bill" type="checkbox" v-model="bill.fullyPaid" class="pay-bill-checkbox">
						<label for="pay-bill" class="bill-unpaid">A Payer</label>
					</div>
					<div v-else>
						<label for="pay-bill" class="bill-paid">Payée</label>
					</div>
				</div>
			</div>
			<div class="billing-card-footer flex-container space-between">
				<div class="flex-container billing-card-footer-prices">
					<div class="billing-card-footer-prices-item">{{ bill.totalPrice }}€ {{ bill.taxes === 0 ? 'net de TVA' : 'H.T'}}</div>
				</div>
				<div class="align-center">Emise le : {{ formatBillDate(bill.createdAt) }}</div>
				<div class="align-right">
					<font-awesome-icon icon="download" title="Télécharger" class="dl-bill-icon" @click="getGeneratedBilling(bill.id)"/>
				</div>
			</div>
		</div>
		<div class="pages-index-container">
			<vue-awesome-paginate
				:total-items="totalItems"
				:items-per-page="itemsPerPage"
				:max-pages-shown="maxPagesShown"
				v-model="bills"
				:on-click="onClickPaginationHandler"
				:hide-prev-next="true" 
				v-cloak
			/>
			<!-- hide-prev-next to prevent paginate component bug -->
		</div>
	</div>
	<div v-else>
		Aucun résultat trouvé pour votre recherche
	</div>
</template>

<script>
import { PATIENTS_TYPES } from '../../services/patientTypes'
import { BILL_STATUS_TYPES } from '../../services/billStatusTypes'
import { getBillingSessionDetails, markBillAsPaid } from './services/bills'
import { getTherapists } from './services/team'

export default {
	name: 'BillingList',
	components: {
	},
	data() {
		return {
			showConfirmPayment: false,
			billIdToConfirm: '',
			bills: [],
			patientTypes: PATIENTS_TYPES,
			billStatusFilter: BILL_STATUS_TYPES,
			statusFilterValue: 'ALL',
			patientNameFilter: '',
			startDate: null,
			endDate: null,
			totalItems: 0,
			itemsPerPage: 1,
			maxPagesShown: 1,
			errorMsg: '',
			therapists: [],
			isOfferUltime: false,
		}
	},
	methods: {
		updateStatusFilter() {
			this.getBillingDetails()
		},
		formatBillDate(currentDate) {
			const dateArray = new Date(currentDate).toLocaleDateString().split('/')
			return dateArray[1].padStart(2, '0') + '-' + dateArray[0].padStart(2, '0') + '-' + dateArray[2]
		},
		cancelConfirmPayment() {
			for(let i = 0; i < this.bills.length; i++) {
				if  (this.bills[i].id === this.billIdToConfirm) {
					this.bills[i].fullyPaid = false
					break;
				}
			}
			this.showConfirmPayment = false; 
			this.billIdToConfirm='';
		},
		confirmPayment() {
			markBillAsPaid(this.billIdToConfirm).then(() => {
				this.billIdToConfirm = ''
				this.showConfirmPayment =  false
				this.getBillingDetails()
			})
		},
		showPaybillModal(bill) {
			if (!bill.fullyPaid) {
				this.showConfirmPayment = true
				this.billIdToConfirm = bill.id
			}
		},
		redirectToCreation() {
			this.$router.push({name: 'Billing' });
		},
		getBillingDetails() {
			this.onClickPaginationHandler(0)
		},
		onClickPaginationHandler(pageNumber) {
			this.errorMsg = ''
			if (this.startDate > this.endDate) {
				this.errorMsg = 'La date de fin ne peut être inférieure à la date de début';
			} else {

				let realPageNumber = pageNumber == 0 ? pageNumber : pageNumber - 1
				getBillingSessionDetails({
						askedInfos: {
							startDate: this.startDate,
							endDate: this.endDate,
							page: realPageNumber,
							name: this.patientNameFilter,
							status: this.statusFilterValue
						}}
				).then((response) => {
					this.bills = response.content
					this.totalItems = response.totalElements
					this.itemsPerPage = response.itemsPerPage
					this.maxPagesShown = response.totalPages
				}).catch(() => {})
			}
		},
		getGeneratedBilling(billId) {
            this.$store.dispatch({
				type: 'getBilling',
				billId: billId
			}).then((response) => {
                let docUrl = document.createElement('a');
                docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }));
                docUrl.download="billing.pdf";
                docUrl.click();
			});
		},
		filterBillsByPatientsName() {
			getBillingSessionDetails({
					askedInfos: {
						startDate: this.startDate,
						endDate: this.endDate,
						page: 0,
						name: this.patientNameFilter,
						status: this.statusFilterValue
					}}
			).then((response) => {
				this.bills = response.content
				this.totalItems = response.totalElements
				this.itemsPerPage = response.itemsPerPage
				this.maxPagesShown = response.totalPages
			}).catch(() => {})
		},
		filterSessionTherapists(){
			let therapistId = ''
			if(this.selectedTherapist !== 'Tous') {
				therapistId = this.selectedTherapist.id
			}

			getBillingSessionDetails({
					askedInfos: {
						startDate: this.startDate,
						endDate: this.endDate,
						page: 0,
						name: this.patientNameFilter,
						status: this.statusFilterValue,
						therapistId: therapistId
					}}
			).then((response) => {
				this.bills = response.content
				this.totalItems = response.totalElements
				this.itemsPerPage = response.itemsPerPage
				this.maxPagesShown = response.totalPages
			}).catch(() => {})
		},
		callGetTherapists() {
			getTherapists().then(team => {
				this.therapists = team.members
				if (this.therapists.length >= 1) {
					this.selectedTherapist = this.therapists[0];
				}
			})
		},
	},
	mounted() {
		let date = new Date(new Date().toLocaleString("en-US", {timeZone: "UTC"}));
		this.endDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3');
		date.setMonth(date.getMonth()-3);
		date.setDate(1);
		this.startDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3');
		this.getBillingDetails();
		this.isOfferUltime = this.offerName = this.$store.getters.user.tenant.offer === 'ULTIME'
		if (this.isOfferUltime)
			this.callGetTherapists()

	}};
</script>
<style scoped>

#bills-date-selection {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
}
.bill-payment {
	font-size: 24px;
}

.bill-paid {
	color: var(--success-color);
	font-size: 14px;
    font-weight: 800;
}

.bill-unpaid {
	color: var(--deep-warning-color);
	font-size: 14px;
    font-weight: 800;
}

.bill-cards-container {
	margin: 0 auto;
}

@media (min-width: 995px) {
  .bill-cards-container {
    max-width: 1140px;
	margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .bill-cards-container {
    max-width: 90%;
	margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .bill-cards-container {
    max-width: 90%;
	margin: 0 auto;
  }
}

@media (max-width: 544px) {
  .bill-cards-container {
    max-width: 90%;
	margin: 0 auto;
  }
}

.bill-card {
	background-color: #fff;
    border-radius: 0.125rem;
    box-shadow: 0 2px 2px rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px rgba(0,0,0,.12);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
	padding: 1rem;
}

.bill-card-number {
	font-size: 16px;
	font-weight: 700;
}

.billing-card-footer {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}
.billing-card-footer-prices {
	margin-right: 1rem;
	min-width:10rem;
}

.billing-card-footer-prices-item {
	margin-right: 1rem;
}

.dl-bill-icon {
	font-size: 18px;
	cursor: pointer;
}
.pay-bill-checkbox {
	margin-right: 0.5rem;
}

.bills-date-error-msg {
	margin-bottom: 1rem;
}
</style>