import axios from 'axios';
import authHeaders from '../../../services/auth-header';

const ANIMALS_URL = process.env.VUE_APP_BASE_AP_URL+`/animals`;

export async function getAnimalDetails(animalId) {
	return axios.get(`${ANIMALS_URL}/${animalId}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function getAnimalSessions(animalId) {
	return axios.get(`${ANIMALS_URL}/${animalId}/sessions`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}


export async function getAnimalHealth(animalId) {
	return axios.get(`${ANIMALS_URL}/${animalId}/health`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function createVaccine(animalId, vaccine) {
	return axios.post(`${ANIMALS_URL}/${animalId}/health/vaccines`, vaccine, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function updateVaccine(animalId, vaccine) {
	return axios.put(`${ANIMALS_URL}/${animalId}/health/vaccines/${vaccine.id}`, vaccine, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function deleteVaccine(animalId, vaccineId) {
	return axios.delete(`${ANIMALS_URL}/${animalId}/health/vaccines/${vaccineId}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function createMedicalAppointment(animalId, appointment) {
	return axios.post(`${ANIMALS_URL}/${animalId}/health/appointments`, appointment, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function updateMedicalAppointment(animalId, appointment) {
	return axios.put(`${ANIMALS_URL}/${animalId}/health/appointments/${appointment.id}`, appointment, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function deleteMedicalAppointment(animalId, appointmentId) {
	return axios.delete(`${ANIMALS_URL}/${animalId}/health/appointments/${appointmentId}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}