import axios from 'axios';
import authHeaders from '../services/auth-header';

const FINANCE_FORECAST_URL = process.env.VUE_APP_BASE_AP_URL+`/finance/forecast`;

export async function saveFinanceForecast(financeForecast) {
    return axios.post(`${FINANCE_FORECAST_URL}`, financeForecast, {headers : { 'Authorization':authHeaders()}})
	.then((response) => {
        return Promise.resolve(response.data);
    })
	.catch(error => {
      return Promise.reject(error);
	});
}

export async function getFinanceForecast() {
    return axios.get(`${FINANCE_FORECAST_URL}`, {headers : { 'Authorization':authHeaders()}})
	.then((response) => {
        return Promise.resolve(response.data);
    })
	.catch(error => {
      return Promise.reject(error);
	});
}