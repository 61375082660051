import axios from 'axios';
import authHeaders from './auth-header';

const PUBLIC_PATIENT_URL = process.env.VUE_APP_BASE_AP_URL + `/public/patients`
const PUBLIC_SESSIONS_URL = process.env.VUE_APP_BASE_AP_URL + `/public/patients/sessions`
const PUBLIC_PATIENT_CREATION_CONFIRMATION = process.env.VUE_APP_BASE_AP_URL + '/public/validations/patient'

export async function createSession(session) {
	return axios.post(PUBLIC_SESSIONS_URL, session, {headers : { 'Authorization':authHeaders()}})
        .then(response => {
			if (response.data) 
				return Promise.resolve(response.data)
		})
		.catch(error => Promise.reject(error))
}

export async function createPatientFromPublic(patient) {
	return axios.post(PUBLIC_PATIENT_URL, patient)	
        .then(response => {
			if(response.data) {
				return Promise.resolve(response.data)
			}
		})
		.catch(error => Promise.reject(error))
}

export async function createPatientFromPublicAfterValidation(patient) {
	return axios.put(PUBLIC_PATIENT_CREATION_CONFIRMATION, patient)	
        .then(response => {
			if(response.data) {
				return Promise.resolve(response.data);
			}
		})
		.catch(error => Promise.reject(error))
}