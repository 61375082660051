<template>
<header>
    <div class="navbar">
        <a href="/"><h1 v-if="!isMobile" class="logo" @click="redirectToMainPage"> Planner</h1></a>
        <nav>
            <ul>
                <li @click="showSignupModal = true">Inscription</li>
                <li @click="showLoginModal = true">Connexion</li>
            </ul>
        </nav>
    </div>
    <LoginAndSignUp v-if="showLoginModal || showSignupModal" :showUltimeSignUpModal="false" :defaultShowLoginModal="showLoginModal" :defaultShowSignupModal="showSignupModal" @exit="closeModal"/>
</header>   
</template>

<script>
import { goToMainPage } from '../../services/route-utils';

export default ({
  name: 'ProHeaderNotLogged',
  data() {
      return {
        showLoginModal: false,
        showSignupModal : false,
        isMobile: this.$store.state.isMobile
      }
  },
  methods: {
    closeModal() {
        this.showLoginModal = false;
        this.showSignupModal = false;
    },
    redirectToMainPage() {
      goToMainPage(this.$router)
    }
  }
})
</script>
<style scoped>

</style>