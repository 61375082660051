<template>
    <div id="demo-thanks">
        <div>Merci pour la demande!</div>
        <div>Nous vous recontactons au plus vite pour vous proposer un rendez-vous</div>
        <div>A bientôt</div>
    </div>
</template>

<script>
export default ({
  // eslint-disable-next-line
  name: 'DemoThanks'
})
</script>

<style scoped>
#demo-thanks {
    text-align: center;
    width: 100%;
}
</style>