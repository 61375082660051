import { createSSRApp } from 'vue'
import App from './App.vue'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import Multiselect from 'vue-multiselect'
import Toaster from "@meforma/vue-toaster";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import VueAwesomePaginate from "vue-awesome-paginate";

import './assets/styles/index.scss';
import './assets/styles/navbar.css';
import './assets/styles/utilities.css';
import './assets/styles/spinner.css';
import './assets/styles/toggle.css';
import './assets/styles/mobile.css';
import "vue-awesome-paginate/dist/style.css";

import router from './router';
import './services/axio-config';

import LoginAndSignUp from './components/pros/LoginAndSignUp'
import CookieComply from './components/cookie-banner/CookieComply'

library.add(fas)
library.add(far);
library.add(faFacebook);
library.add(faTwitter);
library.add(faLinkedin);


//TODO https://stackoverflow.com/questions/71905108/vue3-persisting-state-with-page-refresh
// and https://github.com/vuejs/pinia/issues/309
const app = createSSRApp({ extends: App, 
                        created() {
                            this.$store.dispatch('loadStoredState')
                        }})
app.use(store)
   .use(Toaster, {
       position: "top-right",
       duration: 3000
    })
   .use(router)
   .use(VueMeta, {
       refreshOnceNavigation: true
   })
   .use(VueGtag, {
       config: { id: "G-5RKND351LB" }
    }, router)
   .use(VueAwesomePaginate)
   .component('LoginAndSignUp', LoginAndSignUp)
   .component('font-awesome-icon', FontAwesomeIcon)
   .component('font-awesome-layers', FontAwesomeLayers)
   .component('font-awesome-layers-text', FontAwesomeLayersText)
   .component('multiselect', Multiselect)
   .component('VueCookieComply', CookieComply)
   .mount('#app');

   