<template>
 
 <section class="flex-container global-infos">
  <div id="public-features-title-first-container">
    <h1 class="public-features-title-first">Consultez un professionnel</h1>
    <div class="public-features-content">
      <ul class="global-infos-list">
        <li><font-awesome-icon icon="fa-solid fa-check" class="global-infos-list-check"/>Accédez aux disponibilités de professionnels</li>
        <li><font-awesome-icon icon="fa-solid fa-check" class="global-infos-list-check"/>Réservez en quelques clics</li>
        <li><font-awesome-icon icon="fa-solid fa-check" class="global-infos-list-check"/>Profitez des bénéfices de ces séances</li>
      </ul>
    </div>
  </div>
  <div>
    <img class="greeting-img" src="../../assets/imgs/homepage.png" />
  </div>
 </section>
 
 <section id="public-greetings-pro-section">
    <h3 class="public-greetings-pro-section-header">Vous êtes praticien(ne) ?</h3>
    <h4 class="public-greetings-pro-section-sub-header">Rejoignez Animal-Planner pour faciliter votre travail.</h4>
    <div class="public-greetings-pro-section-items">
        <div>
            <font-awesome-icon icon="calendar-alt" class="greetings-icon" alt="gérez vos séances"/>
            <div>
              <p>Simplifiez la gestion de vos rendez-vous</p>
            </div>
        </div>
        <div>
            <font-awesome-icon icon="address-book" class="greetings-icon" alt="historique patients animaux"/>
            <div>
              <p>Historique patients et/ou animaux en 1 clic</p>
            </div>
        </div>
        <div>
            <font-awesome-icon icon="file-invoice-dollar" class="greetings-icon" alt="gestion facturation"/>
            <div>
              <p>Gagnez en confort pour la facturation</p>
            </div>
        </div>
    </div>
    <div id="link-to-pro-container">
      <a href="/pros" id="link-to-pro">Découvrir Animal-Planner Pro</a>
    </div>
</section>
<public-footer/>
</template>

<script>
import PublicFooter from './PublicFooter.vue'

export default {
  components: { PublicFooter },
  name: 'PublicGreetings'
}
</script>

<style scoped>

#public-features-title-first-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.public-features-title-first {
  font-weight: 900;
  font-size: 40px;
  line-height: 4rem;
}
.public-features-title {
  margin-top:2rem;
}

.public-features-consult-content {
  margin-top: 3rem;
}

.global-infos {
  width: 80%;
  margin: 0 auto;
}

.global-infos > div {
  flex: 1;
}

.global-infos-list {
  padding: 0 2rem;
  align-content: center;
  display: grid;
  height: 100%;
  justify-content: center;
  font-size: 16px;
  font-weight: 900;
  line-height: 4.5rem;
}

.global-infos-list-check {
  margin-right: 0.2rem;
  color: var(--light-color);
  font-size: 16px;
}

.greeting-img {
  width: 30rem;
  border-radius: 1rem;
}

#public-greetings-pro-section {
  margin: 3rem auto;
  padding: 1rem 0;
  background-color: var(--cream-color);
  border-radius: 10px;
  text-align: center;
  width: 50%;
}

.public-greetings-pro-section-header {
  font-size: 26px;
  line-height: 2rem;
  color: #6E6E0D
}

.public-greetings-pro-section-sub-header {
  font-size: 18px;
  font-weight: 600;
}

#link-to-pro-container {
  text-align: center;
  line-height: 2.6rem;
}

#link-to-pro {
  padding: 1rem 2rem;
  border-radius: .5rem;
  width: 5rem;
  background-color: #e6d99f;
  color: var(--light-color);
  text-decoration: none;
  font-weight: 700;
}

#link-to-pro:hover {
  color: #e6d99f;
  background-color: var(--light-color);
}

.public-greetings-pro-section-items {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    justify-content: space-between;
    margin: 2rem 0;
    font-weight: 600;
}

.public-greetings-pro-section-items .greetings-icon {
  font-size: 48px;
  color: var(--light-color);
  margin-bottom: 0.5rem;
}

@media (max-width: 800px) {
  .global-infos {
    display: block;
    width: 100%;

  }

  .global-infos-list {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5rem;
  }


  .public-features-title-first {
    text-align: center;
  }

  .greeting-img {
    display: none;
  }

  #public-greetings-pro-section {
    width: 80%;
  }
  
}
</style>
