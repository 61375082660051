import axios from 'axios';
import authHeaders from '../../../services/auth-header';

const PUBLIC_PATIENTS_URL = process.env.VUE_APP_BASE_AP_URL+'/public/patients';
const SHORT_SESSIONS_URL = process.env.VUE_APP_BASE_AP_URL+'/public/patients/sessions';

export async function getPatientInfos() {
	return axios.get(PUBLIC_PATIENTS_URL, { headers : { 'Authorization':authHeaders()}})	
	.then(response => {
		if(response.data) {
			return Promise.resolve(response.data);
		}
	})
	.catch(error => Promise.reject(error));
}

export async function updatePatientInfos(patient) {
	return axios.put(PUBLIC_PATIENTS_URL, patient, { headers : { 'Authorization':authHeaders()}})	
	.then(response => {
		if(response.data) {
			return Promise.resolve(response.data);
		}
	})
	.catch(error => Promise.reject(error));
}

export async function getNextSession() {
	return axios.get(SHORT_SESSIONS_URL, { params : {next: true}, headers : { 'Authorization':authHeaders()}})	
	.then(response => {
		if(response.data) {
			return Promise.resolve(response.data);
		}
	})
	.catch(error => Promise.reject(error));
}

export async function getSessions() {
	return axios.get(SHORT_SESSIONS_URL, {headers : { 'Authorization':authHeaders()}})	
	.then(response => {
		if(response.data) {
			return Promise.resolve(response.data);
		}
	})
	.catch(error => Promise.reject(error));
}

export async function cancelSessionById(sessionId) {
	return axios.delete(`${SHORT_SESSIONS_URL}/${sessionId}`, {headers : { 'Authorization':authHeaders()}})
					.then(response => Promise.resolve(response))
					.catch(error => Promise.reject(error))
}

export async function deleteAccount() {
	return axios.delete(PUBLIC_PATIENTS_URL, {headers : { 'Authorization':authHeaders()}})
					.then(response => Promise.resolve(response))
					.catch(error => Promise.reject(error))
}