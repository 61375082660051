import axios from 'axios';
import * as types from './mutation-types';
import { event } from 'vue-gtag'

const PATIENT_SIGNIN_URL = process.env.VUE_APP_BASE_AP_URL+`/public/login`;

export const loginPatient = async ({ commit }, body) => {
    
    return axios.post(PATIENT_SIGNIN_URL, {
      email: body.user.email,
      password: body.user.password,  
    })
    .then(response => {
        if(response.data) {
            event('login', { method: 'standard' });
            commit(types.LOGIN, response.data);
            return Promise.resolve();
        }
    })
    .catch(function (error) {
        if (error.response.status === 404) 
            commit(types.USER_NOT_FOUND);
        else
            commit(types.LOGIN_ERROR);
        return Promise.reject(error);    
    })
}