<template>
	<div v-if="showDeleteModal" class="modal">
		<div class="modal-content">
			<p>Etes vous sûr de vouloir supprimer le compte de cette personne ? </p>
			<div class="modal-buttons">
				<button name="cancel" @click="showDeleteModal = false" class="button">Annuler</button>
				<button name="submit" @click="deleteMember(memberToDelete)" class="button button-danger">OK</button>
			</div>
		</div>
	</div>
	<div v-if="offerName === 'PRO' || offerName === 'ULTIME'">
		<div id="parameters-intro">
			<p v-if="offerName === 'PRO'">Ajouter ici votre assistant(e), et définissez ses droits.</p>
			<p v-else>Ajoutez ici vos assistant(e)s, et définissez leurs droits.</p>
		</div>
		
		<table class="data-table">
			<thead class="data-table-header">
				<tr>
					<th>Adresse mail</th>
					<th>Sessions</th>
					<th>Animaux</th>
					<th>Clubs</th>
					<th>Patients</th>
					<th>Devis</th>
					<th>Facturation</th>
					<th v-if="currentUserIsAdmin">Rôle</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(member, index) in members"
					:value="member"
					:key="index">
					<td>
						<input class="member-email" name="member-email" type="text" v-model="member.email"/>
					</td>
					<td>
						<input name="member-authorizations" type="checkbox" v-model="member.authorizations[0]"/>
					</td>
					<td>
						<input name="member-animals" type="checkbox" v-model="member.authorizations[1]"/>
					</td>
					<td>
						<input name="member-clubs" type="checkbox" v-model="member.authorizations[2]"/>
					</td>
					<td>
						<input name="member-patients" type="checkbox" v-model="member.authorizations[3]"/>
					</td>
					<td>
						<input name="member-estimations" type="checkbox" v-model="member.authorizations[4]"/>
					</td>
					<td>
						<input name="member-billings" type="checkbox" v-model="member.authorizations[5]"/>
					</td>
					<td v-if="currentUserIsAdmin">
						<select name="member-role" v-model="member.role" tabindex="4" required>
						<option v-for="role in roles" :key="role.description" :value="role.value" :selected="member.role === role.value">
							{{ role.toDisplay }}
						</option>
					</select>
					</td>
					<td>
						<button v-if="!member.id" @click="saveAndInvite(member)" class="button-small">Inviter</button>
						<div v-if="member.id" class="members-buttons">
							<button @click="updateAuthorizations(member)" class="button-small">MISE A JOUR</button>
							<font-awesome-icon icon="trash-alt" @click.stop="displayDeleteModal(member)" class="trash data-table-edit-icon" />
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { PRIVILEGES } from '../../services/privileges';
import { ROLES } from '../../services/roles';
export default {
    name: 'APTeam',
    data() {
        return {
            offerName: '',
			members: [
				{
					email: '',
					authorizations: [ true, true, true, true, false, false ],
					role: ROLES.ASSISTANT.value
				}
			],
			nbMaxAssistants: 0,
			nbMaxTherapists: 0,
			currentUserIsAdmin: false,
			roles: [ROLES.ASSISTANT, ROLES.THERAPIST],
			showDeleteModal: false,
			memberToDelete: {}
        }
    },
	methods: {
		displayDeleteModal(member) {
			this.showDeleteModal = true;
			this.memberToDelete = member;
		},
        getUserDetails() {
            this.$store.dispatch({
				type: 'getUserDetails',
			}).then(userDetails => {
                this.offerName = userDetails.tenant.offer
				this.nbMaxAssistants = userDetails.tenant.nbMaxAssistants
				this.nbMaxTherapists = userDetails.tenant.nbMaxTherapists
				this.updateTeam()
            });
        },
		updateTeam() {
			this.members = [
				{
					email: '',
					authorizations: [ true, true, true, true, false, false ],
					role: ROLES.ASSISTANT.value
				}
			];
			this.$store.dispatch({
					type: 'getTeam'
				}).then(team => {
					
					let teamMembers = team.members.map(member => {
						let authorizations = [false, false, false, false, false, false];

						member.authorizations.forEach(authorization => {
							authorizations[Object.keys(PRIVILEGES).indexOf(authorization.name)] = true;
						});

						return {
							id: member.id,
							email: member.email,
							authorizations: authorizations,
							role: member.role
						};
					});
					
					if (teamMembers.length != 0)
						this.members = teamMembers;

					if (this.offerName === 'ULTIME' && teamMembers.length != 0 && teamMembers.length < (this.nbMaxAssistants + this.nbMaxTherapists))	
						this.members.push({
							email: '',
							authorizations: [ true, true, true, true, false, false ],
							role: ROLES.ASSISTANT.value
						});
				})
		},
		saveAndInvite(m) {
			this.$store.dispatch({
				type: 'saveAndInvite',
				member: {
					id : m.id,
					email: m.email,
					authorizations: this.fromIndexAuthorizationToAuthorization(m.authorizations),
					role: m.role
				}
			}).then(() => {
				this.updateTeam();
				this.$toast.success('Invitation envoyée')
            }).catch((error) => {
				if (error.response.data.errorCode === 'E102')
					this.$toast.error('Adresse mail déjà utilisée');
				else
					this.$toast.error('Erreur : Vérifiez vos paramètres');
			});			
		},
		updateAuthorizations(m) {
			this.$store.dispatch({
				type: 'updateAuthorizations',
				member: {
					id : m.id,
					email: m.email,
					authorizations: this.fromIndexAuthorizationToAuthorization(m.authorizations),
					role: m.role
				}
			}).then(() => {
				this.updateTeam();
				this.$toast.success('Autorisations mises à jour');
            }).catch((error) => {
				if (error.response.data.errorCode === 'E126')
					this.$toast.error("L'addresse mail ne peut être changée.");
			});			
		},
		deleteMember(member) {
            this.$store.dispatch({
				type: 'deleteMember',
				id: member.id
			}).then(() => {
				this.updateTeam();
				this.showDeleteModal = false;
				this.$toast.success('Utilisateur supprimé')
            });			
		},
		fromIndexAuthorizationToAuthorization(authorizations) {
			return authorizations.map((auth, index) => {
				if (auth === true)
					return {name: Object.values(PRIVILEGES)[index]};
			}).filter(function( element ) {
				return element !== undefined;
			});
		}
	},
    created() {
		this.getUserDetails();
		this.currentUserIsAdmin = this.$store.getters.roles === ROLES.ADMIN.value;
	}
}
</script>
<style scoped>
#parameters-intro {
	margin: 1rem 0px 2rem 1rem;
}

.members-buttons {
	display: flex;
	align-items: center;
}

.member-email {
	border: 1px solid #e8e8e8;
    border-radius: 5px;
	width: 15rem;
	height: 2rem;
}
</style>
