<template>

    <div v-if="!userPropertiesExists" v-cloak>
		<FillUserInfos/>
	</div>
	<div v-if="!userPropertiesExists && (isAssistant || selectedTherapist.id !== $store.getters.user.id)" v-cloak>
        <p>Pour continuer, le thérapeute doit remplir ses informations.</p>
	</div>
	<div v-if="userPropertiesExists" v-cloak>

		<h3>Facturation</h3>
		<form id="billing-form" class="apform" accept-charset="UTF-8">
			<div>
				<div v-if="therapists.length > 1"> 
					<label for="therapist">Practicien</label>
					<select
						class="zoo-input select-patient-club-animal"
						name="therapist"
						id="therapist-select"
						v-model="selectedTherapist"
						tabindex="1"
						@change="getMemberDetails()"
					>
						<option disabled :value="Choisissez">Choisissez</option>
						<option value='Tous'>Tous</option>
						<option
							v-for="(therapist, index) in therapists"
							v-bind:value="therapist"
							v-bind:key="index"
						>
							{{ therapist.firstname }} {{ therapist.lastname }}
						</option>
					</select>
				</div>
				<div v-else-if="!isOfferStandard">
					Vous générez une facture pour {{userDetails.firstname}} {{userDetails.lastname}}
				</div>
			</div>

			<div>
				<label for="patients" class="billing-label">Patient</label>

				<multiselect v-model="selectedPatient" :options="patients" :close-on-select="true" placeholder="Aucun" :custom-label="nameIndividualOrFirm" class="multi-select-patient-input">
				</multiselect>
			</div>

			<div v-if="Object.keys(selectedPatient).length === 0" class="fillUserInfoMsg">
				<span>
					<font-awesome-icon icon="exclamation-circle" />
					<span style="margin-left:1rem">En attente de sélection d'un patient...</span>
				</span>
			</div>
			<div v-else> <!-- ameliore ca -->
				<div>
					<h4>Informations</h4>
					<div class="flex-container">
						<label>Mode simplifié</label>
						<div id="toggle-div">
							<input type="checkbox" id="toggle_input" tabindex="4" v-model="simplifiedMode" @change="resetPrices">
							<label for="toggle_input" class="toggle_label">Toggle</label>
						</div>
					</div>
					<div class="flex-container">
						<label for="bill-number">Numéro de facture</label>
						<input name="bill-number" class="bill-number-input" type="text" v-model="billNumber"/>
						<font-awesome-icon icon="exclamation-circle" title="généré à partir du dernier numéro connu, 1 si ce n'était pas un numéro"/>
					</div>
					<div class="flex-container">
						<label for="taxes">T.V.A (en %)</label>
						<input name="taxes" type="checkbox" v-model="withTaxes"/>
						<input v-if="simplifiedMode" name="taxesAmount" type="number" v-model="taxes" max="100" min="0" @change="simplifiedMode ? updateSimplifiedPrices():updateDetailedPrices()"/>
					</div>
					<div v-if="!withTaxes">
						<label for="comments">Mention supplémentaire</label>
						<textarea id="taxes-comment-text" name="comments" type="text" v-model="taxesComment" rows="2" cols="75"/>
					</div>
				</div>

				<div v-if="Object.keys(selectedPatient).length !== 0">
					<div v-if="!simplifiedMode">
						<DetailedBilling @dataReceived="setBillingAndComputePrices" :withTaxes="withTaxes"/>
					</div>
					<div v-else>
						<SimplifiedBilling :patient="selectedPatient" :therapist="selectedTherapist" @dataReceived="setBillingAndComputePrices" :key="selectedPatient"/>
					</div>
				</div>

				<div class="flex-container">
					<font-awesome-layers class="fa-lg times-circle-layer-style" title="La T.V.A est différente selon les produits, veuillez appliquer la réduction sur chaque ligne" v-if="differentTaxesAmount">
						<font-awesome-icon icon="fa-regular fa-circle" />
						<font-awesome-icon icon="fa-info" transform="shrink-4" />
					</font-awesome-layers>
					<label for="global-discount">Remise générale</label>
					<input name="global-discount" type="number" placeholder="Remise" v-model="discount" :disabled="differentTaxesAmount" @change="simplifiedMode ? updateSimplifiedPrices():updateDetailedPrices()"/>
					<select name="discountType" v-model="discountType" class="small-input"  @change="simplifiedMode ? updateSimplifiedPrices():updateDetailedPrices()">
						<option v-for="discountTypeOpt in discountTypes" :key="discountTypeOpt" :value="discountTypeOpt" :selected="discountType === discountTypeOpt">
							{{ discountTypeOpt.label }}
						</option>
					</select>
				</div>

				<div class="billing-summary">
					<p><span style="display: inline-block; width:10rem;">Total HT</span>{{ totalWithoutTaxes }}</p>
					<p><span style="display: inline-block; width:10rem;">Remise générale</span>{{ globalDiscount }}</p>
					<p><span style="display: inline-block; width:10rem;">Total HT final</span>{{ totalWithoutTaxesFinal }}</p>
					<p v-if="withTaxes"><span style="display: inline-block; width:10rem;">TVA</span>{{ totalTaxesAmount }}</p>
					<p><span style="display: inline-block; width:10rem;">Total</span>{{ totalWithTaxes }}</p>
				</div>

				<div>
					<h3>Règlement</h3>

					<div>
						<label for="terms-of-payment">Conditions de règlement</label>
						<select name="terms-of-payment" v-model="selectedTermOfPayment" class="default-input">
							<option v-for="term in termsOfPayment" :key="term.id" :value="term" :selected="selectedTermOfPayment === term">
								{{ term.value }}
							</option>
						</select>
					</div>

					<div>
						<label>Mode de règlement</label>
						<select name="payment-choices" v-model="selectedPaymentChoice" class="default-input">
							<option v-for="choice in paymentChoices" :key="choice" :value="choice" :selected="selectedPaymentChoice === choice">
								{{ choice.value }}
							</option>
						</select>
					</div>

					<div>
						<label>Intérêts de retard</label>
						<select name="late-interests" v-model="selectedLateInterest" class="default-input">
							<option v-for="interest in lateInterests" :key="interest" :value="interest" :selected="selectedInterest === interest">
								{{ interest.value }}
							</option>
						</select>
					</div>
				</div>	

				<div>
					<h3>Textes affichés sur le document</h3>
					<div>
						<textarea placeholder="Texte d'introduction (visible sur la facture)" rows="3" class="billing-textarea" v-model="introText"/>
					</div>
					<div>
						<textarea placeholder="Texte de conclusion (visible sur la facture)" rows="3" class="billing-textarea" v-model="concludeText"/>
					</div>
				</div>

				<div v-if="errorMsg !== ''" class="errorMessages error-billing">
					{{  errorMsg }}
				</div>
				<div class="form-submission">
					<button name="submit" type="submit" tabindex="15" @click="getDraftBill">
						<div v-if="loadingDraft" class="loader init-bottom-margin"></div>
						<span>Voir l'aperçu</span>
					</button>
					<button name="submit" type="submit" @click="generateBilling">
						<div v-if="loading" class="loader init-bottom-margin"></div>
						<span>Générer</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import FillUserInfos from './FillUserInfos.vue'
import { ROLES } from '../../services/roles'
import { PATIENTS_TYPES } from '../../services/patientTypes'
import { getTherapists } from './services/team'
import { getDraftBill, createBilling, getPaymentTerms, getPaymentChoices, getPaymentLateInterests, getNextBillNumber } from './services/bills'
import DetailedBilling from './billings/DetailedBilling.vue'
import SimplifiedBilling from './billings/SimplifiedBilling.vue'
import { DISCOUNT_TYPES } from '../../services/discountTypes'

export default {
	// eslint-disable-next-line
	name: 'Billing',
	components: {
		FillUserInfos,
		DetailedBilling,
		SimplifiedBilling
	},
	data() {
		return {
			isAssistant: false,
			therapists: [],
			selectedTherapist: 'Tous',
			userDetails: {},
			selectedPatient: {},
			sessions: [],
			selectAll: false,
			withTaxes: true,
			taxes: 20,
			taxesComment: 'TVA non applicable selon l’article 293 B du Code Général des Impôts.',
			userPropertiesExists: false,
			patientsTypes: PATIENTS_TYPES,
			loading: false,
			loadingDraft: false,
			isOfferStandard: false,
			simplifiedMode: true,
			billNumber: '',
			termsOfPayment: [],
			paymentChoices: [],
			lateInterests: [],
			selectedInterest: {},
			selectedTermOfPayment: {},
			selectedPaymentChoice: {}, 
			selectedLateInterest: {},
			simplifiedBillingContent: [],
			detailedBillingContent: [],
			totalWithoutTaxes: 0,
			globalDiscount: 0,
			differentTaxesAmount: false,
			discount: 0,
			discountType: DISCOUNT_TYPES[1],
			discountTypes: DISCOUNT_TYPES,
			totalWithoutTaxesFinal: 0,
			totalTaxesAmount: 0,
			totalWithTaxes: 0,
			introText:'',
			concludeText:'',
			errorMsg: ''
		};
	},
	methods: {
		getNextBillNumber() {
			getNextBillNumber().then(response => {
				this.billNumber = response.billNumber
			})
			
		},
		setBillingAndComputePrices(data) {
			if (this.simplifiedMode) {
				this.simplifiedBillingContent = JSON.parse(JSON.stringify(data)) 
				this.updateSimplifiedPrices()
			} else {
				this.detailedBillingContent = JSON.parse(JSON.stringify(data))
				this.updateDetailedPrices()
			}
		},
		resetPrices() {
			this.totalWithoutTaxes = 0
            this.globalDiscount = 0
            this.totalWithoutTaxesFinal = 0
            this.totalTaxesAmount = 0
            this.totalWithTaxes = 0
			this.differentTaxesAmount = false
		},
		updateSimplifiedPrices(){
			this.resetPrices()
			this.simplifiedBillingContent.forEach((session) => {
				if (session.includeInBilling)
					this.totalWithoutTaxes += session.price
			})

			let discount = this.discount
			if (this.discountType.value === 'PERCENT' && this.discount !== 0)
				discount = (this.totalWithoutTaxes * (discount/100)).toFixed(2)
			this.globalDiscount = discount
		

			if (!this.withTaxes)
				this.taxes = 0
				
            this.totalWithoutTaxesFinal = (Number(this.totalWithoutTaxes) - Number(this.globalDiscount)).toFixed(2)
			this.totalTaxesAmount = (this.totalWithoutTaxesFinal * this.taxes/100).toFixed(2)
			this.totalWithTaxes = (Number(this.totalWithoutTaxesFinal) + Number(this.totalTaxesAmount)).toFixed(2)

		},
		updateDetailedPrices() {
			this.resetPrices()
			
			let lastTaxesValue = this.detailedBillingContent[0].taxesAmount
			if (!this.withTaxes)
				this.differentTaxesAmount = false
			else {
				this.detailedBillingContent.forEach((line) => {
					if (line.taxesAmount !== lastTaxesValue){
						this.differentTaxesAmount = true
					}
				})
			}

            this.detailedBillingContent.forEach((line) => {

				let priceWithoutTaxes = 0
				let priceWithTaxes = 0
				let lineDiscount = 0
				let lineDiscountWithGlobalDiscount = 0
				let taxes = 0

				if (line?.priceWithoutTaxes !== undefined && line?.taxesAmount !== undefined && line?.quantity !== undefined) {
					priceWithoutTaxes = (line.priceWithoutTaxes * line.quantity).toFixed(2)
					if (line.discount === undefined)
						line.discount = 0

					lineDiscount = line.discount  //remise par ligne
					if (line.discountType.value === 'PERCENT' && lineDiscount !== 0)
						lineDiscount = (line.priceWithoutTaxes * line.quantity * lineDiscount/100).toFixed(2)
						
					priceWithoutTaxes = (priceWithoutTaxes - lineDiscount).toFixed(2)
					//if (!this.differentTaxesAmount && this.discount !== 0) { //ajout de la reduction globale
					//	if (this.discountType === 'PERCENT')
					//		lineDiscountWithGlobalDiscount = (priceWithoutTaxes * this.discount/100).toFixed(2)
					//	else
					//		lineDiscountWithGlobalDiscount = lineDiscount + (this.discount/this.detailedBillingContent.length) // la remise generale pour une ligne c'est la remise generale divisée par le nombre de ligne
					//}

					priceWithoutTaxes = (priceWithoutTaxes - lineDiscountWithGlobalDiscount).toFixed(2) //prix HT toutes remises confondues
					priceWithTaxes = (priceWithoutTaxes * (1+(line.taxesAmount/100))).toFixed(2)        
					taxes = priceWithTaxes - priceWithoutTaxes
				}

				//total HT
				this.totalWithoutTaxes = (Number(this.totalWithoutTaxes) + Number(priceWithoutTaxes)).toFixed(2)

				//total HT final
				this.totalWithoutTaxesFinal = this.totalWithoutTaxes

				//TVA
				this.totalTaxesAmount = (Number(this.totalTaxesAmount) + Number(taxes)).toFixed(2)
				if (!this.withTaxes)
					this.totalTaxesAmount = 0

				//total
				this.totalWithTaxes = (Number(this.totalWithoutTaxesFinal) + Number(this.totalTaxesAmount)).toFixed(2)
            })

			if (!this.withTaxes)
				this.totalTaxesAmount = 0
			if (!this.differentTaxesAmount && this.discount !== 0) { //ajout de la reduction globale
				if (this.discountType.value === 'PERCENT')
					this.globalDiscount = (this.totalWithoutTaxes * this.discount/100).toFixed(2)
				else
					this.globalDiscount = this.discount

				//total HT final
				this.totalWithoutTaxesFinal = (Number(this.totalWithoutTaxes) - Number(this.globalDiscount)).toFixed(2)

				//TVA
				this.totalTaxesAmount = (Number(this.totalWithoutTaxesFinal) * lastTaxesValue/100).toFixed(2)
				if (!this.withTaxes)
					this.totalTaxesAmount = 0

				//total	
				this.totalWithTaxes = (Number(this.totalWithoutTaxesFinal) + Number(this.totalTaxesAmount)).toFixed(2)
			}
        },
		hasSelectedSessions() {
			//parcourir la liste des sessions pour voir s'il y en a au moins avec includeInBilling
			let filteredSessions = this.sessions.filter(s => s.includeInBilling === true);
			return filteredSessions.length > 0;
		},
		checkBillValidity() {
			if (this.simplifiedMode) {
				if (this.simplifiedBillingContent.filter(s => s.includeInBilling).length <= 0) {
					this.errorMsg = 'Au moins une session doit être sélectionnée.'
					return false;
				}
			} else {
				if (this.detailedBillingContent.length === 0 || 
					this.detailedBillingContent.filter(line => line.description === null 
													|| line.description === ''
													|| line.quantity === null  
													|| line.quantity === 0 
													|| line.priceWithoutTaxes === null
													|| line.priceWithoutTaxes === 0
													|| (line.taxes === 0 && this.withTaxes)).length !== 0) {
					this.errorMsg = 'La description, le prix, la tva et la quantité sont obligatoires sur chaque ligne.'
					return false;
				}
			}
			return true;
		},
		getDraftBill(e) {
			e.preventDefault()
			this.errorMsg = ''
			if (!this.checkBillValidity()) {
				return;
			}
			getDraftBill({
				bill: {
					billNumber: this.billNumber,
					patientId: this.selectedPatient.id,
					therapistId: this.selectedTherapist.id,
					withTaxes: this.withTaxes,
					taxes: this.taxes,
					taxesComment: this.taxesComment,
					simplifiedMode: this.simplifiedMode,
					selectedTermOfPayment: this.selectedTermOfPayment.id,
					selectedPaymentChoice: this.selectedPaymentChoice.id, 
					selectedLateInterest: this.selectedLateInterest.id,
					simplifiedBillingContent: this.simplifiedBillingContent.filter(s => s.includeInBilling).map(s => s.id),
					detailedBillingContent: this.detailedBillingContent,
					discount: this.discount,
					discountType: this.discountType,
					introText: this.introText,
					concludeText: this.concludeText
				}
			}).then((response) => {
                let docUrl = document.createElement('a');
                docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }));
                docUrl.download="billing_draft.pdf";
                docUrl.click();
			}).catch(()=>{
				this.$toast.error('Erreur lors de la génération du brouillon');
			}).finally(() => {
				this.loading = false;
			});
		},
		generateBilling(e) {
			e.preventDefault();
            this.errorMsg = ''
			if (!this.checkBillValidity()) {
				if (this.simplifiedMode)
					this.errorMsg = 'Au moins une séance doit être sélectionnée.'
				else
					this.errorMsg = 'Les descriptions sont obligatoires et les quantités doivent être supérieures à 0.'
				return;
			}

			this.loading = true;
			createBilling({
				bill: {
					billNumber: this.billNumber,
					patientId: this.selectedPatient.id,
					therapistId: this.selectedTherapist.id,
					withTaxes: this.withTaxes,
					taxes: this.taxes,
					taxesComment: this.taxesComment,
					simplifiedMode: this.simplifiedMode,
					selectedTermOfPayment: this.selectedTermOfPayment.id,
					selectedPaymentChoice: this.selectedPaymentChoice.id, 
					selectedLateInterest: this.selectedLateInterest.id,
					simplifiedBillingContent: this.simplifiedBillingContent.filter(s => s.includeInBilling).map(s => s.id),
					detailedBillingContent: this.detailedBillingContent,
					discount: this.discount,
					discountType: this.discountType,
					introText: this.introText,
					concludeText: this.concludeText
				}
			}).then((response) => {
                let docUrl = document.createElement('a');
                docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }));
                docUrl.download="billing.pdf";
                docUrl.click();
				this.$toast.success('Facture générée')
				this.$router.push({path:'/pros/billings/details'})
			}).catch((error)=>{
				if (error.errorCode && error.errorCode === 'E139')
					this.$toast.error('Le numéro de facture est déjà attribué');
				else 
					this.$toast.error('Erreur lors de la génération de la facture');
			}).finally(() => {
				this.loading = false;
			});
		},
		getMemberDetails() {
			let id = this.selectedTherapist === 'Tous' ? this.$store.getters.user.id : this.selectedTherapist.id;
			this.$store.dispatch({
				type: 'getMemberDetails',
				id
			}).then(therapist => {
				this.userDetails = therapist;
				this.mandatoryPropertiesExists();
				this.unitaryPrice = this.userDetails.defaultSessionPrice;
			});
		},
		getTherapists() {
			getTherapists().then(team => {
				this.therapists = team.members;
				if (this.therapists.length === 1 || this.isAssistant) {
					this.selectedTherapist = this.therapists[0];
					this.getMemberDetails();
				} else {
					this.selectedTherapist = this.therapists.filter(t => t.id === this.$store.getters.user.id)[0]
				}
			});
		},
		getUserDetailsWithFirm() {
			this.$store.dispatch({
				type: 'getUserDetailsWithFirm',
			}).then(userDetails => {
				this.userDetails = userDetails
				this.mandatoryPropertiesExists()
				this.unitaryPrice = this.userDetails.defaultSessionPrice
				
				if (this.userDetails.taxes !== null) {
					this.withTaxes = this.userDetails.taxes
					if (this.userDetails.taxes === false) {
						this.taxes = 0;
						this.taxesComment = 'TVA non applicable selon l’article 293 B du Code Général des Impôts.';
					} else 
						this.taxes = this.userDetails.tenant.taxesAmount;
				} else
					this.taxes = 20;
			});
        },
		
		mandatoryPropertiesExists() {
            if(this.userDetails !== null && Object.keys(this.userDetails).length !== 0
            && this.isEmpty(this.userDetails.firstname) === false
			&& this.isEmpty(this.userDetails.lastname) === false
            && this.userDetails.firm !== null && Object.keys(this.userDetails.firm).length !== 0)
            {
                this.userPropertiesExists = this.isEmpty(this.userDetails.firm.name)===false && this.isEmpty(this.userDetails.firm.siren)===false;
			} else {
				this.userPropertiesExists = false;
			}
		},
        isEmpty(strValue)
        {
            return !strValue || strValue.trim() === "" || (strValue.trim()).length === 0;
        },
		nameIndividualOrFirm(patient) {
			if (patient.type=== this.patientsTypes.INDIVIDUAL.value)
				return patient.individual.lastname + ' ' + patient.individual.firstname
			else if (patient.type=== this.patientsTypes.FIRM.value)
				return patient.firm.companyName
			else	
				return 'Aucun'
		}
	},
	computed: {
		...mapState(['patients']),
	},
	created() {
		this.isAssistant = this.$store.getters.roles === ROLES.ASSISTANT.value;
		this.getTherapists()
		if (!this.isAssistant) {
			this.getUserDetailsWithFirm();
		}
		let date = new Date(new Date().toLocaleString("en-US", {timeZone: "UTC"}))

		// Update the day without affecting the month/day when using toISOString()
		date.setDate(1)
		// Vincent - 29052023
		//this.billingStartDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3')
		//date.setMonth(date.getMonth()+1);
		//date.setDate(0);
		//this.billingEndDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3')

		if (Object.keys(this.patients).length === 0)
			this.$store.dispatch('initPatients')
				.then(() => {
					//everything's fine, nothing to do
					this.patients = this.$store.state.patients;
				});
				
		this.isOfferStandard = this.$store.getters.user.tenant.offer === 'STANDARD'

		getPaymentTerms().then(response=> {
			this.termsOfPayment = response
			this.selectedTermOfPayment = this.termsOfPayment[2]
		})

		getPaymentChoices().then(response => {
			this.paymentChoices = response
			this.selectedPaymentChoice = this.paymentChoices[0]
		})

		getPaymentLateInterests().then((response) => {
			this.lateInterests = response
			this.selectedLateInterest = this.lateInterests[0]
		})

		this.getNextBillNumber()
	}
}
</script>
<style scoped>
.billing-label {
	min-width: 7rem;
}

.therapist-comments {
	margin-top: 2rem;
}

.billing-textarea {
	padding: 2.133rem 1.067rem 1.067rem;
	min-width: 60%;
}

#taxes-comment-text {
	min-width: 48rem;
}

.error-billing {
	max-width: 50rem;
}

.bill-number-input {
	margin-right: 0.25rem !important;
}

</style>