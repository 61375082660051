import axios from 'axios';
import authHeaders from '../../../services/auth-header';

const SESSION_TYPES_QUESTION_URL = process.env.VUE_APP_BASE_AP_URL+`/tenant/personalize/session-types/`;

export async function saveQuestion(sessionTypeId, question) {
	return axios.post(`${SESSION_TYPES_QUESTION_URL}${sessionTypeId}/questions`, question, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function updateQuestion(sessionTypeId, question) {
	return axios.put(`${SESSION_TYPES_QUESTION_URL}${sessionTypeId}/questions/${question.id}`, question, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function deleteQuestion(sessionTypeId, questionId) {
	return axios.delete(`${SESSION_TYPES_QUESTION_URL}${sessionTypeId}/questions/${questionId}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function getQuestions(sessionTypeId) {
    return axios.get(`${SESSION_TYPES_QUESTION_URL}${sessionTypeId}/questions`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}