<footer>
    <div>
        <h3>Animal Planner</h3>
        <p>Copyright &copy; 2021</p>
    </div>
    <div>
        <nav>
            <ul>
                <li><router-link to="/pros/about">A propos</router-link></li>
                <li><router-link to="/pros/prices">Tarifs</router-link></li>
                <li><router-link to="/pros/cgu">CGU</router-link></li>
            </ul>
        </nav>
    </div>
    <div class="social">
        <a href="https://www.facebook.com/profile.php?id=100086582943522"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }"/></a>
        <a href="https://www.linkedin.com/company/91629801"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }"/></a>
    </div>
</footer> 