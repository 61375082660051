export const BILL_STATUS_TYPES = {
    ALL: {
        value: 'ALL',
        toDisplay: 'Toutes'
    },
    PAID: {
        value: 'PAID',
        toDisplay: 'Payées'
    },
    UNPAID: {
        value: 'UNPAID',
        toDisplay: 'Non payées'
    },
};