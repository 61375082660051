import axios from 'axios';
import authHeaders from '../services/auth-header';

const SESSIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/sessions`;
const SHORT_SESSIONS_URL = process.env.VUE_APP_BASE_AP_URL+'/short-sessions';

export async function getSessions(startDate, endDate) {
	if(startDate !== null && endDate !== null) {
		let calledURL = `${SHORT_SESSIONS_URL}?startDate=${startDate}&endDate=${endDate}`;
		return axios.get(calledURL, {headers : { 'Authorization':authHeaders()}})	
		.then(response => {
			if(response.data) {
				return Promise.resolve(response.data);
			}
		})
		.catch(error => {
			return Promise.reject(error);
		});
	} 
}


export async function validateSession(sessionId) {
	let calledURL = `${SESSIONS_URL}/${sessionId}/validate`;
	return axios.patch(calledURL, null, {headers : { 'Authorization':authHeaders()}})	
	.then(response => {
		if(response.data) {
			return Promise.resolve(response.data);
		}
	})
	.catch(error => {
		return Promise.reject(error);
	});
}

export async function cancelSession(sessionId) {
	let calledURL = `${SESSIONS_URL}/${sessionId}/cancel`;
	return axios.patch(calledURL, null, {headers : { 'Authorization':authHeaders()}})	
	.then(response => Promise.resolve(response.data))
	.catch(error => Promise.reject(error))
}

export async function getAudioCommentBySessionId(sessionId, audioId) {
	return axios.get(`${SESSIONS_URL}/${sessionId}/audios/${audioId}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => {
      return Promise.reject(error);
	}); 
}

export async function deleteAudioCommentById(sessionId, audioId) {
	return axios.delete(`${SESSIONS_URL}/${sessionId}/audios/${audioId}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => {
      return Promise.reject(error);
	}); 
}

export async function getAudioCommentsBySessionId(id) {
	return axios.get(`${SESSIONS_URL}/${id}/audios`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(error => {
      return Promise.reject(error);
	}); 
}

export async function getAudioContent(url) {
	return axios.get(url, {headers : { 'Access-Control-Allow-Origin': null}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data)
      }
	})
	.catch(error => {
      return Promise.reject(error)
	})
}

export async function updatePaymentSession(sessionId,  paid) {
	let calledURL = `${SESSIONS_URL}/${sessionId}?paid=${paid}`;
	return axios.patch(calledURL, null, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch((error) => {
		return Promise.reject(error)
	});
	
}