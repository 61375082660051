import axios from 'axios';
import * as types from './mutation-types';
import { event } from 'vue-gtag'

const SIGNIN_URL = process.env.VUE_APP_BASE_AP_URL+`/login`;
const SIGNUP_URL = process.env.VUE_APP_BASE_AP_URL+`/signup`;
const USER_URL = process.env.VUE_APP_BASE_AP_URL+`/user`;
const RESET_PASSWORD_URL = USER_URL + `/resetPassword`;
const CHECK_PASSWORD_CHANGE_URL = USER_URL + `/changePassword`;
const UPDATE_PASSWORD_URL = USER_URL + `/updatePassword`;

export const login = async ({ commit }, loginInfo) => {
    
    return axios.post(SIGNIN_URL, {
      email: loginInfo.user.email,
      password: loginInfo.user.password,  
    })
    .then(response => {
        if(response.data) {
            event('login', { method: 'standard' });
            commit(types.LOGIN, response.data);
            return Promise.resolve();
        }
    })
    .catch(function (error) {
        if (error.response.status === 404) 
            commit(types.USER_NOT_FOUND);
        else
            commit(types.LOGIN_ERROR);
        return Promise.reject(error);    
    })
}

export const register = async ({ commit }, registerInfo) => {
    
    return axios.post(SIGNUP_URL, registerInfo.user)
    .then(response => {
        if(response.status === 201) {
            event('register', { method: 'standard' });
            commit(types.REGISTER, response.data);
            return Promise.resolve(response.data);
        }
    })
    .catch(function (error) {
        // handle error
        return Promise.reject(error);
    })
}

export const logout = ({commit}) => {
	localStorage.removeItem('jwtToken')
    localStorage.removeItem('user')
    commit(types.LOGOUT)
}

export const askForResetPassword = async ({ commit }, resetPasswordInfo) => {
    return axios.post(RESET_PASSWORD_URL, {
        email: resetPasswordInfo.email
    }).then(response => {
        if (response.status === 204) {
            commit(types.RESET_PASSWORD);
            return Promise.resolve(response.data);
        }
    }).catch(error => {
        return Promise.reject(error.response);
    });
}

export const checkPasswordChange = async ({ commit }, checkPasswordInfo) => {
    return axios.get(CHECK_PASSWORD_CHANGE_URL+`?token=`+checkPasswordInfo.token).then(response => {
        if (response.status === 200) {
            commit(types.RESET_PASSWORD);
            return Promise.resolve(response.data);
        }
    })
}

export const updatePassword = async ({ commit }, updatePasswordInfo) => {
    return axios.post(UPDATE_PASSWORD_URL, {
        token: updatePasswordInfo.user.token,
        newPassword: updatePasswordInfo.user.newPassword
    }).then(response => {
        if (response.status === 200) {
            commit(types.RESET_PASSWORD);
            return Promise.resolve(response.data);
        }
    })
}
