<template>
  <LoginAndSignUp v-if="showSignupModal || showUltimeSignUpModal" :defaultShowSignupModal="showSignupModal" :defaultShowLoginModal="false" :showUltimeSignUpModal="showUltimeSignUpModal" :defaultOffer="offer" @exit="closeModal"/>
  <div style="width: 100%;">
    <div id="prices-title">
        <h3>1 mois d'essai gratuit</h3>
        <p>Utilisez la plateforme gratuitement pendant 1 mois sans engagement ni carte de crédit.</p>
    </div>
    <div id="prices-container">
      <div class="prices">
          <div class="prices-header">
            <h3>Standard</h3>
            <h2>30€ H.T par mois</h2>
          </div>
          <div class="prices-description">
            <ul>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Patients illimités</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Calendrier</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Devis et factures illimités</li>
            </ul>
          </div>    
          <div class="prices-footer">
            <button @click="register(offers.STANDARD)">S'inscrire</button>
          </div>
      </div>
      <div class="prices">
          <div class="prices-header">
            <h3>Pro</h3>
            <h2>45€ H.T par mois</h2>
          </div>
          <div class="prices-description">
            <ul>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Patients illimités</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Calendrier</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Devis et factures illimités</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Enregistrements vocaux des commentaires</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>1 assistant autorisé</li>
            </ul>
          </div>
          <div class="prices-footer">
            <button @click="register(offers.PRO)">S'inscrire</button>
          </div>
      </div>
      <div class="prices">
          <div class="prices-header">
            <h3>Ultime</h3>
            <h2>Nous contacter</h2>
          </div>
          <div class="prices-description">
            <ul>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Patients illimités</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Calendrier</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Devis et factures illimités</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Enregistrements vocaux des commentaires</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Plusieurs assistants  autorisés</li>
              <li><font-awesome-icon icon="check-circle" class="colored-check"/>Plusieurs praticiens autorisés</li>
            </ul>
          </div>
          <div class="prices-footer">
            <button @click="register(offers.ULTIME)">S'inscrire</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginAndSignUp from './LoginAndSignUp.vue'
import { OFFERS } from '../../services/offers'

export default ({
  // eslint-disable-next-line
  name: 'Prices',
  data() {
    return {
      offers: OFFERS,
      offer: OFFERS.STANDARD,
      showSignupModal: false,
      showUltimeSignUpModal: false,
    }
  },
  components: {
    LoginAndSignUp
  },
  methods : {
    closeModal() {
      this.showSignupModal = false;
      this.showUltimeSignUpModal = false;
    },
    register(signUpMode) {
        this.offer=signUpMode;
        if (this.offer === OFFERS.ULTIME) 
          this.showUltimeSignUpModal = true;
        else
          this.showSignupModal = true;
    }
  }
})
</script>

<style scoped>
#prices-title {
  margin: 2rem;
}

#prices-container {
  display: flex;
  justify-content: space-around;
  margin-top: 5rem;
  margin-left: 5rem;
}

.prices {
  border-radius: 20px;
  background-color: var(--secondary-color);
  color: var(--primary-color); 
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prices-description {
  min-width: 18rem;
}

.colored-check {
  color: var(--success-color);
  margin-right: 5px;
}

.prices-footer {
  display: flex;
  justify-content: center;
}

.modal-content > div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px) {
  #prices-container {
    display: block;
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .prices {
    margin-bottom: 2rem;  
  }
}
</style>
