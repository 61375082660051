import axios from 'axios';
import authHeaders from '../services/auth-header';
import * as types from './mutation-types';

const BILLINGS_URL = process.env.VUE_APP_BASE_AP_URL+`/billings`;
const ESTIMATIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/estimations`;

export const createEstimation = async ({ commit }, event) => {
	return axios.post(ESTIMATIONS_URL, event.estimation, {responseType: 'arraybuffer', headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
            commit(types.ESTIMATION_GENERATED, response.data);
            return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.ESTIMATION_GENERATED_ERROR);
        return Promise.reject(error);
	});
};

export const getEstimation = async ({ commit }, event) => {
	return axios.get(`${ESTIMATIONS_URL}/${event.estimationId}`, {responseType: 'arraybuffer',  headers : {'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
			commit(types.BILLING_GENERATED, response.data);
            return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.BILLING_GENERATED_ERROR);
        return Promise.reject(error);
	});
};

export const getBilling = async ({ commit }, event) => {
	return axios.get(`${BILLINGS_URL}/${event.billId}`, {responseType: 'arraybuffer',  headers : {'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
			commit(types.BILLING_GENERATED, response.data);
            return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.BILLING_GENERATED_ERROR);
        return Promise.reject(error);
	});
};
