<template>

    <div class="flex-container">
        <label class="billing-label">Séances entre le</label><input type="date" name="billing_start_date" v-model="billingStartDate" @change="getSessionsByPatientId()"/>
		<label>et le</label><input type="date" name="billing_end_date" v-model="billingEndDate" @change="getSessionsByPatientId()"/>
	</div>

    <table class="data-table">
        <thead class="data-table-header">
            <tr>
                <td><input type="checkbox" v-model="selectAll" @change="selectUnselectAll" /></td>
                <th>Date</th>
                <th>Heure début</th>
                <th>Heure fin</th>
                <th>Prix</th>
                <th>Payée</th>
                <th>Présence</th>
                <th>Déjà facturé</th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(session, index) in sessions"
                v-bind:key="index"
                @mouseover="hover = index"
                @mouseleave="hover = -1"
            >
                <td><input type="checkbox" v-model="session.includeInBilling" @change="sendDataToParent"/></td>
                <td>{{ session.date }}</td>
                <td>{{ session.startTime }}</td>
                <td>{{ session.endTime }}</td>
                <td>{{ session.price }}</td>
                <td><input type="checkbox" v-model="session.paid" disabled="disabled"/></td>
                <td v-if="session.patientPresent">Oui</td>
                <td v-else>Non</td>
                <td v-if="session.billId===null">Non</td>
                <td v-else>Oui</td>
            </tr>
        </tbody>
    </table>
    <div v-if="sessions.length === 0" class="fillUserInfoMsg">
        <span>
            <font-awesome-icon icon="exclamation-circle" />
            <span style="margin-left:1rem">Aucune session à facturer trouvée pour ce client</span>
        </span>
    </div>
</template>
<script>

export default {
    name: 'SimplifiedBilling',
    props:["patient", "therapist"],
    emits: ['dataReceived'],
    data() {
        return {
            therapistComments: '',
            sessions: [],
			billingEndDate: '',
			billingStartDate: '',
			initBillingDate: true,
            selectAll: false
        }
    },
    methods: {
		selectUnselectAll() {
			let self = this;
			this.sessions = this.sessions.map(function(elt){
				return {
					...elt,
					includeInBilling : self.selectAll
				}
			});
            this.$emit('dataReceived', this.sessions)
		},
        sendDataToParent() {
            this.$emit('dataReceived', this.sessions)
        },
        getSessionsByPatientId() {
            let sessionsDetails = {
						patientId : this.patient.id,
						startDate : this.billingStartDate,
						endDate: this.billingEndDate
				}
            if (this.therapist.id !== undefined)
                sessionsDetails = { ...sessionsDetails, therapistId: this.therapist.id}
			if (this.billingStartDate !== '' && this.billingEndDate !== '') {
				this.$store.dispatch({
					type: 'getSessionsByPatientId',
					sessionsDetails
				}).then(patientSessions => {
					let selectAll = this.selectAll;
					this.sessions = patientSessions.sessions.map(function(elt){
						return {
							...elt,
							includeInBilling: selectAll===true ? true : elt.billId === null
						}   
					})
                    this.sendDataToParent()
				})
			}
		},
    },
    mounted() {
        let date = new Date(new Date().toLocaleString("en-US", {timeZone: "UTC"}))

        // Update the day without affecting the month/day when using toISOString()
        date.setDate(1)

        this.billingStartDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3')
        date.setMonth(date.getMonth()+1);
        date.setDate(0);
        this.billingEndDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3')
        this.getSessionsByPatientId()
    },
    watch: {
        therapist : {
            handler() {
                this.getSessionsByPatientId()
            },
            deep: false
        },
        patient: {
            handler() {
                this.getSessionsByPatientId()
            },
            deep: false
        }
    }
}
</script>
