<template>
  <router-view/>
  <vue-cookie-comply
        :preferences="preferences"
        @on-accept-all-cookies="onAccept"
        @on-save-cookie-preferences="onSavePreferences"
      />

</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'App',
  metaInfo: {
    title: 'animal therapy planning, planning thérapie animale, zoothérapie, équithérapie, canithérapie',
    meta: [
      { property: 'og:title', content: 'planning thérapie animale'},
      { property: 'og:site_name', content: 'Animal Planner'},
      { property: 'og:description', content: 'Outil de planning zoothérapie - médiation animale'},
      { vmid: 'charset', charset: 'utf-8'},
      {itemprop: 'name', content: 'Animal Planner'},
      {itemprop: 'description', content: 'Outil de planning zoothérapie - médiation animale'},
    ],
    link: [
      {rel: 'canonical', href: 'https://www.animal-planner.com'}
    ]
  },
  data() {
    return {
      screen: {}
    }
  },
  created() {
    this.screen.width = screen.width
    this.screen.height = screen.height
    if (screen.width < 800) {
      this.$store.commit("DEFINE_PLATFORM", true);
    }
  },
  computed: {
		...mapState(['jwtToken', 'isPro']),
	},
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.logged-main {
  display: flex;
  flex: 1;
}

@media (max-width: 800px) {
  #app {
    width: 100%;
  }
}
</style>
