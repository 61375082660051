<template>
    <div>
        <h4>Types de séances</h4>
        <form class="apform">
            <div>
                <table>
                    <thead>
                        <th>Nom</th>
                        <th>Prix par défaut</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr v-for="sessionType in sessionTypes" :key="sessionType">
                            <td><input name="session-type-name" type="text" tabindex="1" v-model="sessionType.name" @blur="updateSessionType(sessionType)"/></td>
                            <td><input data-cy="session-taxes-price" name="default-price" type="number" tabindex="2" step=0.01 v-model="sessionType.defaultSessionPrice" @blur="updateSessionType(sessionType)"/></td>
                            <td><font-awesome-icon icon="trash-alt" @click.stop="callDeleteSessionType(sessionType)" class="trash trash-small data-table-edit-icon" /></td>
                        </tr>
                        <tr>
                            <td><input name="new-session-type-name" type="text" tabindex="3" v-model="newSessionType.name" @blur="saveNewSessionType"/></td>
                            <td><input data-cy="new-session-taxes-price" name="new-default-price" type="number" tabindex="4" step=0.01 v-model="newSessionType.defaultSessionPrice"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>

    <div id="questions-define-container">
        <h4>Définissez ici les questions proposées au patient lors de sa prise de rendez-vous.</h4>
        <form class="apform">
            <select v-model="selectedSessionType" tabindex="5" @change="retrieveQuestions()" class="session-types-list">
                <option v-for="s in sessionTypes" :key="s.id" :value="s">
                    {{ s.name }}
                </option>
            </select>
            
            <div v-for="(q, index) in questions" :key="index">
                <input type="text" class="session-type-question" v-model="q.text" @input="updateQuestion(q)" :ref="getRefName(q.id)"/>
                <font-awesome-icon icon="trash-alt" @click.stop="deleteQuestion(q)" class="trash trash-small data-table-edit-icon" />
            </div>
            <div>
                <input type="text"  class="session-type-question" placeholder="Question ?" v-model="newQuestion.text" @input="addQuestionToList"/>
            </div>

            <div class="modal-buttons">
				<button @click="goBack" name="cancel" tabindex="15" class="cancel">Annuler</button>
                <button @click="nothingToDo" name="submit">Enregistrer</button>
            </div>
        </form> 
    </div>
</template>

<script>
import { addSessionType, getSessionTypes, updateSessionType, deleteSessionType,  } from '../../services/personalization'
import { getQuestions, saveQuestion, updateQuestion, deleteQuestion } from './services/sessionType';
import _ from 'lodash';

export default {
    name:'SessionTypesForm',
    data() {
        return {
            sessionTypes: [],
            newSessionType: {},
            questions: [],
            selectedSessionType: {},
            newQuestion: {},
            debounce: null
        }
    },
    mounted(){
        this.getAllSessionTypes()
    },
    methods: {
        goBack(){
			this.$router.go(-1);
		},
        saveNewSessionType() {
            if (Object.keys(this.newSessionType).length > 0) {
                addSessionType(this.newSessionType).then((response) => this.sessionTypes = response)
                this.newSessionType = {}
            }
        },
        callDeleteSessionType(sessionType) {
            if (sessionType.length === 1) {
                this.$toast.error('Suppression impossible. Au moins 1 type de séance doit exister')
            }

            _.remove(this.sessionTypes, function(type) {
                return type.id === sessionType.id
            })
            deleteSessionType(sessionType.id)
        },
        updateSessionType(sessionType) {
            if (Object.keys(sessionType).length > 0 && sessionType.name !== '')
                updateSessionType(sessionType)
        },
        getAllSessionTypes() {
            getSessionTypes().then((response) => {
                this.sessionTypes = response;
                if (this.sessionTypes.length > 0)
                    this.selectedSessionType = this.sessionTypes[0]
                    this.retrieveQuestions()
            })
        },
        retrieveQuestions() {
            getQuestions(this.selectedSessionType.id).then(response => {
                this.questions = response;
            })
        },
        updateQuestion(q) {
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                updateQuestion(this.selectedSessionType.id, q).then((response) => {q = response})
            }, 600)
            
        },
        deleteQuestion(question) {
            
            if (question.id !== undefined) {
                //call remove service
                deleteQuestion(this.selectedSessionType.id, question.id).then(() => {
                    _.remove(this.questions, function(q) {

            
                        return question.id === q.id
                    })
                })
                
            } else {
                _.remove(this.questions, function(q) {
                    return question.text === q.text
                })
            }
        },
        addQuestionToList() {
            if (this.newQuestion.text.length >= 3) {
                saveQuestion(this.selectedSessionType.id, this.newQuestion).then((response) => { 
                    this.questions.push(response)
                    this.$nextTick(() => {
                        this.$refs[this.getRefName(response.id)][0].focus()
                    })
                    this.newQuestion = {}
                })
            }
        },
        getRefName(id) {
            return `question-${id}`
        }
    }
}
</script>
<style scoped>

#questions-define-container {
    margin: 2rem 0;
}
.session-types-list {
    margin-bottom: 1rem;
}
.session-type-question {
    min-width: 34rem;
}
</style>