<template>
    <div class="therapist-card" @click="goToTherapistAvailability">
        <img v-if="therapist.logo !== undefined && therapist.logo !== null && therapist.logo !== ''" :src="therapist.logo" alt="logo entreprise"/>
        <div>
            <b>{{ therapist.firstname}} {{ therapist.lastname}}</b>
            <p v-if="therapist.address !== null">
                {{ therapist.address?.streetName }}
                {{ therapist.address?.zipCode }} {{ therapist.address?.city }}
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'APTherapist',
    props:['therapist'],
    methods: {
        goToTherapistAvailability() {
            this.$store.commit("CURRENT_THERAPIST", this.therapist);
            this.$router.push({name: 'APTherapistAvailability', params: {id : this.therapist.id}})
        }
    }
}
</script>

<style scoped>
.therapist-card {
    display: flex;
    align-items: center;
    border-radius: 0.2rem;
    border: 1px solid;
    margin: 1rem;
    padding: 0.5rem;
    box-shadow: 0 0 1px 0 rgb(50 50 50 / 20%), 0 2px 2px 0 rgb(50 50 50 / 20%);
    cursor: pointer;
    width:50%;
}

.therapist-card img {
    width: 6rem;
    border-radius: 70%;
    margin-right: 2rem;
}

</style>
