<template>

	<div v-if="isAssistant">
		<div v-if="therapists.length > 1"> 
			Choisissez le practicien : 
			<select
				class="zoo-input select-patient-club-animal"
				name="therapist"
				id="therapist-select"
				v-model="selectedTherapist"
				tabindex="1"
				@change="getMemberDetails()"
			>
				<option disabled :value="Choisissez">Choisissez</option>
				<option
					v-for="(therapist, index) in therapists"
					v-bind:value="therapist"
					v-bind:key="index"
				>
					{{ therapist.firstname }} {{ therapist.lastname }}
				</option>
			</select>
		</div>
		<div v-else>
			Vous générez un devis pour {{userDetails.firstname}} {{userDetails.lastname}}
		</div>
	</div>

    <div v-if="!userPropertiesExists && !isAssistant" v-cloak>
        <FillUserInfos/>
	</div>
	<div v-if="!userPropertiesExists && isAssistant" v-cloak>
        <p>Pour continuer, votre thérapeute doit remplir ses informations.</p>
	</div>
	<div v-if="userPropertiesExists" v-cloak>
		<h3>Devis</h3>

		<div v-if="errors.length" class="errorMessages">
			<div>Champs requis</div>
			<ul>
				<li v-for="(error, errorIndex) in errors" v-bind:key="errorIndex">{{ error }}</li>
			</ul>
		</div>

		<div id="estimations-form-choose-patient">
			<div @click="clearPatientInfos(true)" :class="{newPatientSelected: existingPatient}" style="cursor:pointer">Patient existant</div>
			<div @click="clearPatientInfos(false)" :class="{newPatientSelected: !existingPatient}" style="cursor:pointer">Nouveau patient</div>
		</div>

		<form id="estimations-form" class="apform" :class="existingPatient ? 'estimations-form-existing-patient':''" accept-charset="UTF-8">
			<div v-if="existingPatient === true">
					<label for="patient-select">Patient</label>
					<div style="margin-bottom:0">
						<multiselect v-model="selectedPatient" :options="patients" :close-on-select="true" placeholder="Aucun" :custom-label="nameIndividualOrFirm" class="multi-select-patient-input" @select="getPatientsList">
						</multiselect>
					</div>
			</div>
			
			<div v-if="existingPatient === false" style="display: block">
				<div id="patient-types-choices">
					<div v-for="(type, index) in patientTypes" :key="index" class="patient-type-choice">
						<input type="radio" class="patient-type-input" :name='type.value' :value='type.value' v-model="selectedPatient.type" :selected="selectedPatient.type === type.value">
						<label :for='type'>{{ type.toDisplay }}</label>
						<br>
					</div>
				</div>
				<div v-if="this.selectedPatient.type === patientTypes.INDIVIDUAL.value">
					<div>
						<label for="lastname">Nom</label>
						<input name="lastname" type="text" tabindex="1" v-model="selectedPatient.individual.lastname" placeholder="Nom" required autofocus />
					</div>
					<div>
						<label for="firstname">Prénom</label>
						<input name="firstname" type="text" tabindex="2" v-model="selectedPatient.individual.firstname" placeholder="Prénom" required/>
					</div>
				</div>
				<div v-if="this.selectedPatient.type === patientTypes.FIRM.value">
					<div>
						<label for="firm_name">Nom de l'entreprise</label>
						<input name="firm_name" type="text" tabindex="1" v-model="selectedPatient.firm.companyName" placeholder="Nom" required autofocus />
					</div>
					<div>
						<label for="siren">Siren</label>
						<input name="siren" type="text" tabindex="1" v-model="selectedPatient.firm.siren" placeholder="Siren" required autofocus />
					</div>
				</div>
				<div v-if="this.selectedPatient.type === patientTypes.FIRM.value">
					<div>
						<label for="referent_lastname">Nom de famille du contact</label>
						<input name="referent_lastname" type="text" tabindex="2" v-model="selectedPatient.firm.contactLastname" placeholder="Nom"/>
					</div>
					<div>
						<label for="referent_firstname">Prénom du contact</label>
						<input name="referent_firstname" type="text" tabindex="3" v-model="selectedPatient.firm.contactFirstname"  placeholder="Prénom"/>
					</div>
				</div>
				<div>
					<div>
						<label for="email">Adresse Mail</label>
						<input name="email" type="text" tabindex="3" v-model="selectedPatient.email" placeholder="E-mail" required/>
					</div>
					
					<div>
						<label for="phoneNumber">Téléphone</label>
						<input name="phoneNumber" type="text" tabindex="4" v-model="selectedPatient.phoneNumber" placeholder="Téléphone" />
					</div>
				</div>
				<div>
					<div>
						<label for="streetName">Rue</label>
						<input name="streetName" type="text" tabindex="5" v-model="selectedPatient.address.streetName" placeholder="Rue" />
					</div>
					<div>
						<label for="zipCode">Code postal</label>
						<input name="zipCode" type="text" tabindex="6" v-model="selectedPatient.address.zipCode" placeholder="Code postal" />
					</div>
					<div>
						<label for="city">Ville</label>
						<input name="city" type="text" tabindex="7" v-model="selectedPatient.address.city" placeholder="Ville" />
					</div>
				</div>
				<div id="save-patient-section">
					<input name="savePatient" type="checkbox" v-model="savePatient"/>
					<label for="savePatient" style="margin-left: 1rem;">Sauvegarder le patient</label>
				</div>
			</div>
			<div>
				<label for="unitaryPrice">Prix unitaire H.T</label>
				<input name="unitaryPrice" min="0" type="number" tabindex="8" v-model="unitaryPrice" placeholder="Prix d'une heure hors taxe" />
			</div>
			<div>
				<label for="nbHoursToDo">Heures à effectuer</label>
				<input name="nbHoursToDo" type="number" min="0" tabindex="9" v-model="nbHoursToDo" placeholder="Nombre d'heures à effectuer" />
			</div>
			<div v-if="this.selectedPatient.type === patientTypes.FIRM.value">
				<div>
					<label for="nbParticipants">Nombre de participants</label>
					<input name="nbParticipants" type="number" min="1" max="1000" tabindex="10" v-model="nbParticipants" />
				</div>
				<div v-if="nbParticipants === 1 && corporatePatients !== undefined && corporatePatients.size > 0">
					<label>Séance en faveur de</label>
					<multiselect v-model="selectedCorporatePatients" :options="corporatePatients" :multiple="false" :close-on-select="true" placeholder="Aucun" :preserve-search="true" :custom-label="fullName" track-by="id" selectLabel="" deselectLabel="">
					</multiselect>
				</div>
			</div> 
			<div>
				<label for="periodOfValidity">Durée de validité (en mois)</label>
				<input name="periodOfValidity" type="number" min="0" tabindex="11" v-model="periodOfValidity" placeholder="Durée de validité du devis" />
			</div>
			<div>
				<label for="taxes">T.V.A (en %)</label>
				<input name="taxes" min="0" type="number" tabindex="12" v-model="taxes" placeholder="Montant de T.V.A" />
			</div>
			<div v-if="taxes===0">
				<label for="comments">Mention supplémentaire</label>
				<textarea name="comments" type="text" v-model="comments" tabindex="13" rows="2" cols="75"/>
			</div>
			<div class="flex-container">
				<label for="therapistComments">Texte affiché sur le document</label>
				<textarea name="therapistComments" type="text" v-model="therapistComments" rows="14" cols="75"/>
			</div>
			<div class="form-submission">
				<button name="submit" type="submit" tabindex="15" @click="getDraftEstimation">
					<div v-if="loadingDraft" class="loader init-bottom-margin"></div>
					<span>Voir l'aperçu</span>
				</button>
				<button name="submit" type="submit" tabindex="15" @click="createEstimation">
					<div v-if="loading" class="loader init-bottom-margin"></div>
					<span>Finaliser</span>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import FillUserInfos from './FillUserInfos.vue'
import { ROLES } from '../../services/roles'
import { PATIENTS_TYPES } from '../../services/patientTypes'
import { getTherapists } from './services/team'
import { getDraftEstimation } from './services/estimations'

export default {
	// eslint-disable-next-line
	name: 'Estimations',
	components: {
		FillUserInfos
	},
	data() {
		return {
			isAssistant: false,
			therapists: [],
			selectedTherapist: {},
			userDetails: {},
			selectedPatient: {
				type: PATIENTS_TYPES.INDIVIDUAL.value,
				address:{},
				individual: {
					firstname: '',
					lastname: ''
				},
				firm: {}
				},
			corporatePatients: [],
			selectedCorporatePatients: [], //may be some days we could just have to convert {} to []
			nbHoursToDo: 0,	
			periodOfValidity: 3,
			unitaryPrice: 0,
			nbParticipants: 1,
			taxes: 20,
			comments: 'TVA non applicable selon l’article 293 B du Code Général des Impôts.',
			therapistComments: '',
			savePatient: false,
			userPropertiesExists: false,
			existingPatient: false,
			errors: [],
			patientTypes: PATIENTS_TYPES,
			loading: false,
			loadingDraft: false
		};
	},
	computed: {
		...mapState(['patients']),
	},
	methods: {
		clearPatientInfos(existingPatient) {
			this.errors = [];
			this.selectedPatient =  {
				type: PATIENTS_TYPES.INDIVIDUAL.value,
				address:{},
				individual: {},
				firm: {}
			};
			if (this.$store.getters.patients === null || this.$store.getters.patients === undefined || this.$store.getters.patients.length===0) {
				this.sendInitPatients();
			}

			this.existingPatient = existingPatient;
		},
		sendInitPatients() {
			this.$store.dispatch('initPatients').then(() => {}).catch(() => {});
		},
		checkForm() {
			this.errors = [];
			if (this.existingPatient && !this.selectedPatient.id) {
				this.errors.push('patient');
				return true;
			}

			if (PATIENTS_TYPES[this.selectedPatient.type] === PATIENTS_TYPES.INDIVIDUAL) {
				if (this.selectedPatient.individual.firstname === undefined || this.selectedPatient.individual.firstname === '') {
					this.errors.push(`Prénom`)
				}

				if (this.selectedPatient.individual.lastname === undefined || this.selectedPatient.individual.lastname === '') {
					this.errors.push(`Nom de famille`)
				}
			} else {
				if (this.selectedPatient.firm.companyName === undefined) {
					this.errors.push(`Nom de l'entreprise`)
				}
			}

			if (this.existingPatient === false && (this.selectedPatient.email === undefined || this.selectedPatient.email === '')) {
				this.errors.push(`E-mail`)
			}

			if (this.unitaryPrice === null || this.unitaryPrice === 0) {
				this.errors.push(`Prix unitaire`)
			}

			if (this.nbHoursToDo === undefined || this.nbHoursToDo === 0) {
				this.errors.push(`Nombre d'heures`)
			}

			return this.errors.length === 0;
		},
		getDraftEstimation(e) {
			e.preventDefault();
			if (this.checkForm()) {
				this.loadingDraft = true
				getDraftEstimation({
					estimation: {
						patient: this.selectedPatient,
						nbHoursToDo: this.nbHoursToDo,
						periodOfValidity: this.periodOfValidity,
						unitaryPrice: this.unitaryPrice,
						taxes: this.taxes,
						comments: this.taxes === 0 ? this.comments : '',
						therapistComments: this.therapistComments,
						therapistId: this.userDetails.id,
						nbParticipants: this.nbParticipants,
						selectedCorporatePatients: [this.selectedCorporatePatients.id]
					}}
				).then( (response) => {
					let docUrl = document.createElement('a')
					docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }))
					docUrl.download="estimation_draft.pdf"
					docUrl.click()
					docUrl.remove()
				}).catch((response)=>{
					const blob = new Blob([response.response.data], {type: 'text/plain; charset=utf-8'});
                    
                    blob.text().then((response) => {
                        let error = JSON.parse(response);
                        if (error.errorCode === 'E112')
                            this.$toast.error("L'adresse du patient doit être saisie dans sa fiche");
                        else
                            this.$toast.error('Erreur lors de la génération du devis');
                    });
					this.loading = false;
				}).finally(()=>{
					this.loadingDraft = false
				});
			}
		},
		createEstimation(e) {
			e.preventDefault();
			if (this.checkForm()) {
				this.loading = true;
				this.$store.dispatch({
					type: 'createEstimation',
					estimation: {
						patient: this.selectedPatient,
						nbHoursToDo: this.nbHoursToDo,
						periodOfValidity: this.periodOfValidity,
						unitaryPrice: this.unitaryPrice,
						taxes: this.taxes,
						comments: this.taxes === 0 ? this.comments : '',
						therapistComments: this.therapistComments,
						therapistId: this.userDetails.id,
						nbParticipants: this.nbParticipants,
						selectedCorporatePatients: [this.selectedCorporatePatients.id]
					}
				}).then( (response) => {
					let docUrl = document.createElement('a');
					docUrl.href = window.URL.createObjectURL(new Blob([response], { type:"application/pdf" }));
					docUrl.download="estimation.pdf";
					docUrl.click();

					if (this.savePatient) {
						let patient = this.selectedPatient;

						if (this.selectedPatient.type === PATIENTS_TYPES.INDIVIDUAL.value) {
							patient = {
								...patient,
								individual : {
									nbHoursToDo: this.nbHoursToDo,
									lastname: this.selectedPatient.individual.lastname,
									firstname: this.selectedPatient.individual.firstname,
								}
							}
						}

						if (this.selectedPatient.value === PATIENTS_TYPES.FIRM.value) {
							patient = {
								...patient,
								firm: {
									companyName : this.selectedPatient.firm.companyName
								}
							}
						}	


						this.$store.dispatch({
							type: 'addPatient',
							patient
						}).then(()=> {
							this.$toast.success(`Patient sauvegardé.`);
						})
						.catch(() => {
							this.$toast.warning(`Patient non sauvegardé. Adresse mail déjà associée à un patient existant.`);
						});
					}
					this.loading = false;
				}).catch((response)=>{
					const blob = new Blob([response.response.data], {type: 'text/plain; charset=utf-8'});
                    
                    blob.text().then((response) => {
                        let error = JSON.parse(response);
                        if (error.errorCode === 'E112')
                            this.$toast.error("L'adresse du patient doit être saisie dans sa fiche");
                        else
                            this.$toast.error('Erreur lors de la génération du devis');
                    });
					this.loading = false;
				});
			}
		},
		getMemberDetails() {
			this.$store.dispatch({
				type: 'getMemberDetails',
				id: this.selectedTherapist.id
			}).then(therapist => {
				this.userDetails = therapist;
				this.mandatoryPropertiesExists();
				this.unitaryPrice = this.userDetails.defaultSessionPrice;
			});
		},
		getTherapists() {
			getTherapists().then(team => {
				this.therapists = team.members;
				if (this.therapists.length === 1) {
					this.selectedTherapist = this.therapists[0];
					this.getMemberDetails();
				}
			});
		},
		getUserDetailsWithFirm() {
			this.$store.dispatch({
				type: 'getUserDetailsWithFirm',
			}).then(userDetails => {
				this.userDetails = userDetails;
				this.mandatoryPropertiesExists();
				this.unitaryPrice = this.userDetails.defaultSessionPrice;
				if (this.userDetails.taxes !== null) {
					if (this.userDetails.taxes === false) {
						this.taxes = 0;
						this.comments = 'TVA non applicable selon l’article 293 B du Code Général des Impôts.';
					} else 
						this.taxes = this.userDetails.taxesAmount;
				} else
					this.taxes = 20;
			});
        },
		mandatoryPropertiesExists() {
            if(this.userDetails !== null && Object.keys(this.userDetails).length !== 0
            && this.isEmpty(this.userDetails.firstname) === false
			&& this.isEmpty(this.userDetails.lastname) === false
            && this.userDetails.firm !== null && Object.keys(this.userDetails.firm).length !== 0)
            {
                    this.userPropertiesExists = this.isEmpty(this.userDetails.firm.name)===false && this.isEmpty(this.userDetails.firm.siren)===false;
			} else {
				this.userPropertiesExists = false;
			}
		},
        isEmpty(strValue)
        {
            return !strValue || strValue.trim() === "" || (strValue.trim()).length === 0;
        },
		nameIndividualOrFirm(patient) {
			if (patient.type=== this.patientTypes.INDIVIDUAL.value)
			{
				if (Object.keys(patient.individual).length > 0)
					return patient.individual.lastname + ' ' + patient.individual.firstname
			} else if (patient.type=== this.patientTypes.FIRM.value) {
				return patient.firm.companyName
			} else	
				return 'Aucun'
		},
		getPatientsList(patient) { //TODO don't need a dispatch
			if (patient && PATIENTS_TYPES[patient.type] === PATIENTS_TYPES.FIRM) {
				this.$store.dispatch({
					type: 'getPatientDetails',
					id: patient.id
				}).then(response => {
					this.corporatePatients = response.firm.corporatePatients;
				});
			} else {
				this.corporatePatients = []
			}
		},
		fullName(e) {
			return e.firstname + " " + e.lastname
		}
	},
	created() {
		this.isAssistant = this.$store.getters.roles === ROLES.ASSISTANT.value;
		if (this.isAssistant) {
			this.getTherapists();		
		} else {
			this.getUserDetailsWithFirm();				
		}
	},
};
</script>
<style scoped>
#estimations-form-choose-patient {
	display: flex;
	justify-content: space-around;
    width: 15rem;
	margin: 1rem 0 1rem 0;
	align-items: center;
	border: 1px solid lightgray;
	border-radius: 5px;
}
.newPatientSelected {
	border: 1px solid;
	padding: 5px;
	border-radius: 5px;
	background-color: var(--primary-color);
    color: var(--secondary-color);
	min-width: 5rem;
}

#estimations-form label{
   min-width: 12rem;
   display: block;
   margin-right: 1.8rem;
}

#estimations-form > div {
	display: flex;
	margin-bottom: 1rem;
}

#estimations-form > div > div {
	display: flex;
} 

#estimations-form-existing-patient > div {
	margin:2rem;
}

#save-patient-section {
	display: flex;
}

@media (max-width:800px) {
	#estimations-form-choose-patient {
		width: 100%;
	}

	#estimations-form > div > div:not(:first-child) {
		display: block;
	}

	#estimations-form label{
		margin-right: 0rem;
	}
}
</style>