export const DEFINE_PLATFORM = 'DEFINE_PLATFORM';
export const CURRENT_THERAPIST  = 'CURRENT_THERAPIST';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const REGISTER_ERROR = 'ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ASK_DEMO = 'ASK_DEMO';

export const INIT_ANIMALS = 'INIT_ANIMALS';
export const INIT_ANIMALS_ERROR = 'INIT_ANIMALS_ERROR';
export const ANIMAL_CREATED = 'ANIMAL_CREATED';
export const ANIMAL_UPDATED = 'ANIMAL_UPDATED';
export const ANIMAL_UPDATED_ERROR = 'ANIMAL_UPDATED_ERROR';
export const ANIMAL_NOT_CREATED = 'ANIMAL_NOT_CREATED';
export const ANIMAL_REMOVED = 'ANIMAL_REMOVED';
export const ANIMAL_REMOVED_ERROR = 'ANIMAL_REMOVED_ERROR';
export const GET_ANIMAL = 'GET_ANIMAL';
export const GET_ANIMAL_ERROR = 'GET_ANIMAL_ERROR';
export const GET_ANIMAL_ACTIVITY = 'GET_ANIMAL_ACTIVITY';
export const GET_ANIMAL_ACTIVITY_ERROR = 'GET_ANIMAL_ACTIVITY_ERROR';
export const GET_ANIMAL_TYPES = 'GET_ANIMAL_TYPE';

export const INIT_CLUBS = 'INIT_CLUBS';
export const CLUB_CREATED = 'CLUB_CREATED';
export const CLUB_NOT_CREATED = 'CLUB_NOT_CREATED';
export const CLUB_UPDATED = 'CLUB_UPDATED';
export const CLUB_UPDATED_ERROR = 'CLUB_UPDATED_ERROR';
export const CLUB_REMOVED = 'CLUB_REMOVED';
export const CLUB_REMOVED_ERROR = 'CLUB_REMOVED_ERROR';
export const GET_CLUB = 'GET_CLUB';
export const GET_CLUB_SESSION_DETAILS = 'GET_CLUB_SESSION_DETAILS';
export const GET_CLUB_ERROR = 'GET_CLUB_ERROR';

export const INIT_SESSIONS = 'INIT_SESSIONS';
export const INIT_SESSIONS_ERROR = 'INIT_SESSIONS_ERROR';
export const SESSION_CREATED = 'SESSION_CREATED';
export const SESSION_NOT_CREATED = 'SESSION_NOT_CREATED';
export const SESSION_UPDATED = 'SESSION_UPDATED';
export const SESSION_UPDATED_ERROR = 'SESSION_UPDATED_ERROR';
export const SESSION_REMOVED = 'SESSION_REMOVED';
export const SESSION_REMOVED_ERROR = 'SESSION_REMOVED_ERROR';
export const GET_SESSION = 'GET_SESSION';
export const GET_SESSION_ERROR = 'GET_SESSION_ERROR';
export const GET_SESSION_AUDIOS = 'GET_SESSION_AUDIOS';
export const GET_SESSION_AUDIOS_ERROR = 'GET_SESSION_AUDIOS_ERROR';

export const INIT_PATIENTS = 'INIT_PATIENTS';
export const PATIENT_CREATED = 'PATIENT_CREATED';
export const PATIENT_NOT_CREATED = 'PATIENT_NOT_CREATED';
export const PATIENT_REMOVED = 'PATIENT_REMOVED';
export const PATIENT_REMOVED_ERROR = 'PATIENT_REMOVED_ERROR';
export const PATIENT_UPDATED = 'PATIENT_UPDATED';
export const PATIENT_UPDATED_ERROR = 'PATIENT_UPDATED_ERROR';
export const GET_PATIENT = 'GET_PATIENT';
export const GET_PATIENT_ERROR = 'GET_PATIENT_ERROR';
export const GET_FIRMS = 'GET_FIRMS';
export const RESET_PATIENT = 'RESET_PATIENT';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_ERROR = 'GET_USER_DETAILS_ERROR';
export const GET_USER_DETAILS_WITH_FIRM = 'GET_USER_DETAILS_WITH_FIRM';
export const GET_USER_DETAILS_WITH_FIRM_ERROR = 'GET_USER_DETAILS_WITH_FIRM_ERROR';
export const GET_USER_ACTIVITIES = 'GET_USER_ACTIVITIES';
export const GET_USER_ACTIVITIES_ERROR = 'GET_USER_ACTIVITIES_ERROR';
export const GET_TEAM = 'GET_TEAM';
export const GET_TEAM_ERROR = 'GET_TEAM_ERROR';
export const GET_MEMBER = 'GET_MEMBER';
export const SAVE_AND_INVITE = 'SAVE_AND_INVITE';
export const SAVE_AND_INVITE_ERROR = 'SAVE_AND_INVITE_ERROR';

export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATED_ERROR = 'USER_UPDATED_ERROR';
export const USER_REMOVED = 'USER_REMOVED';
export const USER_REMOVED_ERROR = 'USER_REMOVED_ERROR';

export const ESTIMATION_GENERATED = 'ESTIMATION_GENERATED';
export const ESTIMATION_GENERATED_ERROR = 'ESTIMATION_GENERATED_ERROR';

export const BILLING_GENERATED = 'BILLING_GENERATED';
export const BILLING_GENERATED_ERROR = 'BILLING_GENERATED_ERROR';

export const PAYMENT_NEEDED = 'PAYMENT_NEEDED';