<template>
<div v-if="!isMobile">
		<div class="angles-div">
			<font-awesome-icon icon="angles-left" class='ap-angle' v-if="hideMenu===false" @click="hideMenu = !hideMenu"/>
			<font-awesome-icon icon="angles-right" class='ap-angle' v-else @click="hideMenu = !hideMenu"/>
		</div>
		<div class="menu" v-if="hideMenu===false">
			<h4>
				<router-link class="link" to="/patients/user"><font-awesome-icon icon="user-pen" title="Informations personnelles" class="icon-menu-before"/>Mes informations</router-link>
			</h4>
		</div>
		<div class="menu" v-else>
			<h4>
				<router-link class="link" to="/patients/user"><font-awesome-icon icon="user-pen" alt="Informations personnelles"/></router-link>
			</h4>
		</div>
	</div>
	<div v-else>
		<div class="sidenav" :class="{'sidenav': true, 'activeSidenav': showBurger}">
			<a id="closeBtn" href="#" class="close" @click="showBurger = false">×</a>
			<ul>
				<router-link class="link" to="/patients/user" @click="showBurger=false">Mes informations</router-link>
			</ul>
		</div>
		<a href="#" id="openBtn" @click="showBurger = true">
			<span class="burger-icon">
				<span></span>
				<span></span>
				<span></span>
			</span>
		</a>
	</div>    
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'PatientSettingsMenu',
    computed: {
		...mapState(['isMobile']),
	},
    data() {
        return {
            hideMenu: false,
            showBurger: false,
        }
    }
}
</script>
