<template>
    <div class="modal">
		<form id="login-modal" class="modal-content apform">
            <div v-if="errorMsg" class="errorMessage">
                {{errorMsg}}
            </div>
            <div>
                <input type="text" tabindex="1" name="email" v-model="email" placeholder="Email" data-cy="email"/>
                <span v-if="errors.email" class="errorMessage">{{errors.email}}</span>
            </div>
            <div>
                <input type="password" tabindex="2" name="password" v-model="password" placeholder="Mot de passe" data-cy="password" ref="public-password"/>
                <span v-if="errors.email" class="errorMessage">{{errors.password}}</span>
            </div>
            <div class="modal-buttons">
                <button name="cancel" type="reset" @click="$emit('exit')" class="cancel">Annuler</button>
                <button class="submit" name="submit" type="submit" data-cy="submit" @click="login">
                    <div v-if="loading" class="loader init-bottom-margin"></div>
                    <span>OK</span>
                </button>
            </div>
            <div style="text-align: center">
                <p @click="goToResetPassword" class="forget-pass">Mot de passe oublié ?</p>
            </div>

        </form>
    </div>   
</template>

<script>
export default {
    name: 'LoginPublicModal',
    data() {
        return {
            email: '',
            password: '',
            loading: false,
            errors: {},
            errorMsg: undefined
        }
    },
    emits: ['exit'],
    methods: {
        login(e) {
            e.preventDefault();
            this.loading = true
            this.errorMsg = undefined
            if (this.checkForm()) {
                this.$store.dispatch({
                    type: "loginPatient",
                    user: {
                        email: this.email,
                        password: this.password
                    }
                }).then(() => {
                    this.loading = false
                    this.$router.push("/patient")
                }).catch(error => {
                    this.loading = false
                    this.errorMsg = "nom d'utilisateur ou mot de passe incorrect"
                    if (error.response.status === 404) {
                        this.errorMsg = "utilisateur inconnu"
                    }
                });
            } else {
                this.loading = false;
            }
        },
        checkForm() {
            this.errors = {}
            let isValid = true
            if (this.password === "" || this.password === undefined) {
                this.errors.password = "Mot de passe requis"
                isValid = false
            }
            if (this.email === ""  || this.email === undefined) {
                this.errors.email = "E-mail requis"
                isValid = false
            }
            return isValid
        }
    }
}
</script>
