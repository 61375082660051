<template>
  <div id="container-result-box">
        <div id="result-box-patient" class="result-box">
            <p style="font-size:xx-large">{{activities.nbPatients}}</p>
            <p>Patients</p>
        </div>
        <div id="result-box-club" class="result-box">
            <p style="font-size:xx-large">{{activities.nbClubs}}</p>
            <p>Clubs</p>
        </div>
        <div id="result-box-animal" class="result-box">
            <p style="font-size:xx-large">{{activities.nbAnimals}}</p>
            <p>Animaux</p>
        </div>
  </div>

  <div class="align-items-center admin-activities-selectors">
    <div id="filter-therapist-container" v-if="isOfferUltime && therapists.length > 1" > 
          <label for="therapise-select">Practicien </label>
          <select
              class="zoo-input select-patient-club-animal"
              name="therapist"
              id="therapist-select"
              v-model="selectedTherapist"
              tabindex="1"
              @change="getActivitiesByTherapist"
          >
              <option value='Tous'>Tous</option>
              <option
                  v-for="therapist in therapists"
                  :value="therapist"
                  :key="therapist"
              >
              {{ therapist.lastname }} {{ therapist.firstname !== null ? therapist.firstname : therapist.email }}
              </option>
          </select>
      </div>
      <div id="admin-date-selection">
          <p>Entre le <input name="startDate" type="date" v-model="startDate" @change="getUserActivities"> et le <input name="startDate" type="date" v-model="endDate" @change="getUserActivities"></p>
      </div>
  </div>

  <div id="global-billing-infos">
      <div>
          <div class="result-box global-billing-infos">
            <div class="global-billing-infos-exclamation">
              <font-awesome-icon icon="exclamation-circle" title="basé sur le nombre de sessions"/>
            </div>
            <div class="global-billing-infos-value">{{activities.estimatedRevenue == null ? 0 : activities.estimatedRevenue.toFixed(2)}}</div>
            <div v-if="activities.estimatedRevenueCharges != null">Dont charges: {{ activities.estimatedRevenue != null ? 0 : activities.estimatedRevenueCharges.toFixed(2) }}</div>
            <div>
              Chiffre d'affaires estimé
            </div>
            <div>(en euros)</div>
          </div>
      </div>
      <div>
          <div class="result-box global-billing-infos">
            <div class="global-billing-infos-exclamation">
               <font-awesome-icon icon="exclamation-circle" title="basé sur les factures éditées"/>
            </div>
            <div class="global-billing-infos-value">{{activities.billedRevenue == null ? 0 : activities.billedRevenue.toFixed(2)}}</div>
            <div>
              Chiffre d'affaires facturé
            </div>
            <div>(en euros)</div>
          </div>
      </div>
      <div>
          <div class="result-box global-billing-infos">
            <div class="global-billing-infos-exclamation">
               <font-awesome-icon icon="exclamation-circle" title="basé sur les factures entièrement payées"/>
            </div>
            <div class="global-billing-infos-value">{{activities.incomeReceived == null  ? 0 : activities.incomeReceived.toFixed(2)}}</div>
            <div v-if="activities.incomeReceivedCharges != null">Dont charges: {{ activities.incomeReceivedCharges.toFixed(2) }}</div>
            <div>
              Chiffre d'affaires perçu
            </div>
            <div>(en euros)</div>
          </div>
      </div>
      <div>
          <div class="result-box global-billing-infos">
            <div class="global-billing-infos-value">{{effectiveNbWorkHours}}</div>
            <div>Temps travaillé</div>
            <div>(en H)</div>
          </div>
      </div>
      <div @click="goToBillsDetails">
          <div class="result-box global-billing-infos global-billing-pointer">
            <div class="global-billing-infos-value">{{activities.nbBills}}</div>
            <div>Factures éditées</div>
          </div>
      </div>
      <div @click="goToEstimationsDetails">
        <div class="result-box global-billing-infos global-billing-pointer">
          <div class="global-billing-infos-value">{{activities.nbEstimations}}</div>
          <div>Devis édités</div>
        </div>
      </div>      
  </div>
  <div id="admin-graph-container">
    <div>
        <p>Temps de travail <span class="subtitle-form">(en nombre d'heures par numéro de semaine)</span></p>
        <LineChart :chartData="chartAPData" :options="chartOptions"  ref="lineChart"/>
    </div>
    <div>
        <p>Finances</p>
        <LineChart :chartData="chartAPFinanceData" :options="chartFinanceOptions" ref="financeChart"/>
    </div>
  </div>

</template>
<script>

import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import { getTherapists, getTherapistActivities } from './services/team'

Chart.register(...registerables);

export default ({
  // eslint-disable-next-line
  name: 'Administration',
  components: {
    LineChart
  },
  data() {
    return {
      showBillsTab: true,
      showEstimationsTab: false,
      activities: {},
      startDate: null,
      endDate: null,
      weeksLabels: [],
      nbHours: [],
      bills: {},
      estimations: {},
      effectiveNbWorkHours: 0,
      chartAPData: {},
      chartOptions: {
        plugins: {
          legend: {
            display: false
          }
        },
      },
      chartAPFinanceData: {},
      chartFinanceOptions: {
        plugins: {
          legend: {
            position: 'bottom'
          }
        },
      },
      therapists: [],
			selectedTherapist: 'Tous',
      isOfferUltime: false
    }
  },
  methods: {
    getUserActivities() {
      this.$store.dispatch({
        type:'getUserActivities',
        askedInfos: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      }).then((response) => {
          this.activities = response;
          this.updateChart();
      }).catch(()=> {
      });
    },
    getLabelsFromActivities() {
      var output = [];
      let infosByWeek = this.activities.infosByWeek;
      if (!infosByWeek)
        return;

      for (var i=0; i < infosByWeek.length ; ++i) {
        output.push(infosByWeek[i].weekNumber +'-'+ infosByWeek[i].year);
      }
      
      output.sort((a, b) => {
        let yearMonthA = a.split('-');
        let yearMonthB = b.split('-');
        if (yearMonthA[1] < yearMonthB[1]) {
            return -1;
        } else if (yearMonthA[1] === yearMonthB[1]) {
          return parseInt(yearMonthA[0]) < parseInt(yearMonthB[0]) ? -1:1;
        } else {
          return 1;
        }
      });

      return output;
    },
    getDataFromActivities() {
      var output = [];
      let infosByWeek = this.activities.infosByWeek;

      if (!infosByWeek)
        return;

      this.effectiveNbWorkHours = 0;
      for (var i=0; i < infosByWeek.length ; ++i) {
        output.push(infosByWeek[i].nbHours);
        this.effectiveNbWorkHours += infosByWeek[i].nbHours;
      }
      return output;
    },
    getFinanceDataFromActivities() {
      var taxes = [];
      var earnings = [];
      var expectedEarnings = [];
      let infosByWeek = this.activities.infosByWeek;
      for (var i=0; i < infosByWeek.length ; ++i) {
        taxes.push(infosByWeek[i].taxes)
        earnings.push(infosByWeek[i].earnings)
        if (infosByWeek[i].expectedEarnings !== 0)
          expectedEarnings.push(infosByWeek[i].expectedEarnings)
      }
      return { taxes, earnings, expectedEarnings }
    },
    updateChart() {
      this.chartAPData = {
        labels: this.getLabelsFromActivities(),
        datasets: [
          {
            label: 'Nb heure par numéro de semaine',
            data: this.getDataFromActivities(),
            fill: true,
            backgroundColor: ["#61876C"],
            borderColor: "#61876C",
          }
        ]
      }
      this.$refs.lineChart.update();
      let financeData = this.getFinanceDataFromActivities();
      this.chartAPFinanceData = {
        labels: this.getLabelsFromActivities(),
        datasets: [
          {
            label: 'Charges',
            data: financeData.taxes,
            fill: true,
            backgroundColor: ["#EDE3B0"],
            borderColor: "#EDE3B0",
          },
          {
            label: 'Gains',
            data: financeData.earnings,
            fill: true,
            backgroundColor: ["#01876C"],
            borderColor: "#01876C",
          },
          {
            label: 'Gains attendus',
            data: financeData.expectedEarnings,
            fill: false,
            backgroundColor: ["#FF0000"],
            borderColor: "#FF0000",
          }
        ]
      }


      this.$refs.financeChart.update();
    },
    goToBillsDetails() {
      this.$router.push('/pros/billings/details')
    },
    goToEstimationsDetails() {
      this.$router.push('/pros/estimations/details')
    },
    getActivitiesByTherapist() {
      if (this.selectedTherapist !== 'Tous') {
        getTherapistActivities(this.startDate, this.endDate, this.selectedTherapist.id).
          then((response) => {
            this.activities = response[0].activities;
            this.updateChart()
          })
      } else {
        this.getUserActivities();
      }
    }
  },
  computed() {
    this.updateChart();
  },
  mounted() {
    let date = new Date(new Date().toLocaleString("en-US", {timeZone: "UTC"}));
    date.setMonth(date.getMonth()+1);
    date.setDate(0);
    this.endDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3');
    date.setMonth(date.getMonth()-2);
		date.setDate(1);
		this.startDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3');
    this.getUserActivities();
    this.offerName = this.$store.getters.user.tenant.offer
    this.isOfferUltime = this.offerName === 'ULTIME'
		if (this.isOfferUltime)
            getTherapists().then(team => {
				this.therapists = team.members
			})
  }
});
</script>
<style scoped>
#container {
    margin-top:2rem;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

#ca {
    margin-top: 2rem;
}

.result-box {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.65rem;
  border-radius: 3px;
  min-height: 7rem;
  min-width: 10rem;
  margin-right: 1rem;
  color: var(--primary-color);
}

#result-box-patient {
  background-color: var(--light-color);
} 

#result-box-club {
  background-color: #0968c7;
}

#result-box-animal {
  background-color: #182a36;
}

.global-billing-infos {
  color: var(--light-color);
  background-color: var(--cream-color);
}

.global-billing-pointer {
  cursor: pointer;
}

#global-billing-infos {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}

#global-billing-infos > div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.global-billing-infos-exclamation {
    text-align: right;
}

.global-billing-infos-value {
    font-size:xx-large;
}

.revenue-infos {
    position: absolute;
    border: 1px solid;
    background: #FFFFFF;
    padding: 0.1rem;
    z-index: 1;
    color: var(--light-color);
    margin-right: 1rem;
}

.admin-activities-selectors {
  margin-top: 3rem;
}

#admin-date-selection {
    margin-left: 2rem;
}

#container-result-box {
  margin-top: 2rem;
  width:100%;
  display: flex;
  flex-direction: row;
}

#admin-graph-container {
  display: flex;
  margin: 2rem;
  justify-content: space-around;
}

#admin-graph-container div {
  flex: 2 1 100%;
}

@media (max-width: 800px) {
  #container-result-box {
    display: block;
  }

  #global-billing-infos {
    display: block;
  }

  #global-billing-infos > div {
    margin-bottom: 1rem;
  }
}
</style>