<template>
  <div class="modal">
		<div class="modal-content">
			<p>Pour une inscription dans ce mode, veuillez nous contacter par mail à l'adresse suivante contact@animal-planner.com.</p>
      <p>A très vite</p>
      <div>
        <button name="submit" @click="$emit('exit', true)">OK</button>
      </div>
		</div>
	</div>
</template>
<script>
export default {
	// eslint-disable-next-line
	name: 'UltimeSignUpModal',
	data() {
		return {
		}
	}
}    
</script>

<style>
</style>
