<template>
	<UltimeSignUpModal v-if="showUltimeModal" @exit="hideInfoModal"/>
	<div v-if="!showUltimeModal && (showSignupModal || showLoginModal)" class="modal">
		<form id="login-modal" class="modal-content apform">
				<h3 v-if="showLoginModal">Connexion</h3>
				<h3 v-if="showSignupModal">S'inscrire</h3>
				<p v-if="errorMsg" class="errorMessage">
                    {{ errorMsg }}
                </p>
				<div>
					<input type="text" tabindex="1" name="email" v-model="email" placeholder="Email" data-cy="email"/>
                    <span v-if="errors.email" class="errorMessage">{{errors.email}}</span>
				</div>
				<div>
					<input type="password" tabindex="2" name="password" v-model="password" placeholder="Mot de passe" data-cy="password"/>
                    <span v-if="errors.password" class="errorMessage">{{errors.password}}</span>
				</div>
				<div v-if="showSignupModal">
					<input type="password" tabindex="3" v-model="confirmPassword" placeholder="Confirmation mot de passe" />
                    <span v-if="errors.confirmation" class="errorMessage">{{errors.confirmation}}</span>
				</div>
				<div v-if="showSignupModal" class="select-offer">
					<div>
						<label for="offer-select" id="label-offer-select">Type d'offre</label>
						<select name="offer-select" class="offer-select" v-model="selectedOffer" tabindex="4" @change="checkUltime" required>
							<option v-for="offer in offers" :key="offer" :value="offer" :selected="selectedOffer === offer">
								{{ offer }}
							</option>
						</select>
					</div>
				</div>
				<div v-if="showSignupModal" class="flex-container">
					<input id="accept-cgu" type="checkbox" name="acceptCgu" v-model="acceptCgu" data-cy="accept-cgu-input"/>
					<label for="acceptCgu" @click="acceptCgu = !acceptCgu">En cliquant vous acceptez <a href="/cgu">les conditions générales d'utilisation</a></label>
				</div>
				<div class="modal-buttons">
					<button name="cancel" type="reset" @click="$emit('exit')" class="cancel">Annuler</button>
					<button v-if="showSignupModal" name="submit" type="submit" @click="signup" :class="{'button-inactive': !acceptCgu || selectedOffer === offers.ULTIME}" :disabled="!acceptCgu || selectedOffer === offers.ULTIME" class="submit">
						<div v-if="loading" class="loader"></div>
						<span>OK</span>
					</button>
					<button v-if="showLoginModal && !showSignupModal" class="submit" name="submit" type="submit" data-cy="submit" @click="login">
						<div v-if="loading" class="loader init-bottom-margin"></div>
						<span>OK</span>
					</button>
				</div>
				<div v-if="showLoginModal && !showSignupModal" style="text-align: center">
					<p @click="goToResetPassword" class="forget-pass">Mot de passe oublié ?</p>
				</div>
		</form>
	</div>
</template>
<script>
import { goToFirstAccessibleRoute } from '../../services/route-utils'
import { ROLES } from '../../services/roles'
import { OFFERS } from '../../services/offers'
import UltimeSignUpModal from './UltimeSignUpModal.vue';

export default {
    // eslint-disable-next-line
    name: "LoginAndSignUp",
    data() {
        return {
            email: "",
            password: "",
            confirmPassword: "",
            loading: false,
            acceptCgu: false,
            selectedOffer: "",
			showUltimeModal: false,
			showLoginModal: false,
			showSignupModal: false,
            offers: [],
            errors: {},
			errorMsg:'',
        };
    },
    props: ["defaultShowSignupModal", "defaultShowLoginModal", "defaultOffer", "showUltimeSignUpModal"],
	emits: ['exit'],
    created() {
        this.offers = OFFERS
        this.selectedOffer = this.defaultOffer === undefined ? this.offers.PRO : this.defaultOffer
		this.showUltimeModal = this.showUltimeSignUpModal
		this.showLoginModal = this.defaultShowLoginModal
		this.showSignupModal = this.defaultShowSignupModal
    },
    methods: {
        hideInfoModal() {
			this.showUltimeModal=false
			this.showLoginModal= false
			if(this.showSignupModal === false) {
				this.$emit('exit')
			}
        },
        goToResetPassword() {
            this.$router.push("/reset-password")
            this.hideInfoModal()
        },
		checkUltime(event) {
			if (event.target.value === OFFERS.ULTIME) {
				this.showUltimeModal = true;
			}
		},
        checkForm() {
            this.errors = {};
            let isValid = true;
            if (this.password === "" || this.password === undefined) {
                this.errors.password = "Mot de passe requis"
                isValid = false
            }
            if (this.email === ""  || this.email === undefined) {
                this.errors.email = "E-mail requis"
                isValid = false
            }
            if (this.showSignupModal === true && this.password !== this.confirmPassword) {
                this.errors.confirmation = "Mot de passe et confirmation différents"
                isValid = false
            }
            return isValid
        },
        signup(e) {
            e.preventDefault()
            this.errorMsg = undefined
            if (this.checkForm()) {
                this.loading = true;
                this.$store.dispatch({
                    type: "register",
                    user: {
                        email: this.email,
                        password: this.password,
                        offer: this.selectedOffer
                    }
                }).then(() => {
                    this.login(e);
                    this.loading = false
                }, (error) => {
                    this.loading = false
                    if (error.response.status === 400) {
                        if (error.response.data.errorCode === "E102")
                            this.errorMsg = "Adresse mail déjà enregistrée"
                    }
                    else
                        this.errorMsg = "Problème d'inscription"
                });
            }
        },
        login(e) {
            e.preventDefault()
            this.loading = true
            this.errorMsg = undefined
            if (this.checkForm()) {
                this.$store.dispatch({
                    type: "login",
                    user: {
                        email: this.email,
                        password: this.password
                    }
                }).then(() => {
                    this.$store.dispatch("initPatients").then(() => {})
                    this.$store.dispatch("initAnimals").then(() => {})
                    this.$store.dispatch("initClubs").then(() => {
                        goToFirstAccessibleRoute(this.$router);
                    })
                    if (this.$store.getters.roles === ROLES.THERAPIST.value)
                        this.$store.dispatch("getUserDetailsWithFirmWithoutLogo").then(() => {
                            goToFirstAccessibleRoute(this.$router)
                        })
                }).catch(error => {
                    this.errorMsg = "nom d'utilisateur ou mot de passe incorrect"
                    if (error.response.status === 404) {
                        this.errorMsg = "utilisateur inconnu"
                    }
                }).finally (() => {
                    this.loading = false
                });
            } else 
                this.loading = false;
        }
    },
    components: { UltimeSignUpModal }
};
</script>

<style>
.forget-pass {
	cursor: pointer;
}

.select-offer {
    display: block;
}
.select-offer input {
    display: block;
}

.select-offer div {
    margin-top: 1rem;
    display: flex;
}

#label-offer-select {
    display: inline;
    margin-right: 0.5rem;
}

#accept-cgu {
    margin-right: 0.3rem;
}

#login-modal input {
    border: none;
    border-bottom: 1px solid var(--light-gray);
}


@media (max-width: 800px) {
    #login-modal {
        border-radius: 0px;
        padding: 1rem;
        margin: 0 auto;
        max-height: 20rem;
        margin-top: 15%;
    }
}

</style>
