<template>
    <div class="fillUserInfoMsg">
        <span>
            <font-awesome-icon icon="exclamation-circle" />
            <p style="margin-left:1rem">Le remplissage de vos informations de société est indispensable pour continuer.</p>
            
        </span>
        <p v-if = "!isOfferUltime || (isOfferUltime && !isAdmin)" style="margin-left:1rem">Seul le/la thérapeute principal(e) peut modifier ces informations.</p>
        <p v-else>Rendez vous sur la page <router-link to="/pros/user">"Mes informations"</router-link></p>
    </div>
    
</template>
<script>
import { PRIVILEGES } from '../../services/privileges';

export default {
	name: 'FillUserInfos',
    data() {
        return {
            isOfferUltime: false,
            isAdmin: false
        }
    },
    created() {
		this.isOfferUltime = this.$store.getters.user.tenant.offer === 'ULTIME'
        this.isAdmin = this.$store.getters.privileges.includes(PRIVILEGES.ADMINISTRATION);

    }
};
</script>
