<template>    
  <div id="patient-session-details">
    <div id="patient-session-details-header">
      <font-awesome-icon icon="fa-regular fa-calendar" class="patient-session-details-icons"/>{{ daysInLetter }} {{ formattedDate }} <font-awesome-icon icon="fa-regular fa-clock" class="patient-session-details-icons"/>{{ session.startTime }}
    </div>
    <div id="patient-session-details-content">{{ session.therapist.firstname }} {{ session.therapist.lastname }}</div>
  </div>

</template>

<script>
export default {
    name: 'PatientSessionDetails',
    props: ["session"],
    data() {
      return {
        days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        daysInLetter: '',
        formattedDate: ''
      }
    },
    mounted() {
      let sessionDate = new Date(this.session.date);
      this.daysInLetter = this.days[sessionDate.getDay()]
      this.formattedDate = sessionDate.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$3-$2-$1')
    },
    
}
</script>

<style scoped>
#patient-session-details-header {
  background-color: var(--light-color);
  color: #FFFFFF;
  font-weight: bold;
  padding: 0.5rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#patient-session-details-content {
  border: 1px solid var(--light-color);
  padding: 0.5rem;
}

.patient-session-details-icons {
  margin-left: 0.3rem;
  margin-right: 0.2rem;
}
</style>