<template>
    <PatientHeader/>
    <router-view/>
</template>

<script>
import PatientHeader from './PatientHeader.vue'

export default {
    name: 'PatientHomepage',
    components: {
        PatientHeader,
    }
}
</script>
