import axios from 'axios';
import authHeaders from '../services/auth-header';
import * as types from './mutation-types';

const SESSIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/sessions`;
const SHORT_SESSIONS_URL = process.env.VUE_APP_BASE_AP_URL+'/short-sessions';

export const initSessions = async ({ commit }) => {
	
	return axios.get(SHORT_SESSIONS_URL, { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
		let sessions = [];
		sessions.push(...response.data);
		commit(types.INIT_SESSIONS, sessions);
		return Promise.resolve(sessions);
      }
	})
	.catch(error => {
		return Promise.reject(error.response);
	});

};

export const addSession = async ({ commit }, event) => {	
	return axios.post(SESSIONS_URL, event.session, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
            commit(types.SESSION_CREATED, response.data);
            return Promise.resolve(response.data);
        }
	})
	.catch(error => {
		commit(types.SESSION_NOT_CREATED);
        return Promise.reject(error);
	});

};

export const getSession = async ({ commit }, event) => {
	return axios.get(`${SESSIONS_URL}/${event.id}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          commit(types.GET_SESSION, response.data);
          return Promise.resolve(response.data);
      }
	})
	.catch(error => {
      commit(types.GET_SESSION_ERROR, event.id);
      return Promise.reject(error);
	});
};

export const editSession = async ({ commit }, event) => {
	return axios.put(`${SESSIONS_URL}/${event.id}`, event.session, {params: {type: event.modificationType}, headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
			//commit(types.SESSION_UPDATED, response);
			return Promise.resolve(response.data);
		}
	})
	.catch((error) => {
		commit(types.SESSION_UPDATED_ERROR, error);
		return Promise.reject(error);
	});
	
};

export const deleteSessions = async ({ commit }, event) => {
	//TODO revoir ce ../ a cause du devProxyServer
	return axios.delete(`${SESSIONS_URL}/${event.sessionInfos.id}?type=${event.sessionInfos.deletionType}`, { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
		commit(types.SESSION_REMOVED, event.sessionInfos.id);
		return Promise.resolve({});
      }
	})
	.catch(error => {
      commit(types.SESSION_REMOVED_ERROR, error);
	});

};
