<template>
    <div v-if="showValidateAccount" class="modal">
        <div class="modal-content">
            Cette adresse mail est déjà connue.
            Souhaitez vous être rattaché à ce compte ?
            Sinon, veuillez choisir une adresse mail différente.
            <div class="modal-buttons">
                <button tabindex="1" @click="showValidateAccount = false">Annuler</button>
                <button tabindex="2" @click="createAfterValidation">S'identifier</button>
            </div>
        </div>
    </div>

    <div v-if="showExistingAccount" class="modal">
        <div class="modal-content">
            Un compte est rattaché à cette adresse mail.<br/>
            Veuillez vous rendez dans l'onglet "J'ai déjà un compte"
            <div class="modal-buttons">
                <button tabindex="1" @click="backToChoseAccount">OK</button>
            </div>
        </div>
    </div>

    <div id="public-session-add-form">
        <div>
            <h3>Rendez-vous</h3>
            <div class="date-time-input">
                le <input type="date" class="event-date" tabindex="1" name="startDate" v-model="session.startDate" placeholder="Jour de la séance" @change="updateRecurrences" required autofocus/> de <input type="time" class="event-date" tabindex="2" name="startTime" v-model="session.startTime" @change="updateEndTime" placeholder="Heure de début" required autofocus/>
 à <input type="time" class="event-date" tabindex="3" name="endTime" v-model="session.endTime" placeholder="Heure de fin" required/> avec {{therapist.firstname}} {{therapist.lastname}}
                
            </div>
            <div id="session-type-container">
                <p>Type de séance</p>
                <select
					name="therapist"
					v-model="session.sessionTypeId"
					tabindex="2"
                    @change="updateQuestionsComponent"
					>
						<option disabled :value="{}">Choisissez</option>
						<option
							v-for="sessionType in sessionTypes"
                            :key="sessionType"
                            :value="sessionType.id"
                            :selected="session.sessionTypeId === sessionType.id"
						>
						{{ sessionType.name }}
						</option>
				</select>
            </div>
        </div>
        <div id="step-login" v-if="showAskLogin">
            <div id="contact-information-header">
                <h3 @click="(userWithoutAccount=true)" :class="userWithoutAccount ? 'public-title-selected' : 'public-title-unselected'">Vos coordonnées</h3>
                <h3 @click="(userWithoutAccount=false)" :class="userWithoutAccount  === false ? 'public-title-selected' : 'public-title-unselected'">J'ai déjà un compte</h3>
            </div>
            <form class="contact-information-form">
                <div v-if="userWithoutAccount" class="contact-information-form-container">
                    <div class="flex-container contact-information-infos-container">
                        <div id="contact-information-form-names">
                            <label class="contact-information-form-label">Nom</label>
                            <input type="text" class="contact-information-form-input" v-model="patient.lastname"/>
                        </div>
                        <div>
                            <label class="contact-information-form-label">Prénom</label>
                            <input type="text" class="contact-information-form-input" v-model="patient.firstname"/>
                        </div>
                    </div>
                    <div class="contact-information-infos-container">
                        <label class="contact-information-form-label">Email</label>
                        <input type="text" class="contact-information-form-input" v-model="patient.email"/>
                    </div>
                    <div class="contact-information-infos-container">
                        <label class="contact-information-form-label">Téléphone</label>
                        <input type="text" class="contact-information-form-input" v-model="patient.phoneNumber"/>
                    </div>
                    <div class="contact-information-infos-container">
                        <label class="contact-information-form-label">Créer votre mot de passe</label>
                        <input type="password" class="contact-information-form-input" v-model="patient.password"/>
                        <small>Pour suivre vos rendez-vous</small>
                    </div>
                    <div class="modal-buttons">
                        <button name="submit" type="submit" tabindex="16" @click="askQuestions">
                            <div v-if="loading" class="loader init-bottom-margin"></div>
                            <span>Valider</span>
                        </button>
                    </div>
                </div>
                <div v-else class="contact-information-form-container">
                    <div v-if="errorMsg !== ''" class="errorMessages">
                        <p>{{ errorMsg }}</p>
                    </div>

                    <div class="contact-information-infos-container">
                        <label class="contact-information-form-label">Email</label>
                        <input type="text" class="contact-information-form-input" name="email" v-model="email"/>
                    </div>
                    <div class="contact-information-infos-container">
                        <label class="contact-information-form-label">Mot de passe</label>
                        <input type="password" class="contact-information-form-input" name="password" v-model="password"/>
                    </div>
                    <div class="modal-buttons">
                        <button name="submit" type="submit" tabindex="16" @click="signInAndAddPublicSession">
                            <div v-if="loading" class="loader init-bottom-margin"></div>
                            <span>Valider</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div id="step-questions" v-if="showAskQuestions">
            <questions-step :therapist="therapist" :questions="questions" @exit="addPublicSession" :key="questionsComponentKey"/>
        </div>
    </div>
</template>
<script>

import { createSession, createPatientFromPublic, createPatientFromPublicAfterValidation } from '../../services/publicSessions'
import { getTherapistSessionTypes } from '../../services/therapists'
import { getQuestions } from './services/questions'
import QuestionsStep from './QuestionsStep.vue'

export default {
 
    name: 'PublicSession',
    components: {
        QuestionsStep
    },
    data() {
        return {
            email: '',
            password: '',
            userWithoutAccount: true,
            showValidateAccount: false,
            showExistingAccount: false,
            patient: {
                firstname: '',
                lastname: '',
                email: '',
                phoneNumber: '',
                password: ''
            },
            session: {
                startDate: '',
                startTime: '',
                endTime: '',
                therapistId: '',
                patientId: '',
                sessionTypeId: ''
            },
            sessionTypes: [],
            loading: false,
            errors: [],
            errorMsg: '',
            showAskQuestions: false,
            showAskLogin: true,
            questionsComponentKey: 0,
            questions: [],
            answers: []

        }
    },
    props: ["therapist", "eventDate"],
    emits: ['exit'],
    mounted() {
        let currentDate = new Date(this.eventDate.dateStr)
        this.session.startDate = currentDate.toISOString().substring(0,10)
        this.session.startTime = currentDate.toTimeString().substring(0,5)
        this.session.therapistId = this.therapist.id
        this.updateEndTime()
        this.getSessionTypeByTherapist()
    },
    methods : {
        backToChoseAccount() {
            this.showExistingAccount = false
            this.showAskLogin = true
            this.showAskQuestions = false
        },
        backToCalendar() {
            this.$emit('exit', true)
        },
        askQuestions(e) { //don't need to add questions if the user is already known
            e.preventDefault();
            getQuestions(this.therapist.id, this.session.sessionTypeId).then((response) => {
                this.questions = response;
                if (this.questions.length == 0)
                    this.addPublicSession([])
                else {
                    this.showAskQuestions = true
                    this.showAskLogin = false
                }
            })
        },
        addPublicSession(answers) {
            if (!this.checkForm()) {
                this.loading = true
            
                createPatientFromPublic(this.patient).then((response) => {
                    if (response.patientId !== undefined) {
                        this.loading = false
                        this.session.patientId = response.patientId
                        this.$store.dispatch({
                            type: "loginPatient",
                            user: this.patient
                        }).then(() => {
                            this.session.answers = answers;
                            createSession(this.session).then((response)=> {
                                if (response.id !== undefined) {
                                    this.loading = false
                                    this.$router.push({name:'PublicSessionWaitingValidation'})
                                }
                            }).catch((response)=>{
                                this.loading = false
                                if (response.response.data.errorCode === 'E113') {
                                    this.$toast.error('La date de début doit être supérieure à la date courante.')
                                } else       
                                    this.$toast.error("Erreur lors de l'enregistrement veuillez ré-ssayer")
                            })
                        })
                    }
                }).catch((error) => {
                    if (error.response.data.errorCode === 'E128') 
                        this.showValidateAccount = true
                    if (error.response.data.errorCode === 'E102')
                        this.showExistingAccount = true    
                }).finally(()=>this.loading=false);                
            } else 
                this.$toast.error('Renseignez les champs obligatoires')
        },
        signInAndAddPublicSession(e) {
            e.preventDefault()
            this.errorMsg = ''
            this.loading = true
            this.$store.dispatch({
                    type: "loginPatient",
                    user: {
                        email: this.email,
                        password: this.password
                    }
                }).then(() => {
                    createSession(this.session).then((response)=> {
                        if (response.id !== undefined)
                            this.$router.push({name:'PublicSessionWaitingValidation'})
                    }).catch((response)=> {
                        if (response.response.data.errorCode === 'E113') {
                            this.$toast.error('La date de début doit être supérieure à la date courante.')
                        } else       
                            this.$toast.error("Erreur lors de l'enregistrement veuillez ré-ssayer")
                    })
                }).catch(error => {
                    if (error.response.status === 404) {
                        this.errorMsg = "utilisateur inconnu"
                    } else if (error.response.status === 401) {
                        this.errorMsg = "nom d'utilisateur ou mot de passe incorrect"
                    }
                }).finally(() => this.loading = false)
        },
        createAfterValidation() {
            this.showValidateAccount = true
            createPatientFromPublicAfterValidation(this.patient).then(() => {
                this.$store.dispatch({
                    type: "loginPatient",
                    user: this.patient
                }).then(() => {
                    createSession(this.session)
                        .then(()=> {
                            this.showValidateAccount = false
                            this.$router.push({name:'PublicSessionWaitingValidation'})
                        }
                        )
                        .catch((response) => {
                            if (response.response.data.errorCode === 'E113') {
                                this.$toast.error('La date de début doit être supérieure à la date courante.')
                            } else       
                                this.$toast.error("Erreur lors de l'enregistrement veuillez ré-ssayer")
                        })
                })
            }).catch(() => {
                this.$toast.error("Erreur lors de la création de l'utilisateur")
            }).finally(() => this.showValidateAccount = false)
        },
        checkForm() {
            this.errors = [];

            if (this.therapist.id === null)
                this.errors.push('Aucun thérapeute sélectionné')
            if (this.session.startDate === null)
                this.errors.push('Date rendez vous')
            if (this.session.startTime === null)
                this.erros.push('Heure de début')
            if (this.session.endTime === null)
                this.errors.push('Heure de fin')

            return this.errors.length > 0
        },
        updateEndTime() {
			let tmpEndTime = new Date(this.session.startDate + " " + this.session.startTime);
			tmpEndTime.setHours(tmpEndTime.getHours()+1)
			this.session.endTime = tmpEndTime.toTimeString().substring(0,5);
		},
        getSessionTypeByTherapist() {
            getTherapistSessionTypes(this.session.therapistId).then(response => {
                this.sessionTypes = response 
                if (this.sessionTypes.length > 0) {
                    this.session.sessionTypeId = this.sessionTypes[0].id
                }
            })
        },
        updateQuestionsComponent() {
            if (this.showAskQuestions) {
                getQuestions(this.therapist.id, this.session.sessionTypeId).then((response) => {
                    this.questions = response;
                    this.questionsComponentKey++;
                })
            }
        }
    }
}
</script>
<style scoped>
#public-session-add-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#contact-information-header {
    display: flex;
    padding: 4rem;
    font-size: medium;
    font-weight: 600;
    justify-content: center;
}

#contact-information-header h3 {
    margin-right:2rem;
}

.contact-information-form {
    display: flex;
    justify-content: center;
    text-align: left;
    min-width: 20%;
}
.contact-information-form-container {
    min-width: 20%;
}

#contact-information-form-names {
    margin-right: 2rem;
}
.contact-information-form-label {
    display: block;
}
.contact-information-form-input {
    width: 100%;
    height: 2.3rem;
    font-size: var(--font-regular);
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 0.2rem;
    margin-right: 2rem;
}

.public-title-selected {
    border-bottom : 0.1rem solid black;
}

.public-title-unselected {
    color: var(--primary-color-light);
}

.contact-information-infos-container {
    margin-bottom: 2rem;
}

#session-type-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

#session-type-container select {
    margin-left: 1rem;
}
</style>
