<template>
	<session-types-form/>
</template>

<script>
import SessionTypesForm from './SessionTypesForm.vue'

export default {
    name: 'APSessionsTypes',
	components: {
		SessionTypesForm
	},
    data() {
        return {
        }
    },
	mounted() {
	},
	methods: {
		backToCalendar() {
			this.$router.push({name: 'Sessions', state: {initialDate: this.startDate}});
		},
	}
}
</script>

<style scoped>
</style>
