<template>
	<div class="modal-overlay" style="display: block;" v-if="showClubModal" @click="hideModal"></div>  
	<button @click="showClubModal = true" data-cy="add-club-button">Ajouter</button>

	<div v-if="showDeleteClubModal" class="modal">
		<div class="modal-content">
			<p>Etes vous sûr de vouloir supprimer club ?</p>
			<p>Les séances associées à ce club seront modifiées.</p>
			<div class="modal-buttons">
				<button name="cancel" @click="showDeleteClubModal = false" class="button cancel">Annuler</button>
				<button name="submit" @click="deleteSelectedClub" class="button button-danger">OK</button>
			</div>
		</div>
	</div>
	
	<table class="data-table">
		<thead class="data-table-header">
			<tr>
				<th @click="sort('name')" :class="defineCarretClass('name')">Nom</th>
				<th @click="sort('email')" :class="defineCarretClass('email')">Email</th>
				<th v-if="!isMobile">Téléphone</th>
				<th v-if="!isMobile">Adresse</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="(club, index) in sortedClubs"
				v-bind:key="index"
				@mouseover="hover = index"
				@mouseleave="hover = -1"
				@click="routeToDetails(club.id)"
			>
				<td>{{ club.name }}</td>
				<td>{{ club.email }}</td>
				<td v-if="!isMobile">{{ club.phoneNumber }}</td>
				<td v-if="!isMobile">{{ club.address.streetName && club.address.zipCode && club.address.city ? club.address.streetName + " - " + club.address.zipCode + " " + club.address.city : ""}}</td>
				<td class="data-table-edit" v-if="!isMobile">
					<div v-if="hover === index" >
						<font-awesome-icon icon="pen" @click.stop="displayEditClubModal(club)" class="data-table-edit-icon"/>
						<font-awesome-icon icon="trash-alt" @click.stop="displayDeleteModal(club.id)" class="data-table-edit-icon" />
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	
	<div class="empty-content-container" v-if="sortedClubs.length === 0">
		Aucun club répertorié pour l'instant
	</div>

	<div v-if="showClubModal || showEditClubModal" class="modal">
		<form id="club-modal" class="modal-content apform">
			<h3 v-if="showClubModal">Ajouter un Club</h3>
			<h3 v-if="showEditClubModal">Editer les informations du Club</h3>
			<div>
				<label for="name">Nom</label>
				<input name="name" type="text" tabindex="1" v-model="name" placeholder="Nom" autofocus/>
			</div>
			<div>
				<label for="email">E-mail</label>
				<input name="email" type="text" tabindex="2" v-model="email" placeholder="E-mail"/>
			</div>
			<div>
				<label for="street">Rue</label>
				<input name="street" type="text" tabindex="3" v-model="streetName" placeholder="Rue" />
			</div>
			<div>
				<label for="zipCode">Code postal</label>
				<input name="zipCode" type="text" tabindex="4" v-model="zipCode" placeholder="Code postal" />
			</div>
			<div>
				<label for="city">Ville</label>
				<input name="city" type="text" tabindex="4" v-model="city" placeholder="Ville" />
			</div>
			<div>
				<label for="phoneNumber">Téléphone</label>
				<input name="phoneNumber" type="text" tabindex="6" v-model="phoneNumber" placeholder="Téléphone" />
			</div>
			<div>
				<label for="amountDue">Montant dû (par séance)</label>
				<input name="amountDue" type="text" tabindex="7" v-model="amountPerSession" placeholder="Montant" />
			</div>
			<div class="modal-buttons">
				<button name="cancel" type="reset" class="cancel" @click="hideModal">Annuler</button>
				<button v-if="showClubModal" name="submit" type="submit" @click="addClub">OK</button>
				<button v-if="showEditClubModal" name="submit" type="submit" @click="editClub">OK</button>
			</div>
		</form>
	</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
	// eslint-disable-next-line
	name: 'Clubs',
	data() {
		return {
			id: '',
			name: '',
			email: '',
			streetName: '',
			city: '',
			zipCode: '',
			phoneNumber: '',
			amountPerSession: '',
			showClubModal: false,
			showEditClubModal: false,
			showDeleteClubModal: false,
			hover: -1,
			selectedClubId: -1,
			currentSort:'name',
			currentSortDir:'asc',
			isMobile: this.$store.state.isMobile,
		};
	},
	mounted() {
		this.sendInitClubs();
	},
	methods: {
		sort(s) {		
			if(s === this.currentSort) {
				this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
			}
			this.currentSort = s;
		},
		defineCarretClass(criteria) {
			if (criteria === this.currentSort) {
				if (this.currentSortDir === 'asc')
					return 'carret-up';

				return 'carret-down';
			}
			return '';
		},
		sendInitClubs() {
			this.$store.dispatch('initClubs')
			.then(() => {
                //everything's fine, nothing to do
            }).catch(response => {
                if (response.status === 401) {
                    this.$store.dispatch('logout');
					this.$router.push('/');
				}
            });
		},
		addClub(e) {
			e.preventDefault();
			this.$store.dispatch({
				type: 'addClub',
				club: {
					name: this.name,
					email: this.email,
					phoneNumber: this.phoneNumber,
					amountPerSession: this.amountPerSession,
					address : {
						streetName: this.streetName,
                        city: this.city,
                        zipCode: this.zipCode,
					}
				},
			}).then(()=>{
				this.hideModal();
			})
			.catch((error) => {
				if (error.response.data.errorCode === 'E119')
					this.$toast.error('Nom de club déjà existant');
				else if (error.response.data.errorCode === 'E120')
					this.$toast.error('Email club déjà existant');
				else
					this.$toast.error('Erreur lors de la création du club');
			});
			
		},
		displayEditClubModal(club) {
            this.showEditClubModal = true;
			this.id = club.id;
			this.name = club.name;
			this.email = club.email;
			this.phoneNumber = club.phoneNumber;
			this.streetName = club.address.streetName;
			this.city = club.address.city;
			this.zipCode = club.address.zipCode;
			this.amountPerSession = club.amountPerSession;
		},
		editClub(e) {
			e.preventDefault();
			this.$store.dispatch({
				type: 'updateClub',
				club: {
					id: this.id,
					name: this.name,
					email: this.email,
					phoneNumber: this.phoneNumber,
					amountPerSession: this.amountPerSession,
					address : {
						streetName: this.streetName,
                        city: this.city,
                        zipCode: this.zipCode,
					}
				},
			}).then(()=>{
				this.hideModal();
			})
			.catch((error) => {
				if (error.response.data.errorCode === 'E119')
					this.$toast.error('Nom de club déjà existant');
				else if (error.response.data.errorCode === 'E120')
					this.$toast.error('Email club déjà existant');
				else
					this.$toast.error('Erreur lors de la création du club');
			});
		},
		displayDeleteModal(clubId) {
			this.selectedClubId = clubId;
			this.showDeleteClubModal = true;
		},		
		deleteSelectedClub() {
			this.$store.dispatch({
				type: 'deleteClub',
				id: this.selectedClubId
			}).then(() => {
				this.showDeleteClubModal = false;
				this.$toast.success('Club correctement supprimé');
			}).catch(()=>{
				this.$toas.error('Erreur lors de la suppression du club');
			});
		},
		hideModal() {
			this.showClubModal = false;
			this.showEditClubModal = false;
			this.name = '';
			this.email = '';
			this.phoneNumber = '';
			this.streetName = '';
			this.city = '';
			this.zipCode = '';
		},
		routeToDetails(clubId) {
			this.$router.push({ name: 'ClubWithId', params: { id : clubId } });
		}
	},
	computed: {
		...mapState(['clubs']),
		sortedClubs() {
			let clubs = this.$store.state.clubs;
			return clubs.sort((a,b) => {
				let modifier = 1;
				if(this.currentSortDir === 'desc') modifier = -1;
				if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
				if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
				return 0;
			});
		}
	},
};
</script>

<style></style>
