import axios from 'axios';
import authHeaders from '../../../services/auth-header';

const SESSION_QUESTIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/sessions/%sessionId%/questions`;
const PATIENT_QUESTIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/patients/%patientId%/questions`;

export async function getQuestionsAndAnswersBySessionId(sessionId) {
	let filledQuestionsUrl = SESSION_QUESTIONS_URL.replace('%sessionId%', sessionId)
	return axios.get(filledQuestionsUrl, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

export async function getQuestionsAndAnswersByPatientId(patientId) {
	let filledQuestionsUrl = PATIENT_QUESTIONS_URL.replace('%patientId%', patientId)
	return axios.get(filledQuestionsUrl, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}