import axios from 'axios';
import authHeaders from '../../../services/auth-header';

//const DRAFT_BILL_URL = process.env.VUE_APP_BASE_AP_URL+`/draft/bills`;
const BILLINGS_URL = process.env.VUE_APP_BASE_AP_URL+`/billings`;

const NEXT_NUMBER_BILLINGS_URL = process.env.VUE_APP_BASE_AP_URL+`/next-number/billings`;

const DRAFT_BILL_URL = process.env.VUE_APP_BASE_AP_URL+`/draft/bills`;

export const getBillingSessionDetails = async (event) => {
	let calledURL = `${BILLINGS_URL}`;
	calledURL = calledURL + `?page=${event.askedInfos.page}&size=10`
	
    if (event.askedInfos.startDate !== null && event.askedInfos.endDate != null) {
		calledURL = calledURL + `&startDate=${event.askedInfos.startDate}&endDate=${event.askedInfos.endDate}`;
	}

	if (event.askedInfos.name !== null && event.askedInfos.name !== undefined)
		calledURL = calledURL + `&name=${event.askedInfos.name}`

	if (event.askedInfos.status !== null && event.askedInfos.status !== undefined)
		calledURL = calledURL + `&status=${event.askedInfos.status}`	

	if (event.askedInfos.therapistId !== null && event.askedInfos.therapistId !== undefined)
		calledURL = calledURL + `&therapistId=${event.askedInfos.therapistId}`
    return axios.get(`${calledURL}`,  { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
}
export const getDraftBill = async (event) => {
	return axios.post(DRAFT_BILL_URL, event.bill, { headers: { 'Authorization':authHeaders()}, responseType: 'blob'})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
}


export const createBilling = async (event) => {

	return axios.post(BILLINGS_URL, event.bill, {responseType: 'arraybuffer',  headers : {'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
			return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		const decoder = new TextDecoder();
        return Promise.reject(JSON.parse(decoder.decode(error.response.data)));
	});
};


export const getPaymentChoices = async () => {
	return axios.get(process.env.VUE_APP_BASE_AP_URL+'/payment-choices', { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
}

export const getPaymentLateInterests = async () => {
	return axios.get(process.env.VUE_APP_BASE_AP_URL+'/payment-late-interests', { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
}

export const getPaymentTerms = async () => {
	return axios.get(process.env.VUE_APP_BASE_AP_URL+'/payment-terms', { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
}

export const markBillAsPaid = async (billId) => {
	return axios.put(`${BILLINGS_URL}/${billId}?fullyPaid=true`, {}, { headers: { 'Authorization':authHeaders()}})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(function (error) {
			return Promise.reject(error);
		});
}

export const getNextBillNumber = async () => {
	return axios.get(`${NEXT_NUMBER_BILLINGS_URL}`, { headers: { 'Authorization':authHeaders()}})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(function (error) {
			return Promise.reject(error);
		});
}