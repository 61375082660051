<template>
  <div class="flex-container">
    <div>
      <h3>Nouveau commentaire : </h3>
    </div>
    <div id="spinner-container">
      <div :class="{'spinner-active': isRecording}"></div>
      <span>
        <font-awesome-icon icon="microphone" @mousedown="register" @mouseup="stopRegister"/>
      </span>
    </div>
  </div>
</template>

<script>
import { sendAudio } from '../../../services/audio.js';

export default {
  // eslint-disable-next-line
  name: 'AudioRecorder',
  data() {
    return {
      permissionStatus: false,
      recorder: null,
      isRecording: false
    };
  },
  props: ["sessionId"],
  mounted() {
    this.checkPermission();
  },
  methods: {
    async checkPermission() {
      const status = await navigator.permissions.query(
        { name: 'microphone' },
      );
      this.permissionStatus = status.state;
    },
    register() {
      try {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          this.recorder = new MediaRecorder(stream);
          this.isRecording = true;
          this.recorder.start();
          const audioChunks = [];
          
          this.recorder.ondataavailable = (e) => {
            audioChunks.push(e.data);
          };
          
          this.recorder.addEventListener("stop", () => {
            audioChunks[0].arrayBuffer().then((response) => {
              let soundAsString = String.fromCharCode.apply(null, Array.from(new Uint8Array(response)));
              sendAudio(this.sessionId, soundAsString).then(response => {
                this.$emit('receiveNewAudioComment', response);
              });
              this.isRecording = false;
            });
          });
          
        }).catch(() => { });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Audio error: ', err);
      }
    },
    stopRegister() {
      this.recorder.stop();
    },
    
  }
}
</script>

<style scoped>

#spinner-container {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
}

#spinner-container span {
  width: 100%;
  height: 100%;
  position: absolute;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  top: 0;
}

.spinner-active {
  position: absolute;
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
  animation: spinner-container 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}


@keyframes spinner-container {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
