import axios from 'axios';
import authHeaders from '../../../services/auth-header';

const PATIENTS_URL = process.env.VUE_APP_BASE_AP_URL+`/patients`;

export async function filterPatients(name, startDate, endDate, sessionTypes) {
	return axios.get(`${PATIENTS_URL}`, {
											headers : { 'Authorization':authHeaders()}, 
											params: {
												name,
												startDate,
												endDate,
												sessionTypes: sessionTypes.join(',')
											}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}
