<template>
    <form id="animal-modal" class="apform">
		<h3 v-if="!isEdit">Ajouter un animal</h3>
		<h3 v-else>Editer les informations de l'animal</h3>
		<div v-if="errors.length" class="errorMessages">
			<div>Champs requis</div>
			<ul>
				<li v-for="(error, errorIndex) in errors" v-bind:key="errorIndex">{{ error }}</li>
			</ul>
		</div>
		<div>
			<label for="name">Nom</label>
			<input name="name" type="text" tabindex="1"  v-model="name" placeholder="Nom" required autofocus />
		</div>
		<div>
			<label for="birtdat-select">Date de naissance</label>
			<select name="birthdate-select" class="birthdate-daymonth-select" v-model="birthdateDay" tabindex="2" required>
				<option v-for="val in days" :key="val.index" :value="val.value" :selected="birthdateDay === val.index">
					{{ val.value }}
				</option>
			</select>
			<select class="birthdate-daymonth-select" v-model="birthdateMonth" tabindex="3" required>
				<option v-for="val in months" :key="val.index" :value="val.value" :selected="birthdateMonth === val.index">
					{{ val.value }}
				</option>
			</select>
			<select class="birthdate-year-select" v-model="birthdateYear" tabindex="4" required>
				<option v-for="val in years" :key="val.index" :value="val.value" :selected="birthdateYear === val.value">
					{{ val.value }}
				</option>
			</select>
		</div>
		<div class="flex-container">
			<div>
				<label>Espèce</label>
				<multiselect v-model="selectedSpecies" :options="species" placeholder="Sélectionner" label="species" track-by="species"></multiselect>
			</div>
			<div v-if="selectedSpecies?.races?.length > 1">
				<label>Race</label>
				<multiselect v-model="selectedRace" :options="selectedSpecies.races" placeholder="Sélectionner" label="race" track-by="race"></multiselect>
			</div>
		</div>
		<div>
			<label>Propriétaire</label>
			<select 
				class="zoo-input select-patient-club-animal"
				name="patients"
				data-cy="patient-select"
				id="owner-select"
				v-model="ownerId"
				tabindex="5"
			>
				<option :selected="ownerId === '' || ownerId === null" :value=null>Aucun</option>
				<option
					v-for="patient in $store.getters.patients"
					:value="patient.id"
					:key="patient.id"
					:selected="patient.id === ownerId"
				>
					{{ patientsTypes[patient.type] === patientsTypes.INDIVIDUAL ? `${patient.individual.firstname} ${patient.individual.lastname}` :  patient.firm.companyName}}
				</option>
			</select>
		</div>
		<div>
			<label for="club-select">Club</label>
			<div>
				<select name="club-select" class="select-patient-club-animal" v-model="clubId" tabindex="6">
					<option :selected="clubId === '' || clubId === null" :value=null>Aucun</option>
					<option v-for="club in clubs" :key="club.id" :value="club.id" :selected="club.id === clubId">
						{{ club.name }}
					</option>
				</select>
			</div> 
		</div>
		<div>
			<label for="nbWorkingHoursByWeek">Nb heures hebdo</label>
			<input name="nbWorkingHoursByWeek" type="number" min="0" tabindex="6"  v-model="nbWorkingHoursByWeek" placeholder="Nombre d'heures par semaine" />
		</div>

		<div>
			<h4 @click="showActivities = !showActivities">
				Activités
				<font-awesome-icon v-if="!showActivities" icon="fa-solid fa-caret-right"/>
				<font-awesome-icon v-if="showActivities" icon="fa-solid fa-caret-down"/>
			</h4>
			<div v-if="showActivities">
				<p>Temps de travail <span class="subtitle-form">(en nombre d'heures par numéro de semaine)</span></p>
				<LineChart :chartData="chartAPData" :options="chartOptions"  ref="lineChart"/>
			</div>
		</div>

		<div>
			<h4 @click="showSessions = !showSessions">Séances 
				<font-awesome-icon v-if="!showSessions" icon="fa-solid fa-caret-right"/>
				<font-awesome-icon v-if="showSessions" icon="fa-solid fa-caret-down"/>
			</h4>
			<div v-if="showSessions">
				<table class="data-table">
					<thead class="data-table-header">
						<tr>
							<th @click="sort('date')" :class="defineCarretClass('date')">Date</th>
							<th>Commentaire</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(session, index) in sessions"
							v-bind:key="index"
							@mouseover="hover=index"
							@mouseleave="hover=-1"
							@click="redirectToSessionDetails(session.id)"
						>
							<td style="width: 10%">{{ session.date }}</td>
							<td class="session-comment">{{ session.comments}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div>
			<h4 @click="showHealth = !showHealth">Santé 
				<font-awesome-icon v-if="!showHealth" icon="fa-solid fa-caret-right"/>
				<font-awesome-icon v-if="showHealth" icon="fa-solid fa-caret-down"/>
			</h4>
			<div v-if="showHealth">
				<animal-health :id="id"></animal-health>
			</div>
		</div>
		<div class="modal-buttons">
			<button name="cancel" class="cancel" type="reset"  @click="goBack">Annuler</button>
			<button v-if="!isEdit" name="submit" type="submit"  @click="addAnimal">OK</button>
			<button v-else name="submit" type="submit"  @click="editAnimal">Enregistrer</button>
		</div>

		
	</form>
</template>

<script>
import { mapState } from 'vuex'
import Days from '../../../services/days-month-array'
import { getAnimalDetails, getAnimalSessions } from '../services/animals'
import { LineChart } from 'vue-chart-3'
import { Chart, registerables } from "chart.js"
import { PATIENTS_TYPES } from '../../../services/patientTypes'
import AnimalHealth from './AnimalHealth.vue'

import _ from 'lodash';

Chart.register(...registerables);

export default {
    name: 'APAnimal',
	components: {
		LineChart,
		AnimalHealth
	},
    data() {
        return {
            id: '',
            name: '',
            birthdateYear: '',
            birthdateMonth: '',
            birthdateDay: '',
            clubId: '',
			ownerId: '',
            nbWorkingHoursByWeek: 0,
			isEdit: false,
			errors: [],
			days: Days.days(),
			months: Days.months(),
			years: Days.years(),
			chartAPData: {},
			chartOptions: {
				plugins: {
					legend: {
						display: false
					}
				},
			},
			patientsTypes: PATIENTS_TYPES,
			species: [],
			selectedSpecies: {},
			selectedRace: '',
			sessions: [],
			showActivities: false,
			showSessions: false,
			showHealth: false,
			currentSort: 'date',
			currentSortDir: 'asc'
        }
    },
	computed: {
		...mapState(['clubs', 'patients']),
	},
	mounted() {
		if (this.$store.getters.animalTypes === null || this.$store.getters.animalTypes === []) {
			this.$store.dispatch({
				type: 'getSpecies'
			}).then(response=> {
				this.species = response
			})
		} else {
			this.species = this.$store.getters.animalTypes
		}
		
		if (this.$route.params.id) {
			this.isEdit = true
			this.callGetAnimalDetails(this.$route.params.id)
		}

		if (this.clubs.length === 0) {
			this.$store.dispatch({
				type: 'initClubs'
			})
		}

		if (this.patients.length === 0) {
			this.$store.dispatch({
				type: 'initPatients'
			})
		}
	},
    methods: {
		sort(s) {		
			if(s === this.currentSort) {
				this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
			}
			this.currentSort = s;

			this.sessions.sort((a,b) => {
				let modifier = 1;
				if(this.currentSortDir === 'desc') modifier = -1;
                let left = new Date(a.date).getTime();
                let right = new Date(b.date).getTime();

                if(left < right) return -1 * modifier;
				if(left > right) return 1 * modifier;

				return 0;
			});
		},
		defineCarretClass(criteria) {
			if (criteria === this.currentSort) {
				if (this.currentSortDir === 'asc')
					return 'carret-up';

				return 'carret-down';
			}
			return '';
		},
		redirectToSessionDetails(sessionId) {
            this.$router.push({ name: 'EditSession', params: { id : sessionId } });
        },
        checkForm(){
			if (this.name !== '' && this.nbWorkingHoursByWeek !== 0)
                return true

            this.errors = []
			
			if (this.name === '')
                this.errors.push(`Nom de l'animal`)
				
            if (this.nbWorkingHoursByWeek === 0)
                this.errors.push(`Nombre d'heures de travail hebdo`);
			return false
		},
		updateChart(infosByWeek) {
			let labels = this.getLabelsFromActivities(infosByWeek)
			this.chartAPData = {
				labels: labels,
				datasets: this.getDataFromActivities(labels, infosByWeek),
			}
			
			if (this.$refs.lineChart?.value !== undefined)
				this.$refs.lineChart.value.updateChart();
		},
		//TODO gros copier/coller a modifier
		getLabelsFromActivities(infosByWeek) {
			let output = [];
			for (var i=0; i < infosByWeek.length ; ++i)
				output.push(infosByWeek[i].weekNumber+'-'+ infosByWeek[i].year);

			output.sort((a, b) => {
				let yearMonthA = a.split('-');
				let yearMonthB = b.split('-');
				if (yearMonthA[1] < yearMonthB[1]) {
					return -1;
				} else if (yearMonthA[1] === yearMonthB[1]) {
				return parseInt(yearMonthA[0]) < parseInt(yearMonthB[0]) ? -1:1;
				} else {
				return 1;
				}
			});
			output = [...new Set(output)];
			return output;
		},
		getDataFromActivities(labels, infosByWeek) {
			var r = Math.floor(Math.random() * 255);
			var g = Math.floor(Math.random() * 255);
			var b = Math.floor(Math.random() * 255);
			let color = "rgba(" + r + "," + g + "," + b + ", 0.25)";
			
			let data = [];

			for (let i = 0; i < labels.length; i++) {
				let found = false;
				for (let j = 0; j < infosByWeek.length; j++) {
					let searchingWeek = infosByWeek[j].weekNumber + '-' + infosByWeek[j].year;
					if (labels[i] === searchingWeek) {
						data.push(infosByWeek[j].nbHours);
						found = true;
						break;
					}
				}
				if (!found) {
					data.push(0);
				}
			}

			return [{
				data: data,
				fill: true,
				backgroundColor: [color],
				borderColor: [color]
			}]
		},
		callGetAnimalDetails(animalId) {
			getAnimalDetails(animalId).then(animal => {
				this.id = animal.id
				this.name = animal.name
				if (animal.birthdate !== null) {
					this.birthdateDay = animal.birthdate[2] < 10 ? '0' + animal.birthdate[2] : animal.birthdate[2]
					this.birthdateMonth = animal.birthdate[1] < 10 ? '0' + animal.birthdate[1] : animal.birthdate[1]
					this.birthdateYear = animal.birthdate[0]
				}
				this.clubId = animal.club?.id
				this.ownerId = animal.owner?.id
				this.nbWorkingHoursByWeek = animal.nbWorkingHoursByWeek

				if (animal.type !== null) {
					let raceId = animal.type.races[0].id
					this.selectedSpecies = _.find(this.$store.getters.animalTypes, elt=> _.find(elt.races, race => race.id === raceId))
					this.selectedRace = _.find(this.selectedSpecies.races, elt => elt.id === raceId)
				}
				this.updateChart(animal.infosByWeek)
			})
			getAnimalSessions(animalId).then(response => this.sessions = response)
		},
		twoDigits() {
            if (parseInt(this.birthdateDay,10)<10 && (!this.birthdateDay.length || this.birthdateDay.length < 2))
                this.birthdateDay='0'+this.birthdateDay;
			if (parseInt(this.birthdateMonth,10)<10 && (!this.birthdateMonth.length || this.birthdateMonth.length < 2))
                this.birthdateMonth='0'+this.birthdateMonth;	
		},
        addAnimal(e) {
            e.preventDefault()
			this.errors = []
            if (this.checkForm()) {
                this.twoDigits()
                this.$store.dispatch({
                    type: 'addAnimal',
                    animal: {
                        name: this.name,
                        birthdate: this.birthdateYear !== '' && this.birthdateMonth !== '' && this.birthdateDay !== '' ? this.birthdateYear + '-' + this.birthdateMonth + '-' + this.birthdateDay : null,
                        clubId: this.clubId,
						ownerId: this.ownerId,
                        nbWorkingHoursByWeek: this.nbWorkingHoursByWeek,
						typeId: this.selectedRace?.id
                    },
                }).then(() => this.$toast.success('Animal ajouté')).catch((error) => {
					if (error.response.data.errorCode === 'E121')
						this.$toast.error('Nom Animal déjà existant')
					else
						this.$toast.error(`Erreur lors de la création de l'animal`)
                });	
			}
		},
        editAnimal(e) {
            e.preventDefault();
            if (this.checkForm()) {
                this.$store.dispatch({
                    type: 'updateAnimal',
                    animal: {
                        id: this.id,
                        name: this.name,
                        birthdate: this.birthdateYear + '-' + this.birthdateMonth + '-' + this.birthdateDay,
                        clubId:  this.clubId,
						ownerId : this.ownerId,
                        nbWorkingHoursByWeek: this.nbWorkingHoursByWeek,
						typeId: this.selectedRace?.id
                    }
                }).then(() => this.$toast.success(`Animal mis à jour`))
                .catch(() => {
					this.$toast.error(`Problème lors de la mise à jour de l'animal`)
				});
			}
		},
		goBack() {
            this.$router.go(-1);
        }
    }
}
</script>
<style scoped>
.animal-details-titles {
	width:50%;
	margin: 0 auto;
}

.animal-details-titles li {
	flex: 1;
	cursor: pointer;
	font-weight: 600;
	font-size: var(--font-large);
}
.animal-details {
	margin: 2rem auto 0 auto;	
	max-width: 80%;
}
</style>
