
import axios from 'axios';
import authHeaders from '../../../services/auth-header';

const DRAFT_ESTIMATIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/draft/estimations`;
const ESTIMATIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/estimations`;

export const getEstimationsDetails = async (event) => {
	let calledURL = `${ESTIMATIONS_URL}` + `?page=${event.askedInfos.page}&size=10`

	if (event.askedInfos.startDate !== null && event.askedInfos.endDate != null) {
		calledURL = calledURL + `&startDate=${event.askedInfos.startDate}&endDate=${event.askedInfos.endDate}`;
	}

	if (event.askedInfos.name !== null && event.askedInfos.name !== undefined)
		calledURL = calledURL + `&name=${event.askedInfos.name}`

	if (event.askedInfos.startDate !== null && event.askedInfos.endDate!=null) {
		calledURL = calledURL + `?startDate=${event.askedInfos.startDate}&endDate=${event.askedInfos.endDate}`;
	}
	
	return axios.get(`${calledURL}`,  { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
}

export const getDraftEstimation = async (event) => {
	return axios.post(DRAFT_ESTIMATIONS_URL, event.estimation, { headers: { 'Authorization':authHeaders()}, responseType: 'blob'})
	.then(response => {
      if(response.data) {
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
}