export const COLORS_TYPES = {
    DEFAULT: {
        value: 'DEFAULT',
        toDisplay: 'Défaut'
    },
    CLUBS: {
        value: 'CLUBS',
        toDisplay: 'Clubs'
    },
    THERAPISTS: {
        value: 'THERAPISTS',
        toDisplay: 'Thérapeutes'
    }
};