<template>
  <section>
    <div>
      About
    </div>
  </section>
</template>

<script>
export default ({
  // eslint-disable-next-line
  name: 'Signup'
})
</script>

<style scoped>

</style>
