<template>
    <div class="modal-overlay" style="display: block;" v-if="confirmDeleteUser" @click="hideModal"></div>  

    <form id="patient-account-form" class="apform">
        <div class="patient-type-subform">
            <div>
                <label for="lastname">Nom de famille</label>
                <input name="lastname" type="text" tabindex="1" v-model="patient.lastname" placeholder="Nom" required autofocus />
            </div>
            <div>
                <label for="firstname">Prénom</label>
                <input name="firstname" type="text" tabindex="2" v-model="patient.firstname"  placeholder="Prénom" required/>
            </div>
            <div>
                <label for="birthdate">Date de naissance</label>
                <select name="birthdate-select" class="birthdate-daymonth-select" v-model="birthdateDay" tabindex="3">
                    <option v-for="val in days" :key="val.index" :value="val.value" :selected="birthdateDay === val.index">
                        {{ val.value }}
                    </option>
                </select>
                <select class="birthdate-daymonth-select" v-model="birthdateMonth" tabindex="4">
                    <option v-for="val in months" :key="val.index" :value="val.value" :selected="birthdateMonth === val.index">
                        {{ val.value }}
                    </option>
                </select>
                <select class="birthdate-year-select" v-model="birthdateYear" tabindex="5">
                    <option v-for="val in years" :key="val.index" :value="val.value" :selected="birthdateYear === val.value">
                        {{ val.value }}
                    </option>
                </select>
            </div>
        </div>
        <div class="patient-type-subform">
            <div>
                <label for="email">E-mail</label>
                <input name="email" type="text" tabindex="7" v-model="patient.email" placeholder="E-mail" required/>
            </div>
            <div>
                <label for="phoneNumber">Téléphone</label>
                <input name="phoneNumber" type="text" tabindex="8" v-model="patient.phoneNumber" placeholder="Téléphone" />
            </div>
        </div>
        <div class="patient-type-subform">
            <div>
                <label for="streetName">Rue</label>
                <input name="streetName" type="text" tabindex="9" v-model="patient.address.streetName" placeholder="Rue" />
            </div>
        </div>
        <div class="patient-type-subform">
            <div>
                <label for="zipCode">Code postal</label>
                <input name="zipCode" type="text" tabindex="10" v-model="patient.address.zipCode" placeholder="Code postal" />
            </div>
            <div>
                <label for="city">Ville</label>
                <input name="city" type="text" tabindex="11" v-model="patient.address.city" placeholder="Ville" />
            </div>
        </div>
        
        <div class="deletion-button-container">
            <h3 @click="showDeleteAccount=!showDeleteAccount">Fermer son compte <font-awesome-icon icon="fa-solid fa-caret-right"/></h3>
            <button v-if="showDeleteAccount" @click="confirmDeleteUser = true" type="button" class="deletion-button">Supprimer mon compte</button>
        </div>

        <div class="modal-buttons">
			<button name="cancel" type="reset" class="cancel" @click="goBack">Annuler</button>
			<button name="submit" type="submit"  @click="updatePatient">Enregistrer</button>
		</div>

        <div v-if="confirmDeleteUser" class="modal">
            <div class="modal-content">
                <h5>Etes vous sûr de vouloir supprimer votre compte ?</h5>
                <br/>
                <p>Cette opération est irréversible.</p>
                <p>Toutes vos données seront perdues et ne pourront être restaurées.</p>
                <div class="modal-buttons">
                    <button name="cancel" class="cancel" @click="confirmDeleteUser = false">Annuler</button>
                    <button name="submit" @click="callDeleteAccount" class="button-danger">OK</button>
                </div>
            </div>
        </div>
    </form>    
</template>

<script>
import { deleteAccount, getPatientInfos, updatePatientInfos } from '../services/loggedPatient'
import Days from '../../../services/days-month-array'

export default {
    name: 'PatientAccount',
    data() {
        return {
            confirmDeleteUser: false,
            birthdateDay: '',
			birthdateMonth: '',
			birthdateYear: '',
            patient: {
                address: {}
            },
            days: Days.days(),
			months: Days.months(),
			years: Days.years(),
            showDeleteAccount: false
        }
    },
    mounted() {
        getPatientInfos().then((response) => {
            this.patient = response
            if (this.patient.birthdate !== null) {
                let birthdate = this.patient.birthdate;
                this.birthdateDay = birthdate[2] < 10 ? '0'+birthdate[2] : birthdate[2];
                this.birthdateMonth = birthdate[1] < 10 ? '0'+birthdate[1] : birthdate[1];
                this.birthdateYear = birthdate[0]
            }

            if (this.patient.address === null)
                this.patient.address = {}
        })
    },
    methods: {
        goBack() {
            this.$router.push('/patient')
        },
        updatePatient(e) {
            e.preventDefault();
            let patienToUpdate = {...this.patient}
            patienToUpdate.birthdate = this.birthdateYear !== '' && this.birthdateMonth !== '' & this.birthdateDay !== '' ? this.birthdateYear + '-' + this.birthdateMonth + '-' + this.birthdateDay : null,
            updatePatientInfos(patienToUpdate).then(()=> {
                this.$toast.success('Informations mises à jour')
            })
        },
        callDeleteAccount(e) {
            e.preventDefault();
            deleteAccount()
                .then(() => {
                    this.$router.push('/');
                    this.$toast.success('Votre compte a été correctement supprimé. A bientôt')
                }).catch(() => {
                    this.$toast.error('Erreur lors de la suppression du compte.');
                })
        }
    }
}
</script>
