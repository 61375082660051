<template>
    <div v-if="isMobile && !displayDetails">
        <div class="sessions-type-title">
            <div @click="enableFutureOnMobile()">A venir</div>
            <div @click="enableOldOnMobile()">Passés</div>
        </div>
        <div id="future-sessions" v-if="displayFutureOnMobile">
            <PatientSessionSmallCard :session="nextSession" v-if="nextSession" @click="changeSessionToDisplay(nextSession)"/>
            <div v-for="s in futureSessions" :key="s" class="small-card-list-container" @click="changeSessionToDisplay(s)">
                <PatientSessionSmallCard :session="s" />
            </div>
        </div>
        <div id="future-sessions" v-if="displayFutureOnMobile && nextSession === null && Object.keys(futureSessions).length === 0">
            Aucun rendez-vous à afficher
        </div>

        <div id="old-sessions" v-if="displayOldOnMobile">
            <div v-for="s in pastSessions" :key="s" class="small-card-list-container" @click="changeSessionToDisplay(s)">
                <PatientSessionSmallCard :session="s" />
            </div>
        </div>
        <div id="future-sessions" v-if="displayOldOnMobile && (pastSessions === null || pastSessions === undefined || Object.keys(pastSessions).length === 0)">
            Aucun rendez-vous à afficher
        </div>
    </div>
    <div v-if="isMobile && displayDetails">
        <div id="next-session-details" v-if="Object.keys(sessionToDisplay).length > 0">
            <PatientSessionDetails :session="sessionToDisplay" :key="sessionToDisplay"/>

            <div v-if="isCancelAuthorized()">
                <button id="button-cancel-session" @click="cancel">Annuler le rendez-vous</button>
            </div>

            <div id="session-location" v-if="sessionToDisplay.therapist && sessionAddress !== null">
                <h5><font-awesome-icon icon="fa-solid fa-location-dot"/> Se rendre à la consultation</h5>
                <a :href="encodedLocationURI">
                    <p>{{ sessionAddress.streetName }}</p>
                    <p>{{ sessionAddress.zipCode }} {{ sessionAddress.city }}</p>
                </a>
            </div>
            <div id="session-phone">
                <h5><font-awesome-icon icon="fa-solid fa-phone"/> Téléphone</h5>
                {{ sessionToDisplay.therapist ? sessionToDisplay.therapist.phoneNumber : ''}}
            </div>
        </div>
        <div class="modal-buttons">
            <button name="back" @click="displayDetails=false" class="button">RETOUR</button>
        </div>
    </div>
    <div v-if="!isMobile" class='flex-container'>
        <div id="patient-sessions">
            <div>
                <h4>Prochain rendez-vous</h4>
                <div v-if="nextSession !== null && Object.keys(nextSession).length > 0">
                    <PatientSessionSmallCard :session="nextSession" @click="changeSessionToDisplay(nextSession)"/>
                </div>
                <div v-else>Aucun</div>
            </div>
            <div id="future-sessions" v-if="nextSession !== null && Object.keys(nextSession).length > 0">
                <h4 v-if="!showFutureSessions" @click="showFutureSessions = !showFutureSessions" class="old-sessions-title">Voir mes rendez vous futur</h4>
                <h4 v-else @click="showFutureSessions = !showFutureSessions" class="old-sessions-title">Mes rendez vous futur</h4>
                <div v-if="showFutureSessions">
                    <div v-for="s in futureSessions" :key="s" class="small-card-list-container" @click="changeSessionToDisplay(s)">
                        <PatientSessionSmallCard :session="s" />
                    </div>
                </div>
            </div>

            <div id="old-sessions">
                <h4 v-if="!showPastSessions" @click="showPastSessions = !showPastSessions" class="old-sessions-title">Voir mes rendez vous passés</h4>
                <h4 v-else @click="showPastSessions = !showPastSessions" class="old-sessions-title">Mes rendez vous passés</h4>
                <div v-if="showPastSessions">
                    <div v-for="s in pastSessions" :key="s" class="small-card-list-container" @click="changeSessionToDisplay(s)">
                        <PatientSessionSmallCard :session="s" />
                    </div>
                </div>
            </div>
        </div>
        <div id="next-session-details" v-if="Object.keys(sessionToDisplay).length > 0">
            <PatientSessionDetails :session="sessionToDisplay" :key="sessionToDisplay"/>

            <div v-if="isCancelAuthorized()">
                <button id="button-cancel-session" @click="cancel">Annuler le rendez-vous</button>
            </div>

            <div id="session-location" v-if="sessionToDisplay.therapist && sessionAddress !== null">
                <h5><font-awesome-icon icon="fa-solid fa-location-dot"/> Se rendre à la consultation</h5>
                <a :href="encodedLocationURI">
                    <p>{{ sessionAddress.streetName }}</p>
                    <p>{{ sessionAddress.zipCode }} {{ sessionAddress.city }}</p>
                </a>
            </div>
            <div id="session-phone">
                <h5><font-awesome-icon icon="fa-solid fa-phone"/> Téléphone</h5>
                {{ sessionToDisplay.therapist ? sessionToDisplay.therapist.phoneNumber : ''}}
            </div>
        </div>
        <div v-else class="no-session-msg">
            <p>Aucune séance à afficher</p>
        </div>
    </div>
</template>

<script>
import { getSessions, getNextSession, cancelSessionById } from './services/loggedPatient'
import { getTherapistCancellationBefore } from '../../services/therapists'
import PatientSessionSmallCard from './PatientSessionSmallCard.vue'
import PatientSessionDetails from './PatientSessionDetails.vue'

export default {
    name: 'PatientMain',
    components: {
        PatientSessionSmallCard,
        PatientSessionDetails
    },
    data() {
        return {
            nextSession: {},
            sessions: [],
            showPastSessions: false,
            showFutureSessions: false,
            encodedLocationURI: '',
            sessionAddress: null,
            pastSessions: [],
            futureSessions: [],
            sessionToDisplay: {},
            therapistCancellationBefore: 0,
            displayFutureOnMobile: false,
            displayOldOnMobile: true,
            isMobile: this.$store.state.isMobile,
            displayDetails: false
        }
    },
    mounted() {
        this.getNextSessionByPatient()
        this.getSessionsByPatient()
        if (this.isMobile)
            this.enableFutureOnMobile()
    },
    methods: {
        getNextSessionByPatient(){
            getNextSession().then(response => {
                this.nextSession = response[0]
                this.changeSessionToDisplay(this.nextSession)
            })
        },
        changeSessionToDisplay(s) {
            if (s !== null) {
                this.sessionToDisplay = s
                this.sessionAddress = this.sessionToDisplay.club?.address ? this.sessionToDisplay?.club.address : this.sessionToDisplay?.therapist?.address
                if (this.sessionAddress != null) {
                    this.encodedLocationURI = encodeURI("https://www.google.com/maps?q="+ this.sessionAddress.streetName + ' ' + this.sessionAddress.zipCode + ' ' + this.sessionAddress.city)
                    this.getTherapistCancellationBefore(this.sessionToDisplay.therapist.id)
                }
                this.displayDetails=true
            }
        },
        getSessionsByPatient() {
            getSessions()
                .then((response) => {
                    let today = new Date()
                    this.sessions = this.nextSession === null ? response : response.filter(s => s.id !== this.nextSession.id)
                    this.sessions.forEach(s => {
                        if (new Date(s.date) > today)
                            this.futureSessions.push(s);
                        else 
                            this.pastSessions.push(s);
                    })
                })
        },
        cancel() {
            cancelSessionById(this.sessionToDisplay.id)
                .then()
                .catch(error => {
                    if(error.data.errorCOde === 'E129')
                        this.$toast.error('Cette session ne peut pas être annulée')
                })
                .finally(() => {
                    this.getNextSessionByPatient()
                });
        },
        getTherapistCancellationBefore(therapistId) {
            this.therapistCancellationBefore = getTherapistCancellationBefore(therapistId).then(response => {
                this.therapistCancellationBefore = response.cancellationBefore
            })
        },
        isCancelAuthorized() {
            let now = new Date()
            let sessionDetailsDate = new Date(this.sessionToDisplay.date + " " + this.sessionToDisplay.startTime)
            let nowWithCancellation = now.setHours(now.getHours() + this.therapistCancellationBefore) 
            return sessionDetailsDate > nowWithCancellation 
        },
        enableFutureOnMobile() {
            this.displayFutureOnMobile = true
            this.displayOldOnMobile = false
        },
        enableOldOnMobile() {
            this.displayFutureOnMobile = false
            this.displayOldOnMobile = true
        }
    }
}
</script>

<style scoped>
#patient-sessions {
    margin: 2rem 0 2rem 1rem;
    min-width: 14rem;
}

#next-session-details {
    flex-basis: 35%;
    margin: 2rem 0 2rem 10rem;
}
#button-cancel-session{
    color: red;
    background-color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;    
}

#old-sessions {
    margin-top: 2rem
}

.old-sessions-title {
    cursor: pointer
}

#session-location {
    margin-top: 2rem;
}

#session-phone {
    margin-top: 2rem;
}

.small-card-list-container {
    margin-top: 1rem;
    cursor: pointer;
}

.sessions-type-title {
    display: flex;
    justify-content: space-around;
}

.no-session-msg {
    padding: 2%;
    font-size: 26px;
    font-weight: 900;
}

@media (max-width: 800px) {
    #next-session-details {
        flex-basis: 100%;
        margin: 0;
    }
}
</style>