<template>
	
    <div id="full-calendar" v-if="Object.keys(eventDate).length === 0">
		<APTherapist :therapist="currentTherapist" />
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
	</div>
	<div v-else>
		<public-session v-if="Object.keys(currentTherapist).length !== 0" :therapist="currentTherapist" :eventDate="eventDate" @exit="initEventDate()"></public-session>
	</div>
</template>

<script>
import _ from 'lodash';
import APTherapist from './APTherapist.vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import { getTherapistById, getTherapistActivities } from '../../services/therapists'
import { getFirstAndLastDaysOfWeek } from '../../services/apdate'
import PublicSession from '../../components/public/PublicSession.vue'

export default {
    name: 'APTherapistAvailability',
    components: {
		APTherapist,
        FullCalendar,
		PublicSession
    },
    data() {
		var self = this;
        return {
            id: this.$route.params.id,
			currentTherapist: this.$store.state.currentTherapist,
			eventDate: {},
            calendarOptions: {
				plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
				headerToolbar: {
					left: 'prev,next today',
					center: 'title',
					right: screen.width < 800 ? '':'timeGridWeek,timeGridDay'
				},
				initialDate: new Date(),
				initialView: screen.width < 800 ? 'timeGridDay':'timeGridWeek',
				dateClick: this.dateClick,
				events: [],
				locale: frLocale,
				scrollTime: '08:00:00',
				allDaySlot: false,
				height: '45rem',
				selectOverlap: function() {
					self.isOverlap = true
				},
				customButtons: {
					prev: {click: () => {
						let calendarApi = this.$refs.fullCalendar.getApi()
						calendarApi.prev()
						let dates = getFirstAndLastDaysOfWeek(this.$refs.fullCalendar.calendar.getCurrentData().currentDate)
						this.getSessionsByDates(dates.startDate, dates.endDate)
					}},
					next: {click: () => {
						let calendarApi = this.$refs.fullCalendar.getApi()
						calendarApi.next()
						let dates = getFirstAndLastDaysOfWeek(this.$refs.fullCalendar.calendar.getCurrentData().currentDate)
						this.getSessionsByDates(dates.startDate, dates.endDate)
					}},
				},
				selectable: true,
			},
			isOverlap: false
        }
    },
    created() {
		let dates = getFirstAndLastDaysOfWeek(new Date())
		if (this.currentTherapist === null  || Object.keys(this.currentTherapist).length === 0) {
				getTherapistById(this.id).then((response) => {
					this.currentTherapist = response;
					this.getSessionsByDates(dates.startDate, dates.endDate)
				})
		} else {
			this.getSessionsByDates(dates.startDate, dates.endDate)
		}
    },
    methods: {
		initEventDate() {
			this.eventDate = {}
		},
        dateClick(elt){
			if (this.isOverlap === false)
				this.eventDate = elt;
			this.isOverlap = false;
        },
        getSessionsByDates(startDate, endDate) {
			getTherapistActivities(this.id, startDate, endDate).then((sessions) => {
				this.updateEvents(sessions)
			}).catch(() => {})
		},
        updateEvents(sessions) {
			let uniqueSessions = _.uniqBy(sessions, function (session) {
				return session.date + " " + session.startTime + " " + session.endTime;
			});

			this.calendarOptions.events = uniqueSessions.map(function(elt){		
				return {
					title : '', 
					start: new Date(elt.date + " " + elt.startTime), 
					end: new Date(elt.date + " " + elt.endTime),
					id: elt.id
				};
			});
		}
    }
}
</script>
