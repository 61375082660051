<template>

    <h3>Saisissez ici votre bilan prévisionnel</h3>

    <form class="apform forecast-form-container">
        <table>
            <thead>
                <tr>
                    <td></td>
                    <td></td>
                    <td><label for="increaseN1" class="forecast-label-header">Augmentation N+1</label><input class="forecast-input-header" name="increaseN1" type="number" max="999" v-model="financeForecast.increaseN1" @change="changeIncreaseN1()"/></td>
                    <td><label for="increaseN2" class="forecast-label-header">Augmentation N+2</label><input class="forecast-input-header" name="increaseN2" type="number" max="999" v-model="financeForecast.increaseN2" @change="changeIncreaseN2()"/></td>
                </tr>
                <tr class="forecast-head-line">
                    <td></td>
                    <td>Année N</td>
                    <td>Année N+1</td>
                    <td>Année N+2</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Immobilisations</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.fixedAssetsN" @change="changeFixedAssetN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.fixedAssetsN1" @change="changeFixedAssetN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.fixedAssetsN2"/></td>
                </tr>
                <tr>
                    <td>Amortissements, Provisions</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.depreciationN" @change="changeDepreciationN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.depreciationN1" @change="changeDepreciationN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.depreciationN2"/></td>
                </tr>
                <tr class="forecast-highlight">
                    <td>Immobilisations nettes</td>
                    <td>{{ financeForecast.fixedAssetsN - financeForecast.depreciationN }}</td>
                    <td>{{ financeForecast.fixedAssetsN1 - financeForecast.depreciationN1 }}</td>
                    <td>{{ financeForecast.fixedAssetsN2 - financeForecast.depreciationN2 }}</td>
                </tr>
                <tr>
                    <td>Créances clients</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.receivablesN" @change="changeReceivablesN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.receivablesN1" @change="changeReceivablesN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.receivablesN2"/></td>
                </tr>
                <tr>
                    <td>Autres créances</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.otherReceivablesN" @change="changeOtherReceivablesN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.otherReceivablesN1" @change="changeOtherReceivablesN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.otherReceivablesN2"/></td>
                </tr>
                <tr>
                    <td>Disponibitées</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.availabilityN" @change="changeAvailabilityN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.availabilityN1" @change="changeAvailabilityN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.availabilityN2"/></td>
                </tr>
                <tr class="forecast-highlight">
                    <td>Actif circulant</td>
                    <td>{{ (financeForecast.receivablesN + financeForecast.otherReceivablesN + financeForecast.availabilityN).toFixed(2) }}</td>
                    <td>{{ (financeForecast.receivablesN1 + financeForecast.otherReceivablesN1 + financeForecast.availabilityN1).toFixed(2) }}</td>
                    <td>{{ (financeForecast.receivablesN2 + financeForecast.otherReceivablesN2 + financeForecast.availabilityN2).toFixed(2) }}</td>
                </tr>
                <tr class="forecast-highlight-sum">
                    <td>Total de l'actif</td>
                    <td>{{ (financeForecast.fixedAssetsN - financeForecast.depreciationN  + financeForecast.receivablesN + financeForecast.otherReceivablesN + financeForecast.availabilityN).toFixed(2) }}</td>
                    <td>{{ (financeForecast.fixedAssetsN1 - financeForecast.depreciationN1  + financeForecast.receivablesN1 + financeForecast.otherReceivablesN1 + financeForecast.availabilityN1).toFixed(2) }}</td>
                    <td>{{ (financeForecast.fixedAssetsN2 - financeForecast.depreciationN2  + financeForecast.receivablesN2 + financeForecast.otherReceivablesN2 + financeForecast.availabilityN2).toFixed(2) }}</td>
                </tr>
                <tr>
                    <td>Capital social</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.shareCapitalN" @change="changeShareCapitalN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.shareCapitalN1" @change="changeShareCapitalN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.shareCapitalN2"/></td>
                </tr>
                <tr>
                    <td>Réserves, Report à nouveau</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.retainedEarningsN" @change="changeRetainedEarningN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.retainedEarningsN1" @change="changeRetainedEarningN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.retainedEarningsN2"/></td>
                </tr>
                <tr>
                    <td>Résultat de l'exercice</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.exerciseResultN" @change="changeExerciseResultN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.exerciseResultN1" @change="changeExerciseResultN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.exerciseResultN2"/></td>
                </tr>
                <tr class="forecast-highlight">
                    <td>Capitaux propres</td>
                    <td>{{ (financeForecast.shareCapitalN + financeForecast.retainedEarningsN +  financeForecast.exerciseResultN).toFixed(2) }}</td>
                    <td>{{ (financeForecast.shareCapitalN1 + financeForecast.retainedEarningsN1 +  financeForecast.exerciseResultN1).toFixed(2) }}</td>
                    <td>{{ (financeForecast.shareCapitalN2 + financeForecast.retainedEarningsN2 +  financeForecast.exerciseResultN2).toFixed(2) }}</td>
                </tr>
                <tr>
                    <td>Emprunt et dettes assimilés</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.borrowingsN" @change="changeBorrowingsN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.borrowingsN1" @change="changeBorrowingsN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.borrowingsN2"/></td>
                </tr>
                <tr>
                    <td>Dettes fournisseurs</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.payablesN" @change="changePayablesN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.payablesN1" @change="changePayablesN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.payablesN2"/></td>
                </tr>
                <tr>
                    <td>Dettes fiscales et sociales</td>
                    <td><input class="" name="" type="number" v-model="financeForecast.socialTaxDebtsN" @change="changeSocialTaxDebtsN()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.socialTaxDebtsN1" @change="changeSocialTaxDebtsN1()"/></td>
                    <td><input class="" name="" type="number" v-model="financeForecast.socialTaxDebtsN2"/></td>
                </tr>
                <tr class="forecast-highlight">
                    <td>Total des dettes</td>
                    <td>{{ (financeForecast.borrowingsN +  financeForecast.payablesN + financeForecast.socialTaxDebtsN).toFixed(2) }}</td>
                    <td>{{ (financeForecast.borrowingsN1 +  financeForecast.payablesN1 + financeForecast.socialTaxDebtsN1).toFixed(2) }}</td>
                    <td>{{ (financeForecast.borrowingsN2 +  financeForecast.payablesN2 + financeForecast.socialTaxDebtsN2).toFixed(2) }}</td>
                </tr>
                <tr class="forecast-highlight-sum">
                    <td>Total du passif</td>
                    <td>{{ (financeForecast.shareCapitalN + financeForecast.retainedEarningsN +  financeForecast.exerciseResultN + financeForecast.borrowingsN +  financeForecast.payablesN + financeForecast.socialTaxDebtsN).toFixed(2) }}</td>
                    <td>{{ (financeForecast.shareCapitalN1 + financeForecast.retainedEarningsN1 +  financeForecast.exerciseResultN1 + financeForecast.borrowingsN1 +  financeForecast.payablesN1 + financeForecast.socialTaxDebtsN1).toFixed(2) }}</td>
                    <td>{{ (financeForecast.shareCapitalN2 + financeForecast.retainedEarningsN2 +  financeForecast.exerciseResultN2 + financeForecast.borrowingsN2 +  financeForecast.payablesN2 + financeForecast.socialTaxDebtsN2).toFixed(2) }}</td>
                </tr>
            </tbody>
        </table>
        <div class="modal-buttons">
            <button name="cancel" type="reset" tabindex="15" class="cancel" @click="goBack">Annuler</button>
            <button name="submit" type="submit" tabindex="16" @click="registerFinanceForecast">Enregistrer</button>
        </div>
    </form>
</template>

<script>
import { getFinanceForecast, saveFinanceForecast } from '../../../services/finance'
export default ({
    name: 'FinancialForecast',
    setup() {
        
    },
    data() {
        return {
            financeForecast: {
                increaseN1: 0,
                increaseN2: 0,
                fixedAssetsN: 0,
                fixedAssetsN1: 0,
                fixedAssetsN2: 0,
                depreciationN: 0,
                depreciationN1: 0,
                depreciationN2: 0,
                receivablesN:0,
                receivablesN1:0,
                receivablesN2:0,
                otherReceivablesN: 0,
                otherReceivablesN1: 0,
                otherReceivablesN2: 0,
                availabilityN: 0,
                availabilityN1: 0,
                availabilityN2: 0,
                shareCapitalN: 0,
                shareCapitalN1: 0,
                shareCapitalN2: 0,
                retainedEarningsN: 0,
                retainedEarningsN1: 0,
                retainedEarningsN2: 0,
                exerciseResultN: 0,
                exerciseResultN1: 0,
                exerciseResultN2: 0,
                borrowingsN : 0,
                borrowingsN1 : 0,
                borrowingsN2 : 0,
                payablesN: 0,
                payablesN1: 0,
                payablesN2: 0,
                socialTaxDebtsN: 0,
                socialTaxDebtsN1: 0,
                socialTaxDebtsN2: 0
            }
        }
    },
    mounted() {
        getFinanceForecast().then((response) => {
            if (Object.keys(response).length !== 0) {
                this.financeForecast = response
                this.computeForecast(false)
            }
        });
    },
    methods: {
        goBack(){
			this.$router.go(-1);
		},
        registerFinanceForecast(e) {
            e.preventDefault()
            saveFinanceForecast(this.financeForecast).then(() => {
                this.$toast.success('Enregistrement effectué')
            }).catch(() => {
                this.$toast.error("Erreur lors de l'enregistrement")
            })
        }, 
        changeIncreaseN1() {
            let multiplyN1 = 1+(this.financeForecast.increaseN1/100)
            this.financeForecast.fixedAssetsN1 = this.financeForecast.fixedAssetsN * multiplyN1
            this.financeForecast.depreciationN1 = this.financeForecast.depreciationN * multiplyN1
            this.financeForecast.receivablesN1 = this.financeForecast.receivablesN * multiplyN1
            this.financeForecast.otherReceivablesN1 = this.financeForecast.otherReceivablesN * multiplyN1
            this.financeForecast.availabilityN1 = this.financeForecast.availabilityN * multiplyN1
            this.financeForecast.shareCapitalN1 = this.financeForecast.shareCapitalN * multiplyN1
            this.financeForecast.retainedEarningsN1 = this.financeForecast.retainedEarningsN * multiplyN1
            this.financeForecast.exerciseResultN1 = this.financeForecast.exerciseResultN * multiplyN1
            this.financeForecast.borrowingsN1 = this.financeForecast.borrowingsN * multiplyN1
            this.financeForecast.payablesN1 = this.financeForecast.payablesN * multiplyN1
            this.financeForecast.socialTaxDebtsN1 = this.financeForecast.socialTaxDebtsN * multiplyN1
            this.changeIncreaseN2()
        },
        changeIncreaseN2() {
            let multiplyN2 = 1+(this.financeForecast.increaseN2/100)
            this.financeForecast.fixedAssetsN2 = this.financeForecast.fixedAssetsN1 * multiplyN2
            this.financeForecast.depreciationN2 = this.financeForecast.depreciationN1 * multiplyN2
            this.financeForecast.receivablesN2 = this.financeForecast.receivablesN1 * multiplyN2
            this.financeForecast.otherReceivablesN2 = this.financeForecast.otherReceivablesN1 * multiplyN2
            this.financeForecast.availabilityN2 = this.financeForecast.availabilityN1 * multiplyN2
            this.financeForecast.shareCapitalN2 = this.financeForecast.shareCapitalN1 * multiplyN2
            this.financeForecast.retainedEarningsN2 = this.financeForecast.retainedEarningsN1 * multiplyN2
            this.financeForecast.exerciseResultN2 = this.financeForecast.exerciseResultN1 * multiplyN2
            this.financeForecast.borrowingsN2 = this.financeForecast.borrowingsN1 * multiplyN2
            this.financeForecast.payablesN2 = this.financeForecast.payablesN1 * multiplyN2
            this.financeForecast.socialTaxDebtsN2 = this.financeForecast.socialTaxDebtsN1 * multiplyN2
        },
        changeFixedAssetN(){
            this.financeForecast.fixedAssetsN1 = this.financeForecast.fixedAssetsN*(1+this.financeForecast.increaseN1/100)
            this.changeFixedAssetN1()
        },
        changeFixedAssetN1(){
            this.financeForecast.fixedAssetsN2 = this.financeForecast.fixedAssetsN1*(1+this.financeForecast.increaseN2/100)
        },
        changeDepreciationN(){
            this.financeForecast.depreciationN1 = this.financeForecast.depreciationN*(1+this.financeForecast.increaseN1/100)
            this.changeIncreaseN1()
        },
        changeDepreciationN1(){
            this.financeForecast.depreciationN2 = this.financeForecast.depreciationN1*(1+this.financeForecast.increaseN2/100)
        },
        changeReceivablesN(){
            this.financeForecast.receivablesN1 = this.financeForecast.receivablesN*(1+this.financeForecast.increaseN1/100)
        },
        changeReceivablesN1(){
            this.financeForecast.receivablesN2 = this.financeForecast.receivablesN1*(1+this.financeForecast.increaseN2/100)
        },
        changeOtherReceivablesN(){
            this.financeForecast.otherReceivablesN1 = this.financeForecast.otherReceivablesN*(1+this.financeForecast.increaseN1/100)
            this.changeOtherReceivablesN1()
        },
        changeOtherReceivablesN1(){
            this.financeForecast.otherReceivablesN2 = this.financeForecast.otherReceivablesN1*(1+this.financeForecast.increaseN2/100)
        },
        changeAvailabilityN(){
            this.financeForecast.availabilityN1 = this.financeForecast.availabilityN*(1+this.financeForecast.increaseN1/100)
            this.changeAvailabilityN1()
        },
        changeAvailabilityN1(){
            this.financeForecast.availabilityN2 = this.financeForecast.availabilityN1*(1+this.financeForecast.increaseN2/100)
        },
        changeShareCapitalN(){
            this.financeForecast.shareCapitalN1 = this.financeForecast.shareCapitalN*(1+this.financeForecast.increaseN1/100)
            this.changeShareCapitalN1()
        },
        changeShareCapitalN1(){
            this.financeForecast.shareCapitalN2 = this.financeForecast.shareCapitalN1*(1+this.financeForecast.increaseN2/100)
        },
        changeRetainedEarningN(){
            this.financeForecast.retainedEarningsN1 = this.financeForecast.retainedEarningsN*(1+this.financeForecast.increaseN1/100)
            this.changeRetainedEarningN1()
        },
        changeRetainedEarningN1(){
            this.financeForecast.retainedEarningsN2 = this.financeForecast.retainedEarningsN1*(1+this.financeForecast.increaseN2/100)
        },
        changeExerciseResultN(){
            this.financeForecast.exerciseResultN1 = this.financeForecast.exerciseResultN*(1+this.financeForecast.increaseN1/100)
            this.changeExerciseResultN1()
        },
        changeExerciseResultN1(){
            this.financeForecast.exerciseResultN2 = this.financeForecast.exerciseResultN1*(1+this.financeForecast.increaseN2/100)
        },
        changeBorrowingsN(){
            this.financeForecast.borrowingsN1 = this.financeForecast.borrowingsN*(1+this.financeForecast.increaseN1/100)
            this.changeBorrowingsN1()
        },
        changeBorrowingsN1(){
            this.financeForecast.borrowingsN2 = this.financeForecast.borrowingsN1*(1+this.financeForecast.increaseN2/100)
        },
        changePayablesN(){
            this.financeForecast.payablesN1 = this.financeForecast.payablesN*(1+this.financeForecast.increaseN1/100)
            this.changePayablesN1()
        },
        changePayablesN1(){
            this.financeForecast.payablesN2 = this.financeForecast.payablesN1*(1+this.financeForecast.increaseN2/100)
        },
        changeSocialTaxDebtsN() {
            this.financeForecast.socialTaxDebtsN1 = this.financeForecast.socialTaxDebtsN*(1+this.financeForecast.increaseN1/100)
            this.changeSocialTaxDebtsN1()
        },
        changeSocialTaxDebtsN1() {
            this.financeForecast.socialTaxDebtsN2 = this.financeForecast.socialTaxDebtsN1*(1+this.financeForecast.increaseN2/100)
        }
    }
})
</script>
<style scoped>
.forecast-form-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.forecast-head-line {
    font-weight: 600;
    
}
.forecast-head-line td {
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.forecast-label-header{
    display: inline;
    margin-right: 2rem;
}

.forecast-input-header {
    width: 6rem !important
}
.forecast-highlight td {
    background-color:  var(--light-gray);
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
}
.forecast-highlight-sum {
    background-color:  var(--cream-color);
    font-weight: 600;
}
</style>

