<template>
    <div id="questions-steps-container">
        <div class="questions-steps-header">Afin de mieux préparer votre rendez-vous, le/la praticien(ne) souhaiterait vous poser quelques questions.</div>
        
        
        <div v-if="questions.length > 0">
            <div v-for="(question, index) in questions" :key="index">
                <h3>{{ question.text }}</h3>
                <textarea rows="4" columns="50" class="questions-steps-input" v-model="answers[index].text" />
            </div>
        </div>

        <div v-if="questions.length == 0">
            Aucune question pour ce type de séance, vous pouvez valider directement.
        </div>
        
        <div class="modal-buttons">
            <button @click="continueProcess">Valider</button>
        </div>
    </div>
</template>
<script>

export default {
    name: 'QuestionsStep',
    data() {
        return {
            answers: []
        }
    },
    props: ["therapist", "questions"],
    emits: ['exit'],
    created() {
        this.answers = [];
        for(let i = 0; i < this.questions.length; i++) {
            this.answers.push({
                questionId: this.questions[i].id,
                text: ''
            })
        }
    },
    methods: {
        continueProcess() {
            this.$emit('exit', this.answers)
        },
    }
}
</script>
<style>
#questions-steps-container {
    margin: 2rem 0;
}

.questions-steps-input {
    width: 30%;
    font-size: var(--font-regular);
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 0.2rem;
    margin-right: 2rem;
}

</style>