<template>

	<h4>Présentation de l'agenda</h4>
	<br/>
	<form>
		<div>
			<label for="colorTypeSelector">Couleur par type de : </label>
			<select name="colorTypeSelector" tabindex="1"  v-model="colorsType" @change="updateColors">
				<option v-for="filter in colorsTypesFilter" :key="filter" :value="filter.value" :selected="colorsType === filter.value">{{filter.toDisplay}}</option>
			</select>
			<div v-if="colorsType===COLORS_TYPES.DEFAULT.value" id="defaultType">
				<p>Dans ce mode :</p>
				<ul id="default-comments">
					<li>les séances non payées sont bleues,</li>
					<li>Les séances payées avec patient absent sont oranges,</li>
					<li>Les séances payées avec patient présent sont vertes.</li>
				</ul>
			</diV>
			<div v-else class="table-colors-container">
				<table class="data-table table-colors-value">
					<thead class="data-table-header">
						<tr>
							<th>Nom</th>
							<th>Couleur</th>
						</tr>
					</thead>
					<tbody v-if="colorsType===COLORS_TYPES.CLUBS.value" >
						<tr	 v-for="color in colors" v-bind:key="color" >
							<td style="min-width:14rem">{{ color.object.name }}</td>
							<td><input type="color" id="head" name="head" v-model="color.hexa"/></td>
						</tr>
					</tbody>
					<tbody v-if="colorsType===COLORS_TYPES.THERAPISTS.value" >
						<tr	 v-for="color in colors" v-bind:key="color" >
							<td style="min-width:14rem">{{ color.object.lastname ? color.object.lastname + " " + color.object.firstname : color.object.email }}</td>
							<td><input type="color" id="head" name="head" v-model="color.hexa"/></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div v-if="!isAssistant">
			<label for="cancellationBefore">Annulation rendez-vous impossible </label>
			<input name="cancellationBefore" type="number" v-model="cancellationBefore"/> avant le rendez-vous (en heures)
		</div>
		<div class="table-colors-buttons">
			<button name="cancel" class="cancel" type="reset" tabindex="15" @click="backToCalendar">Annuler</button>
			<button name="submit" type="submit" tabindex="16" @click="updatePersonalization">Valider</button>
		</div>
	</form>
</template>

<script>
import _ from 'lodash';
import { ROLES } from '../../../services/roles';
import { COLORS_TYPES } from '../../../services/colorsType'
import { saveColors, getColors, saveCancellation, getCancellation } from '../../../services/personalization'
import { getTherapists } from '../services/team'

export default {
    name: 'APPersonalization',
    data() {
        return {
			colorsType: COLORS_TYPES.DEFAULT.value,
			colors: [],
			therapists: [],
			clubs: [],
			COLORS_TYPES: COLORS_TYPES,
			colorsTypesFilter: this.createColorTypesFilter(),
			cancellationBefore: 48,
			isAssistant: false
        }
    },
	mounted() {
		getColors().then(async (response) => {
			this.colorsType = response.type

			if (this.$store.getters.clubs.length === 0) {
				await this.$store.dispatch("initClubs")
			}
			this.clubs = this.$store.getters.clubs;
			getTherapists().then(team => {
				this.therapists = team.members
			}).finally(() => {
				if (response.type && response.type !== COLORS_TYPES.DEFAULT) {
					this.initColors(response.colors);
				}
			})
		})
		this.isAssistant = this.$store.getters.roles === ROLES.ASSISTANT.value;
		if (!this.isAssistant)
			getCancellation().then(response => {
				this.cancellationBefore = response.cancellationBefore
			})
		
	},
	methods: {
		backToCalendar() {
			this.$router.push({name: 'Sessions', state: {initialDate: this.startDate}});
		},
		initColors(personalizationColors) {
			this.colors = [];
			if (personalizationColors) {
				personalizationColors.forEach(color => {
					if (this.colorsType === COLORS_TYPES.CLUBS.value) {
						this.colors.push({ hexa : color.hexa, object : _.find(this.clubs, el => el.id === color.objectId)});
					} else if (this.colorsType === COLORS_TYPES.THERAPISTS.value) {
						this.colors.push({hexa: color.hexa, object: _.find(this.therapists, el => el.id === color.objectId)});
					}
				});
			}
		},
		updateColors() {
			this.colors = [];
			if (this.colorsType === COLORS_TYPES.CLUBS.value) {
				this.defineColors(this.clubs);
			} else if (this.colorsType === COLORS_TYPES.THERAPISTS.value) {
				this.defineColors(this.therapists);
			}
		},
		defineColors(objects) {
			objects.forEach(object => {
				this.colors.push({ hexa: "#"+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'), object: object});
			});
		},
		updatePersonalization(e) {
			e.preventDefault();
			let colorsWithId = [];
			this.colors.forEach(color => {
				colorsWithId.push({ 'hexa': color.hexa, 'objectId': color.object.id });
			})
			if (!this.isAssistant) {
				saveCancellation(this.cancellationBefore).then(() => {
					saveColors({type: this.colorsType, colors: colorsWithId}).then(() => {
						this.$toast.success('information sauvegardée')
					})
				})
			} else {
				saveColors({type: this.colorsType, colors: colorsWithId}).then(() => {
					this.$toast.success('information sauvegardée')
				})
			}
		},
		createColorTypesFilter() {
			let colorsTypesFilter = Object.keys(COLORS_TYPES).map(index => {
				return COLORS_TYPES[index];
			});
			if (this.$store.getters.user.tenant.offer === 'STANDARD' || this.$store.getters.user.tenant.offer === 'PRO') {
				colorsTypesFilter = colorsTypesFilter.filter((el) => COLORS_TYPES.THERAPISTS !== el);
			}
			return colorsTypesFilter;
		},
	}
}
</script>

<style scoped>
#defaultType {
	margin: 5rem 0 5rem 5rem;
}

#default-comments {
	margin-left: 2rem;
}
.table-colors-container {
	display: flex;
    justify-content: center;
    align-items: center;
	width: 50%;
	margin: 0 auto;
	margin-bottom: 5rem;
}

.table-colors-value {
	width: 100%;
	margin-left: 0;
}

.table-colors-buttons {
	display: flex;
    justify-content: space-around;
    align-items: center;
	width: 50%;
	margin: 0 auto;
}
</style>
