<template>
    <ProHeaderLoggedIn/>
    <main class="flex-container">
      <SettingsMenu/>
      <div id="content">
        <router-view></router-view>
      </div>
    </main>
</template>

<script>
import SettingsMenu from './SettingsMenu'
import ProHeaderLoggedIn from '../ProHeaderLoggedIn'

export default {
    name: 'MainSettings',
    components: {
      SettingsMenu,
      ProHeaderLoggedIn
    }
}
</script>
