<template>
  <ProHeaderNotLogged v-if="jwtToken === null" />
  <ProHeaderLoggedIn v-else/>
  <main :class="jwtToken || dontNeedAuthentication() ? 'logged-main' : ''">
    <router-view v-if="dontNeedAuthentication()"/>
    <Greetings v-else-if="jwtToken === null"/>
    <APMain v-else/>
  </main> 

  <FooterItem/>   
</template>

<script>
import ProHeaderNotLogged from './ProHeaderNotLogged.vue'
import ProHeaderLoggedIn from './ProHeaderLoggedIn.vue'
import FooterItem from './FooterItem.vue'
import APMain from './APMain.vue'
import Greetings from './Greetings.vue'
import { mapState } from 'vuex';
import { goToFirstAccessibleRoute } from './../../services/route-utils'

export default {
    name: 'ProIndex',
    components: {
        ProHeaderNotLogged, 
        ProHeaderLoggedIn,
        FooterItem,
        Greetings,
        APMain
    },
    methods: {
        dontNeedAuthentication() {
            let route = this.$router.currentRoute.value.name;
            return route === 'About' || route === 'Prices' || route === 'AskForResetPassword' || route === 'ChangePassword' || route === 'DemoThanks' || route === 'Cgu'
        },
        checkTokenIsValid() {
            this.$store.dispatch({
                        type: 'getUserDetailsWithFirm',
                    }).then(() => {
                goToFirstAccessibleRoute(this.$router);  
            }).catch(() => {
                //if (response.status === 401) //TODO il y a peut etre des choses a faire selon le status...
                    this.$store.dispatch('logout');
            });
        }
    },
    computed: {
		...mapState(['jwtToken']),
	},
    mounted(){
        this.checkTokenIsValid();
    }
}
</script>
