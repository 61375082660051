<template>
<div id="changePassword">
    <h3>Réinitialisation du mot de passe</h3>

    <form class="apform">
      <div>
        <input type="text" tabindex="1" v-model="email" placeholder="Email"/>
      </div>
      <div>
        <input type="password" tabindex="2" v-model="password" placeholder="Mot de passe" />
      </div>
      <div>
        <input type="password" tabindex="3" v-model="confirmPassword" placeholder="Confirmation mot de passe" />
      </div>
      <div id="changePassword-button-container">
        <button id="changePassword-button" name="submit" type="submit" @click="changePassword">Enregistrer</button>
      </div>
    </form>
</div>    
</template>

<script>
export default ({
  name: 'ChangePassword',
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      token: ''
    }
  }, 
  methods : {
    changePassword(e) {
      e.preventDefault();
      this.$store.dispatch({
          type: 'updatePassword', 
          user: {
            email: this.email,
            newPassword: this.password,
            token: this.token
          }
      }).then(()=> {
        this.$toast.success('Vous pouvez vous reconnecter');
        this.$router.push('/');
      })
    },
    checkChangePassword() {
      this.$store.dispatch({
                type: 'checkPasswordChange', 
                token: this.token
        }).then(()=> {
        })
    }
  },
  mounted() {
        this.token = this.$route.query.token;
        this.checkChangePassword();
  }
})
</script>

<style scoped>
#changePassword {
  text-align: center;
  margin-top: 5rem;
  flex: 1;
}

#changePassword div {
  display: flex;
  justify-content: center;
}

#changePassword-button-container {
  justify-content: center;
}

#changePassword-button {
  border: 1px solid var(--secondary-color);
}
</style>
