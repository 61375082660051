import axios from 'axios';
import authHeaders from '../services/auth-header';
import * as types from './mutation-types';

const SPECIES_URL = process.env.VUE_APP_BASE_AP_URL+`/types/animals`;

export async function getSpecies({ commit }) {
	return axios.get(SPECIES_URL, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			commit(types.GET_ANIMAL_TYPES, response.data)
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}