<template>
    <PatientHeader/>
    <main class="flex-container">
      <PatientSettingsMenu/>
      <div id="content">
        <router-view></router-view>
      </div>
    </main>
</template>

<script>
import PatientHeader from '../PatientHeader.vue'
import PatientSettingsMenu from './PatientSettingsMenu.vue'

export default {
    name: 'MainPatientSettings',
    components: {
      PatientHeader,
      PatientSettingsMenu
    }
}
</script>
