import axios from 'axios';
import authHeaders from '../services/auth-header';
import * as types from './mutation-types';

const CLUBS_URL = process.env.VUE_APP_BASE_AP_URL+`/clubs`;

export const initClubs = ({ commit }) => {
	let clubs = [];
	return axios.get(CLUBS_URL, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          clubs.push(...response.data);
        commit(types.INIT_CLUBS, clubs);
		return Promise.resolve();
      }
	})
	.catch(function (error) {
      return Promise.reject(error.response);
	});
};

export const addClub = async ({ commit }, event) => {
	return axios.post(CLUBS_URL, event.club, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
            commit(types.CLUB_CREATED, response.data);
			return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.CLUB_NOT_CREATED);
        return Promise.reject(error);
	});
};

export const updateClub = async ({ commit }, event) => {
	return axios.put(`${CLUBS_URL}/${event.club.id}`, event.club, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
			const updatedClub = {
				id: response.data.id,
				name: response.data.name,
				email: response.data.email,
				address: {
					zipCode: response.data.address.zipCode,
					streetName:response.data.address.streetName,
					city: response.data.address.city
				},
				phoneNumber: response.data.phoneNumber
			}
            commit(types.CLUB_UPDATED, updatedClub);
			
            return Promise.resolve(updatedClub);
        }
	})
	.catch(function (error) {
		commit(types.CLUB_UPDATED_ERROR);
        return Promise.reject(error);
	});
};

export const deleteClub = async ({ commit }, event) => {
	return axios.delete(`${CLUBS_URL}/${event.id}`, {headers : { 'Authorization':authHeaders()}})
	.then(() => {
        commit(types.CLUB_REMOVED, event.id);
		return Promise.resolve();
	})
	.catch((error) => {
		return Promise.reject(error);
	});
};

export const getClubDetails = async ({ commit }, event) => {
	return axios.get(`${CLUBS_URL}/${event.id}`,  { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          commit(types.GET_CLUB, response.data);
          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      commit(types.GET_CLUB_ERROR, error);
      return Promise.reject(error);
	});
}

export const getClubSessionDetails = async ({ commit }, event) => {
	let calledURL = `${CLUBS_URL}/${event.askedInfos.id}/activity`;
	if (event.askedInfos.startDate !== null && event.askedInfos.endDate!=null) {
		calledURL = calledURL + `?startDate=${event.askedInfos.startDate}&endDate=${event.askedInfos.endDate}`;
	}
	return axios.get(`${calledURL}`,  { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          commit(types.GET_CLUB_SESSION_DETAILS, response.data.sessions);

          return Promise.resolve(response.data);
      }
	})
	.catch(function (error) {
      commit(types.GET_CLUB_ERROR, error);
      return Promise.reject(error);
	});
}

export const updateClubSessionDetails = async({commit}, event) => {
	commit(types.GET_CLUB_SESSION_DETAILS, event.sessions);
}