<template>
  <div id="payment-block-container">
    <h3>Votre mois d'essai est arrive à sa fin ?</h3>
    <p>Vos informations de paiement ne sont pas renseignées ?</p>
    <FillUserInfos/>
    <p>Puis contactez nous par mail à l'adresse contact@animal-planner.com afin que nous réactivions votre compte au plus vite!</p>
  </div>
</template>

<script>
import FillUserInfos from "./FillUserInfos.vue"

export default ({
  // eslint-disable-next-line
  name: 'PaymentNeeded',
  components: {
    FillUserInfos
  },
  data() {
    return {
    }
  },
  methods : {
  },
  created() {
    this.$store.state.isPaymentBlocked = true;
  }
})
</script>

<style scoped>
  #payment-block-container {
    text-align: center;
    margin-top: 2rem;
  }
</style>
