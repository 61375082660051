import * as types from './mutation-types';
import router from '../router'

export const mutations = {
	
	[types.DEFINE_PLATFORM](state, isMobile) {
		state.isMobile = isMobile;
	},
	[types.CURRENT_THERAPIST](state, currentTherapist) {
		state.currentTherapist = currentTherapist;
	},
	[types.PAYMENT_NEEDED]() {
		router.push('/pros/payment-blocked');
	},
	[types.LOGIN](state, payload) {
		localStorage.setItem('jwtToken', payload.token);
		state.jwtToken = payload.token;
	},
	[types.LOGIN_ERROR]() {},
	[types.REGISTER]() {},
	[types.USER_NOT_FOUND]() {},
	[types.RESET_PASSWORD]() {},
	[types.LOGOUT](state) {
		state.jwtToken = null;
		state.isPaymentBlocked = false;
	},
	[types.INIT_ANIMALS](state, payload) {
		state.animals = [];
		state.animals.push(...payload);
	},
	[types.INIT_ANIMALS_ERROR]() {},
	[types.ANIMAL_CREATED](state, payload) {
		state.animals.push(payload);
	},
	[types.ANIMAL_NOT_CREATED]() {
	},
	[types.ANIMAL_UPDATED](state, editedAnimal) {
		state.animals = state.animals.map(a => a.id !== editedAnimal.id ? a : editedAnimal);
	},
	[types.ANIMAL_REMOVED](state, animalId) {
		state.animals = state.animals.filter(el => el.id !== animalId);
	},
	[types.ANIMAL_REMOVED_ERROR]() {},
	[types.GET_ANIMAL_TYPES](state, payload) {
		state.animalTypes = payload
	},
    [types.GET_ANIMAL_ACTIVITY]() {},
	[types.GET_ANIMAL_ACTIVITY_ERROR]() {},
	[types.INIT_CLUBS](state, payload) {
		state.clubs = [];
		state.clubs.push(...payload);
	},
	[types.CLUB_CREATED](state, payload) {
		state.clubs.push(payload);
	},
	[types.CLUB_NOT_CREATED]() {
	},
	[types.GET_CLUB]() {
	},
	[types.GET_CLUB_SESSION_DETAILS](state, payload) {
		state.clubSessionsDetails = payload
	},
	[types.GET_CLUB_ERROR]() {
	},
	[types.CLUB_UPDATED](state, editedClub) {
		state.clubs = state.clubs.map(c => c.id !== editedClub.id ? c : editedClub);
	},
	[types.CLUB_UPDATED_ERROR]() {},
	[types.CLUB_REMOVED](state, clubId) {
		state.clubs = state.clubs.filter(el => el.id !== clubId);
	},

	[types.INIT_SESSIONS](state, payload) {
		state.sessions = [];
		state.sessions.push(...payload);
	},
	[types.SESSION_CREATED](state, payload) {
		state.sessions.push(payload);
	},
	[types.SESSION_NOT_CREATED]() {},
	[types.SESSION_UPDATED]() {},
	[types.SESSION_UPDATED_ERROR]() {},
	[types.SESSION_REMOVED](state, editedSessionId) {
		state.sessions = state.sessions.filter(el => el.id !== editedSessionId);
	},
	[types.SESSION_REMOVED_ERROR]() {
	},

	[types.INIT_PATIENTS](state, payload) {
		state.patients = [];
		state.patients.push(...payload);
	},
	[types.PATIENT_CREATED](state, payload) {
		state.patients.push(payload);
	},
	[types.PATIENT_NOT_CREATED]() {
	},
	[types.PATIENT_UPDATED](state, editedPatient) {
		state.patients = state.patients.map(p => p.id !== editedPatient.id ? p : editedPatient);
	},
	[types.PATIENT_UPDATED_ERROR]() {
	},
	[types.PATIENT_REMOVED](state, patientId) {
		state.patients = state.patients.filter(el => el.id !== patientId);
	},
	[types.PATIENT_REMOVED_ERROR]() {},	
	[types.GET_PATIENT](state, patient) {	
		state.patient = patient;
	},
	[types.GET_FIRMS]() {
	},
	[types.RESET_PATIENT](state) {
		state.patient = {};
	},
	[types.GET_USER_DETAILS](state, payload) {
		state.user = payload;
	},
	[types.GET_USER_DETAILS_ERROR]() {
	},
	[types.GET_USER_DETAILS_WITH_FIRM](state, payload) {
		if (state.user) {
			state.user = {
				...state.user,
				firm: payload.firm,
				bankName: payload.bankName,
				bic: payload.bic,
				defaultSessionPrice: payload.defaultSessionPrice,
				iban: payload.iban,
				logo: payload.logo
			};
		}
	},
	[types.GET_USER_DETAILS_WITH_FIRM_ERROR]() {
	},
	[types.GET_USER_ACTIVITIES]() {
	},
	[types.GET_USER_ACTIVITIES_ERROR]() {
	},
	[types.GET_SESSION]() {
	},
	[types.GET_SESSION_ERROR]() {
	},
	[types.GET_TEAM]() {
	},
	[types.GET_MEMBER]() {
	},
	[types.GET_TEAM_ERROR]() {
	},
	[types.SAVE_AND_INVITE]() {
	},
	[types.SAVE_AND_INVITE_ERROR]() {
	},
	[types.USER_UPDATED](state, payload) {
		state.user = payload;
	},
	[types.USER_UPDATED_ERROR]() {
	},
	[types.USER_REMOVED](state) {
		state.jwtToken = null;
		localStorage.removeItem('jwtToken');
	},
	[types.USER_REMOVED_ERROR]() {},
	[types.ESTIMATION_GENERATED]() {},
	[types.ESTIMATION_GENERATED_ERROR]() {},
	[types.BILLING_GENERATED]() {},
	[types.BILLING_GENERATED_ERROR]() {},
};
