import {createStore} from 'vuex'
import { mutations } from './mutations'
import * as actionsAnimal from './actionsAnimal'
import * as actionsAnimalTypes from './actionsAnimalTypes'
import * as actionsClub from './actionsClub'
import * as actionsSession from './actionsSession'
import * as actionsPatient from './actionsPatient'
import * as getterAnimals from './getterAnimals'
import * as getterAnimalTypes from './getterAnimalTypes'
import * as getterPatients from './getterPatients.js'
import * as getterSessions from './getterSessions.js'
import * as getterClubs from './getterClubs.js'
import * as getterAuthorizations from './getterAuthorizations.js'
import * as getterUser from './getterUser.js'
import * as actionCredentilas from './actionCredentials'
import * as actionsUser from './actionsUser'
import * as actionsBilling from './actionsBilling'
import * as actionPublicPatient from './actionPublicPatient'
const state = {
	animals: [],
	animalTypes: null,
	clubs: [],
	clubSessionsDetails: {},
	sessions: [],
	patients: [],
	patient: {},
	jwtToken: localStorage.getItem('jwtToken'),
	isMobile: false,
	isPaymentBlocked: false,
	isPro: false,
	currentTherapist: {}
};

const store = createStore({
	state,
	mutations,
	actions: { ...actionCredentilas, ...actionsAnimal, ...actionsAnimalTypes, ...actionsClub, ...actionsSession, ...actionsPatient, ...actionsUser, ...actionsBilling, ...actionPublicPatient },
	getters: { ...getterAnimals, ...getterAnimalTypes, ...getterPatients, ...getterClubs, ...getterSessions, ...getterAuthorizations, ...getterUser },
})

export default store;
