<template>
	<div v-if="errors.length" class="errorMessages">
		<div>Champs requis</div>
		<ul>
			<li v-for="(error, errorIndex) in errors" v-bind:key="errorIndex">{{ error }}</li>
		</ul>
	</div>
    <form id="patient-form" class="apform">
		<h3>Informations patient</h3>
		<div id="patient-types-choices">
			<div v-for="(type, index) in patientTypes" :key="index" class="patient-type-choice">
				<input type="radio" data-cy="patient-type-input" class="patient-type-input" :name='type' :value='type' v-model="patientType">
				<label :for='type'>{{ type.toDisplay }}</label>
				<br>
			</div>
		</div>
		<div v-if="this.patientType === patientTypes.INDIVIDUAL">
			<div class="patient-type-subform">
				<div>
					<label for="lastname">Nom de famille</label>
					<input name="lastname" type="text" tabindex="1" v-model="lastname" placeholder="Nom" required autofocus />
				</div>
				<div>
					<label for="firstname">Prénom</label>
					<input name="firstname" type="text" tabindex="2" v-model="firstname"  placeholder="Prénom" required/>
				</div>
				<div>
					<label for="birthdate">Date de naissance</label>
					<select name="birthdate-select" class="birthdate-daymonth-select" v-model="birthdateDay" tabindex="3" required>
						<option v-for="val in days" :key="val.index" :value="val.value" :selected="birthdateDay === val.index">
							{{ val.value }}
						</option>
					</select>
					<select class="birthdate-daymonth-select" v-model="birthdateMonth" tabindex="4" required>
						<option v-for="val in months" :key="val.index" :value="val.value" :selected="birthdateMonth === val.index">
							{{ val.value }}
						</option>
					</select>
					<select class="birthdate-year-select" v-model="birthdateYear" tabindex="5" required>
						<option v-for="val in years" :key="val.index" :value="val.value" :selected="birthdateYear === val.value">
							{{ val.value }}
						</option>
					</select>
				</div>
			</div>
			<div>
				<label for="nbHoursToDo">Nombre d'heures à effectuer</label>
				<input name="nbHoursToDo" type="number" min="0" tabindex="6" v-model="nbHoursToDo" placeholder="Nombre d'heures"/>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="email">E-mail</label>
					<input name="email" type="text" tabindex="7" v-model="email" placeholder="E-mail" required/>
				</div>
				<div>
					<label for="phoneNumber">Téléphone</label>
					<input name="phoneNumber" type="text" tabindex="8" v-model="phoneNumber" placeholder="Téléphone" />
				</div>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="email">Entreprise</label>
					<select name="entreprise-select" class="select-patient-club-animal" v-model="selectedFirm" tabindex="9">
						<option disabled :value="{}">Aucune</option>
						<option v-for="firm in firms" :key="firm.id" :value="firm" :selected="selectedFirm.id === firm.id">
							{{ firm.firm.companyName }}
						</option>
					</select>
				</div>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="streetName">Rue</label>
					<input name="streetName" type="text" tabindex="10" v-model="streetName" placeholder="Rue" />
				</div>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="zipCode">Code postal</label>
					<input name="zipCode" type="text" tabindex="11" v-model="zipCode" placeholder="Code postal" />
				</div>
				<div>
					<label for="city">Ville</label>
					<input name="city" type="text" tabindex="12" v-model="city" placeholder="Ville" />
				</div>
			</div>
			<h3>Référent</h3>
			<div class="patient-type-subform">
				<div>
					<label for="referent_lastname">Nom de famille</label>
					<input name="referent_lastname" type="text" tabindex="13" v-model="contactLastname" placeholder="Nom"/>
				</div>
				<div>
					<label for="referent_firstname">Prénom</label>
					<input name="referent_firstname" type="text" tabindex="14" v-model="contactFirstname"  placeholder="Prénom"/>
				</div>
				<div>
					<label for="referent_email">Email</label>
					<input name="referent_email" type="text" tabindex="15" v-model="referentEmail"  placeholder="Email"/>
				</div>
				<div>
					<label for="referent_phone">Téléphone</label>
					<input name="referent_phone" type="text" tabindex="16" v-model="referentPhone"  placeholder="Téléphone"/>
				</div>
			</div>
			<h3>Responsable légal</h3>
			<div class="patient-type-subform">
				<div>
					<label for="manager_lastname">Nom de famille</label>
					<input name="manager_lastname" type="text" tabindex="17" v-model="legalManagerLastname" placeholder="Nom"/>
				</div>
				<div>
					<label for="manager_firstname">Prénom</label>
					<input name="manager_firstname" type="text" tabindex="18" v-model="legalManagerFirstname"  placeholder="Prénom"/>
				</div>
				<div>
					<label for="manager_email">Email</label>
					<input name="manager_email" type="text" tabindex="19" v-model="legalManagerEmail"  placeholder="Email"/>
				</div>
				<div>
					<label for="manager_phone">Téléphone</label>
					<input name="manager_phone" type="text" tabindex="20" v-model="legalManagerPhone" placeholder="Téléphone"/>
				</div>
				<div>
					<label for="manager_link">Lien (de parenté)</label>
					<input name="manager_link" type="text" tabindex="21" v-model="legalManagerLink"  placeholder="Lien"/>
				</div>
			</div>
			<h3>Financeur</h3>
			<div class="patient-type-subform">
				<div>
					<label for="funder_company_name">Nom</label>
					<input name="funder_company_name" type="text" tabindex="22" v-model="funderCompanyName" placeholder="Nom de l'entreprise"/>
				</div>
				<div>
					<label for="funder_lastname">Nom de famille du contact</label>
					<input name="funder_lastname" type="text" tabindex="23" v-model="funderLastname" placeholder="Nom de famille"/>
				</div>
				<div>
					<label for="funder_firstname">Prénom du contact</label>
					<input name="funder_firstname" type="text" tabindex="24" v-model="funderFirstname"  placeholder="Prénom"/>
				</div>
				<div>
					<label for="funder_email">Adresse mail du contact</label>
					<input name="funder_email" type="text" tabindex="25" v-model="funderEmail" placeholder="mail"/>
				</div>
				<div>
					<label for="funder_tel">Téléphone du contact</label>
					<input name="funder_tel" type="text" tabindex="26" v-model="funderTel"  placeholder="Téléphone"/>
				</div>
			</div>
		</div>
		<div v-if="this.patientType === patientTypes.FIRM">
			<div>
				<div>
					<label for="firm_name">Nom de l'entreprise</label>
					<input name="firm_name" type="text" tabindex="27" v-model="firm.companyName" placeholder="Nom" data-cy="firm-name" required autofocus />
				</div>
				<div>
					<label for="siren">Siren</label>
				<input name="siren" type="text" tabindex="28" v-model="firm.siren" placeholder="Siren" data-cy="firm-siren" required autofocus />
				</div>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="referent_lastname">Nom de famille du contact</label>
					<input name="referent_lastname" type="text" tabindex="29" v-model="contactLastname" data-cy="firm-lastname" placeholder="Nom"/>
				</div>
				<div>
					<label for="referent_firstname">Prénom du contact</label>
					<input name="referent_firstname" type="text" tabindex="30" v-model="contactFirstname" data-cy="firm-firstname"  placeholder="Prénom"/>
				</div>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="email">E-mail</label>
					<input name="email" type="text" tabindex="31" v-model="email" placeholder="E-mail" data-cy="firm-email" required/>
				</div>
				<div>
					<label for="phoneNumber">Téléphone</label>
					<input name="phoneNumber" type="text" tabindex="32" v-model="phoneNumber" data-cy="firm-phonenumber" placeholder="Téléphone" />
				</div>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="streetName">Rue</label>
					<input name="streetName" type="text" tabindex="33" v-model="streetName" placeholder="Rue" data-cy="firm-streetname"/>
				</div>
			</div>
			<div class="patient-type-subform">
				<div>
					<label for="zipCode">Code postal</label>
					<input name="zipCode" type="text" tabindex="34" v-model="zipCode" placeholder="Code postal" data-cy="firm-zipcode" />
				</div>
				<div>
					<label for="city">Ville</label>
					<input name="city" type="text" tabindex="35" v-model="city" placeholder="Ville" data-cy="firm-city"/>
				</div>
			</div>
			<div v-if="isEdit && this.firm.corporatePatients.length > 0">
				<p><b>Liste des patients rattachés à  l'entreprise</b></p>
				<table id="corporate-patients-list">
					<thead>
						<th>Nom</th>
						<th>Prénom</th>
					</thead>
					<tbody>
						<tr v-for="corporatePatient in firm.corporatePatients" :key="corporatePatient">
							<td>{{corporatePatient.lastname}}</td>
							<td>{{corporatePatient.firstname}}</td>
						</tr>
					</tbody>
				</table>	
			</div>
		</div>
		<div class="modal-buttons">
			<button name="cancel" type="reset" class="cancel" @click="goBack">Annuler</button>
			<button name="submit" type="submit"  @click="savePatient">Enregistrer</button>
		</div>
	</form>
</template>
<script>
import Days from '../../../services/days-month-array'
import { PATIENTS_TYPES } from '../../../services/patientTypes'

export default {
    name: 'PatientForm',
    data() {
        return {
			id: '',
			lastname: '',
			firstname: '',
			firm: {
				companyName: '',
				siren: '',
				corporatePatients: []
			},
			contactLastname: '',
			contactFirstname: '',
			referentEmail: '',
			referentPhone: '',
			legalManagerLastname: '',
			legalManagerFirstname: '',
			legalManagerEmail: '',
			legalManagerPhone: '',
			legalManagerLink: '',
			funderLastname: '',
			funderFirstname: '',
			funderCompanyName: '',
			funderEmail: '',
			funderTel: '',
			email: '',
			phoneNumber: '',
			birthdateDay: '',
			birthdateMonth: '',
			birthdateYear: '',
			streetName: '',
			city: '',
			zipCode: '',
			nbHoursToDo: '',
			selectedFirm: {},
			errors: [],
			days: Days.days(),
			months: Days.months(),
			years: Days.years(),
			isEdit: false,
			patientTypes: PATIENTS_TYPES,
			patientType: PATIENTS_TYPES.INDIVIDUAL,
			firms: []
        }
    },
	watch: {
		// whenever question changes, this function will run
		patientType(newPatientType) {
			if (newPatientType.value === PATIENTS_TYPES.INDIVIDUAL.value)
				this.getFirms()
		}
	},
	mounted() {
		if (this.$route.params.id !== undefined) { //edit existing patient
			let patient = this.$store.state.patient;
			this.isEdit = true;
			this.id = patient.id;

			this.patientType = PATIENTS_TYPES[patient.type];

			if (PATIENTS_TYPES[patient.type] === PATIENTS_TYPES.INDIVIDUAL) {
				this.getFirms().then(() => {
					this.lastname = patient.individual.lastname;
					this.firstname = patient.individual.firstname;
					this.referentEmail = patient.individual.referentEmail;
					this.referentPhone = patient.individual.referentPhone;
					this.legalManagerLastname = patient.individual.legalManagerLastname;
					this.legalManagerFirstname = patient.individual.legalManagerFirstname;
					this.legalManagerPhone = patient.individual.legalManagerPhone,
					this.legalManagerLink = patient.individual.legalManagerLink,
					this.legalManagerEmail = patient.individual.legalManagerEmail,
					this.nbHoursToDo = patient.individual.nbHoursToDo;
					this.funderLastname = patient.individual.funderLastname;
					this.funderFirstname = patient.individual.funderFirstname;
					this.funderCompanyName = patient.individual.funderCompanyName;
					this.funderEmail = patient.individual.funderEmail;
					this.funderTel = patient.individual.funderTel;
					if (patient.individual.firm) {
						this.selectedFirm = this.firms.filter(function(firm) {
							return firm.id === patient.individual.firm.id;
						})[0];
					}
					if (patient.individual.birthdate) {
						let birthdate = patient.individual.birthdate;
						this.birthdateDay = birthdate[2] < 10 ? '0'+birthdate[2] : birthdate[2];
						this.birthdateMonth = birthdate[1] < 10 ? '0'+birthdate[1] : birthdate[1];
						this.birthdateYear = birthdate[0];
					}
				})
			} 

			if (this.patientType.value === PATIENTS_TYPES.FIRM.value) {
				this.firm = patient.firm;
			}

			this.contactLastname = patient.contactLastname;
			this.contactFirstname = patient.contactFirstname;
			this.email = patient.email;
			this.phoneNumber = patient.phoneNumber;
			this.streetName = patient.address?.streetName;
			this.city = patient.address?.city;
			this.zipCode = patient.address?.zipCode;
		} else {
			this.getFirms();
		}

	},
    methods: {
		async getFirms() {
			return new Promise((resolve, reject) => {
				this.$store.dispatch({
					type: 'getFirms'
				}).then((response) => {
					this.firms = response;
					resolve();
				}).catch(() => {
					this.$toast.error(`Problème lors de la récupération des entreprises`);
					reject();
				});
			});
		},
		savePatient(e) {
			e.preventDefault();
			if (this.id === '')
                 this.addPatient(e);
			else {
				this.editPatient(e);
			}
		},
		goBack(){
			this.$router.go(-1);
		},
		checkForm(){
            this.errors = [];
			
			if (this.patientType.value === PATIENTS_TYPES.INDIVIDUAL.value) {
				if (this.lastname === '')
					this.errors.push(`Nom de famille`);
			
				if (this.firstname === '')
					this.errors.push(`Prénom`);
			}
            
			if (this.patientType.value === PATIENTS_TYPES.FIRM.value) {
				if (this.firm.companyName === '')
					this.errors.push(`Nom de l'entreprise`);
			}

			return this.errors.length === 0;
		},
		addPatient() {
			if(this.checkForm()) {
				let patient = {
					email: this.email,
					phoneNumber: this.phoneNumber,
					contactFirstname: this.contactFirstname,
					contactLastname: this.contactLastname,
					address: {
						streetName: this.streetName,
						city: this.city,
						zipCode: this.zipCode,
					},
					type: this.patientType.value
				};

				if (this.patientType.value === PATIENTS_TYPES.INDIVIDUAL.value) {
					patient = {
						...patient,
						individual : {
							birthdate: this.birthdateYear !== '' && this.birthdateMonth !== '' & this.birthdateDay !== '' ? this.birthdateYear + '-' + this.birthdateMonth + '-' + this.birthdateDay : null,
							nbHoursToDo: this.nbHoursToDo,
							referentEmail: this.referentEmail,
							referentPhone: this.referentPhone,
							legalManagerLastname: this.legalManagerLastname,
							legalManagerFirstname: this.legalManagerFirstname,
							legalManagerEmail: this.legalManagerEmail,
							legalManagerPhone: this.legalManagerPhone,
							legalManagerLink: this.legalManagerLink,
							lastname: this.lastname,
							firstname: this.firstname,
							firm: Object.keys(this.selectedFirm).length === 0 ? null : this.selectedFirm,
							funderLastname: this.funderLastname,
							funderFirstname: this.funderFirstname,
							funderCompanyName: this.funderCompanyName,
							funderEmail: this.funderEmail,
							funderTel: this.funderTel
						}
					}
				}

				if (this.patientType.value === PATIENTS_TYPES.FIRM.value) {
					patient = {
						...patient,
						firm: this.firm
					}
				}

				this.$store.dispatch({
					type: 'addPatient',
					patient,
				}).then(() => {
					this.$router.go(-1);
					this.$toast.success('Patient ajouté');
				}).catch((response) => {
					if (response.data.errorCode === 'E102')
						this.$toast.error('Adresse mail utilisée pour un patient déjà enregistré');
					else
						this.$toast.error(`Problème lors de l'enregistrement`);
				});
			}
		},
		editPatient() {
			if(this.checkForm()) {
				let patient = {
					id: this.id,
					email: this.email,
					phoneNumber: this.phoneNumber,
					contactFirstname: this.contactFirstname,
					contactLastname: this.contactLastname,
					address: {
						streetName: this.streetName,
						city: this.city,
						zipCode: this.zipCode,
					},
					type: this.patientType.value
				};

				if (this.patientType.value === PATIENTS_TYPES.INDIVIDUAL.value) {
					patient = {
						...patient,
						individual : {
							birthdate: this.birthdateYear !== '' && this.birthdateMonth !== '' & this.birthdateDay !== '' ? this.birthdateYear + '-' + this.birthdateMonth + '-' + this.birthdateDay : null,
							nbHoursToDo: this.nbHoursToDo,
							referentEmail: this.referentEmail,
							referentPhone: this.referentPhone,
							legalManagerLastname: this.legalManagerLastname,
							legalManagerFirstname: this.legalManagerFirstname,
							legalManagerEmail : this.legalManagerEmail,
							legalManagerPhone: this.legalManagerPhone,
							legalManagerLink: this.legalManagerLink,
							lastname: this.lastname,
							firstname: this.firstname,
							firm: this.selectedFirm,
							funderLastname: this.funderLastname,
							funderFirstname: this.funderFirstname,
							funderCompanyName: this.funderCompanyName,
							funderEmail: this.funderEmail,
							funderTel: this.funderTel
						}
					}
				}

				if (this.patientType.value === PATIENTS_TYPES.FIRM.value) {
					patient = {
						...patient,
						firm: this.firm
					}
				}
				this.$store.dispatch({
					type: 'updatePatient',
					patient,
				}).then(() => {
					this.$router.go(-1);
					this.$toast.success('Patient modifié');
				}).catch((response) => {
					if (response.data.errorCode === 'E102')
						this.$toast.error('Adresse mail utilisée pour un patient déjà enregistré');
					else
						this.$toast.error(`Problème lors de l'enregistrement`);
					
				});
			}
		},
		twoDigits() {
            if (parseInt(this.birthdateDay,10)<10 && (!this.birthdateDay.length || this.birthdateDay.length < 2))
                this.birthdateDay='0'+this.birthdateDay;
			if (parseInt(this.birthdateMonth,10)<10 && (!this.birthdateMonth.length || this.birthdateMonth.length < 2))
                this.birthdateMonth='0'+this.birthdateMonth;	
		}
    }
}
</script>
<style scoped>
.select-entreprise-patient-individual {
	height: 2rem;
	width: 16rem;
}

#corporate-patients-list {
	width: 70%;
	margin: 0 auto;
}

#corporate-patients-list th {
	text-align: left;
}
</style>
