import axios from 'axios';
import authHeaders from '../../../services/auth-header';

export async function getQuestions(therapistId, sessionTypeId) {
	return axios.get(getQuestionsUrl(therapistId, sessionTypeId), {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data)
		}
	})
	.catch(error => Promise.reject(error))
}

function getQuestionsUrl(therapistId, sessionTypeId) {
    return process.env.VUE_APP_BASE_AP_URL + '/therapists/' + therapistId + '/session-types/' + sessionTypeId + '/questions'
}