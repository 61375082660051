import axios from 'axios';
import authHeaders from '../services/auth-header';
import * as types from './mutation-types';
const ANIMALS_URL = process.env.VUE_APP_BASE_AP_URL+`/animals`;

export const initAnimals = ({ commit }) => {
	return axios.get(ANIMALS_URL, { headers: { 'Authorization':authHeaders()}})
        .then(response => {
          if(response.data) {
			let animals = [];
			animals.push(...response.data);
			commit(types.INIT_ANIMALS, animals);
			return Promise.resolve();
          }
        })
        .catch(error => {
			commit(types.INIT_ANIMALS_ERROR, error);
			return Promise.reject(error.response);
		})
};

export const addAnimal = async ({ commit }, event) => {
	return axios.post(ANIMALS_URL, event.animal, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
            commit(types.ANIMAL_CREATED, response.data);
			
            return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.ANIMAL_NOT_CREATED);
        return Promise.reject(error);
	});
};

export const updateAnimal = async ({ commit }, event) => {
	return axios.put(`${ANIMALS_URL}/${event.animal.id}`, event.animal, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if(response.data) {
            commit(types.ANIMAL_UPDATED, response.data);			
            return Promise.resolve(response.data);
        }
	})
	.catch(function (error) {
		commit(types.ANIMAL_UPDATED_ERROR);
        return Promise.reject(error);
	});
};

export const getAnimalActivity = async ({ commit }, event) => {
	let calledURL = `${ANIMALS_URL}/${event.animal.id}/activity`;
	if (event.animal.selectedDate !== null)
		calledURL = `${calledURL}?selectedDate=${event.animal.selectedDate}`;
	return axios.get(calledURL,  { headers: { 'Authorization':authHeaders()}})
	.then(response => {
      if(response.data) {
          commit(types.GET_ANIMAL_ACTIVITY, event.id);

          return Promise.resolve({
			nbHoursDoneCurrentWeek: response.data.nbHoursDoneCurrentWeek,
			nbHoursPlanned: response.data.nbHoursPlanned,
		});
      }
	})
	.catch(function (error) {
      commit(types.GET_ANIMAL_ACTIVITY_ERROR, event.id);
      return Promise.reject(error);
	});
};


export const deleteAnimal = async ({ commit }, event) => {
	return axios.delete(`${ANIMALS_URL}/${event.id}`, { headers: { 'Authorization':authHeaders()}})
	.then(() => {
        commit(types.ANIMAL_REMOVED, event.id);
		return Promise.resolve(event.id);
	})
	.catch(function (error) {
      return Promise.reject(error);
	});
};
