<template>
  <div class="patient-session-card">
    <div class="patient-session-header">{{ session.date }} - {{ session.startTime }}</div>
    <div class="patient-session-body">{{ session.therapist?.firstname }} {{ session.therapist?.lastname }}</div>
  </div>
</template>

<script>
export default {
    name: 'PatientSessionSmallCard',
    props: ["session"],
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.patient-session-card{
    border-radius: 5px;
    border: 1px solid var(--light-color);
}

.patient-session-header {
    background-color: var(--light-color);
    color: #FFFFFF;
    padding-left: .2rem;
    font-weight: 600;
}

.patient-session-body {
    padding-left: .2rem;
}
</style>