<template>
    <div class="billing-rows-container">
        <div v-for="(line, index) in detailedBillingContent" :key="line" class="flex-container">
            <div>
                <div class="flex-container">
                    <div>
                        <label for="quantity">Quantité</label>
                        <input name="quantity" type="number" placeholder="Quantité" class="small-input" v-model="line.quantity" @blur="computePriceWithTaxes(index)"/>
                    </div>
                    <div>
                        <label for="priceWithoutTaxes">Prix HT</label>
                        <input name="priceWithoutTaxes" type="number" placeholder="Prix HT" v-model="line.priceWithoutTaxes" @blur="computePriceWithTaxes(index)"/>    
                    </div>
                    <div v-if="withTaxes">
                        <label for="taxes">TVA (en %)</label>
                        <input name="taxes" type="number" placeholder="TVA" v-model="line.taxesAmount" @blur="computePriceWithTaxes(index)"/>
                    </div>
                    <div>
                        <label for="discount">Réduction</label>
                        <input name="discount"  class="prefix-small-input" nametype="number" placeholder="Réduction" v-model="line.discount" @blur="computePriceWithTaxes(index)"/>
                        <select name="discountType" v-model="line.discountType" class="small-input" @blur="computePriceWithTaxes(index)">
                            <option v-for="dt in discountTypes" :key="dt" :value="dt" :selected="Object.is(line.discountType,dt)">
                                {{ dt.label }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <label for="totalHT" v-if="withTaxes">Total HT</label>
                        <label for="totalHT" v-if="!withTaxes">Total net de TVA</label>
                        <input name="totalHT" placeholder="total HT" v-model="computedPrices[index].priceWithoutTaxes" disabled/>
                    </div>
                    <div v-if="withTaxes">
                        <label for="totalTTC">Total TTC</label>
                        <input name="totalTTC" placeholder="total TTC" v-model="computedPrices[index].priceWithTaxes" disabled/>
                    </div>
                </div>
                <div>
                    <textarea placeholder="Description" v-model="line.description" class="billing-line-description-textarea" rows="3" @blur="computePriceWithTaxes(index)" />
                </div>
            </div>
            <div>
                <div class="billing-row-button" @click="deleteLine(index)"><font-awesome-icon icon="xmark" title="delete row"/></div>
                <div class="billing-row-button" @click="duplicateLine(index)"><font-awesome-icon icon="copy" title="duplicate line"/></div>
            </div>
        </div>
    </div>
    <div @click="addLine" class="pointer">
        <font-awesome-icon icon="plus" title="add line" @click="addLine"/> Ajouter une ligne
    </div>


</template>
<script>

import { DISCOUNT_TYPES } from '../../../services/discountTypes';

const computeFinalPriceWithAndWithoutTaxes = (line) => {
    let priceWithoutTaxes = 0
    let priceWithTaxes = 0
    let discount = 0
    if (line?.priceWithoutTaxes !== undefined && line?.taxesAmount !== undefined && line?.quantity !== undefined) {
        let linePriceWithoutTaxes = line.priceWithoutTaxes * line.quantity 
        if (line.discount === undefined)
            line.discount = 0
        
        discount = line.discount
        if (line.discountType.value === 'PERCENT' && line.discount !== 0)
            discount = (linePriceWithoutTaxes * (line.discount/100)).toFixed(2)

        priceWithoutTaxes = (linePriceWithoutTaxes - discount).toFixed(2)
        priceWithTaxes = (priceWithoutTaxes * (1+(line.taxesAmount/100))).toFixed(2)        
    }

    return { priceWithoutTaxes, priceWithTaxes, discount }
}

export default {
    name: 'DetailedBilling',
    emits: ['dataReceived'],
    props: ['withTaxes'],
    data() {
        return {
            discountTypes: DISCOUNT_TYPES,
            discount: 0,
            discountType: DISCOUNT_TYPES[1],
            totalWithoutTaxes: 0,
            globalDiscount: 0,
            totalWithoutTaxesFinal: 0,
            totalTaxesAmount: 0,
            totalWithTaxes: 0,
            differentTaxesAmount: false,
            detailedBillingContent: [
                {
                    description: null,
                    quantity: null,
                    priceWithoutTaxes: null,
                    taxesAmount: null,
                    discount: 0,
                    discountType: DISCOUNT_TYPES[1]
                }
            ],
            computedPrices: [
                {
                    withoutTaxes: 0,
                    withTaxes: 0
                }
            ]
        }
    },
    methods: {
        deleteLine(index) {
            if (this.detailedBillingContent.length > 1)
                this.detailedBillingContent.splice(index, 1)
            this.$emit('dataReceived', this.detailedBillingContent )    
        },
        duplicateLine(index) {
            //improve that by making a copy of the source array in mounted method
            this.detailedBillingContent.push({...this.detailedBillingContent[index]})
            this.computedPrices.push({...this.computedPrices[index]})
            this.$emit('dataReceived', this.detailedBillingContent )
        },
        computePriceWithTaxes(index) {
            let currentLine = this.detailedBillingContent[index]
            if (currentLine.discount === '') currentLine.discount = 0
            
            const { priceWithoutTaxes, priceWithTaxes } = computeFinalPriceWithAndWithoutTaxes(currentLine)
            
            this.computedPrices[index].priceWithoutTaxes = priceWithoutTaxes
            this.computedPrices[index].priceWithTaxes = priceWithTaxes
            
            this.$emit('dataReceived', this.detailedBillingContent )
        },
        addLine() {
            this.detailedBillingContent.push({
                    description: '',
                    quantity: 0,
                    priceWithoutTaxes: 0,
                    taxesAmount: 0,
                    priceWithTaxes: 0,
                    discountType: DISCOUNT_TYPES[1]
                })
            this.computedPrices.push({
                priceWithoutTaxes: 0,
                priceWithTaxes: 0
            })
        }
    }
}
</script>
<style scoped>
.billing-summary {
    background-color: rgba(12,13,15,.06);
    border-radius: 0.133rem;
    margin: 16px 0;
    padding: 1em;
    max-width: 90%;
    text-align: right;
}

.billing-line-description-textarea {
    width: 97%;
}

.billing-row-button {

    border-radius: 50%;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    font-size: 1rem;
}

.billing-rows-container {
    margin-bottom: 0.5rem;
}
</style>
