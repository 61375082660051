<template>
    <h4>Vaccins</h4>
    <form class="apform">
        <div>
            <table>
                <thead>
                    <th>Type</th>
                    <th>Date</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="vaccine in health.vaccines" :key="vaccine">
                        <td><input name="vaccine-name" type="text" tabindex="10" v-model="vaccine.name" @blur="updateVaccine(vaccine)"/></td>
                        <td><input name="vaccine-date" data-cy="vaccine-date" type="date" tabindex="11" v-model="vaccine.date" @blur="updateVaccine(vaccine)"/></td>
                        <td><font-awesome-icon icon="trash-alt" @click.stop="callDeleteVaccine(vaccine)" class="trash trash-small data-table-edit-icon" /></td>
                    </tr>
                    <tr>
                        <td><input name="new-vaccine-name" type="text" tabindex="12" v-model="newVaccine.name" @blur="saveNewVaccine" placeholder="Nom du vaccin"/></td>
                        <td><input name="new-vaccine-date" data-cy="new-vaccine-date" type="date" tabindex="13" v-model="newVaccine.date"/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>

    <h4>Rendez-vous</h4>
    <form class="apform">
        <div>
            <table>
                <thead>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Commentaires</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="appointment in health.medicalAppointments" :key="appointment">
                        <td><input name="meeting-type" type="text" tabindex="14" v-model="appointment.name" @blur="updateAppointment(appointment)"/></td>
                        <td><input data-cy="meeting-date" name="meeting-date" type="date" tabindex="15" v-model="appointment.date" @blur="updateAppointment(appointment)"/></td>
                        <td><input data-cy="meeting-comments" name="meeting-comments" type="text" tabindex="16" v-model="appointment.comments" @blur="updateAppointment(appointment)"/></td>
                        <td><font-awesome-icon icon="trash-alt" @click.stop="callDeleteAppointment(appointment)" class="trash trash-small data-table-edit-icon" /></td>
                    </tr>
                    <tr>
                        <td><input name="new-meeting-type" type="text" tabindex="17" v-model="newAppointment.name" @blur="saveNewAppointment" placeholder="Type de rendez vous"/></td>
                        <td><input data-cy="new-meeting-date" name="new-meeting-date" type="date" tabindex="18" v-model="newAppointment.date"/></td>
                        <td><input data-cy="new-meeting-comments" name="new-meeting-comments" type="text" tabindex="19" v-model="newAppointment.comments"/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
</template>

<script>
import { getAnimalHealth, createVaccine, updateVaccine, deleteVaccine, createMedicalAppointment, updateMedicalAppointment, deleteMedicalAppointment} from '../services/animals'
import _ from 'lodash';

export default {
    name: 'AnimalHealth',
    props: ['id'],
    data() {
        return {
            health: {},
            newVaccine: {},
            newAppointment: {}
        }
    },
    created() {
        this.getHealth()
    },
    methods: {
        getHealth() {
            getAnimalHealth(this.id)
                .then((response) => {
                    this.health = response;
                    this.health.vaccines.forEach(v => {
                        if (v.date === null) 
                            v.date = ''
                        else {
                            let tmpDate = new Date(v.date)
                            tmpDate.setHours(12)
                            v.date = tmpDate.toISOString().substring(0, 10) //ugly hack to avoid date conversion issue
                        }
                    })
                    this.health.medicalAppointments.forEach(a => {
                        if (a.date === null)
                            a.date = ''
                        else {
                            let tmpDate = new Date(a.date)
                            tmpDate.setHours(12)
                            a.date = tmpDate.toISOString().substring(0, 10) //ugly hack to avoid date conversion issue
                        }
                    })
                })
                .catch(() => {
                    this.$toast.error('Récupération informations santé impossible')
                })
        },
        saveNewVaccine(){
            if (this.newVaccine.name !== undefined)
                createVaccine(this.id, this.newVaccine).then((response) => {
                    this.health.vaccines.push(response)
                    this.newVaccine = {}
                })
        },
        updateVaccine(vaccine) {
            updateVaccine(this.id, vaccine)
        },  
        callDeleteVaccine(vaccine) {
            deleteVaccine(this.id, vaccine.id).then(() => {
                _.remove(this.health.vaccines, (v) => v.id === vaccine.id)
            })
        },
        saveNewAppointment() {
            if (this.newAppointment.name !== undefined)
                createMedicalAppointment(this.id, this.newAppointment).then((response) => {
                    this.health.medicalAppointments.push(response)
                    this.newAppointment = {}
                })
        },
        updateAppointment(appointment) {
            updateMedicalAppointment(this.id, appointment)
        },
        callDeleteAppointment(appointment) {
            deleteMedicalAppointment(this.id, appointment.id).then(() => {
                _.remove(this.health.medicalAppointments, (a) => a.id === appointment.id)
            })
        },
    }

}
</script>
