<template>
	<button @click="redirectAddAnimal" data-cy="add-animal-button">Ajouter</button>
	<div v-if="showDeleteModal" class="modal">
		<div class="modal-content">
			<p>Etes vous sûr de vouloir supprimer cet animal ? </p>
			<div class="modal-buttons">
				<button name="cancel" class="cancel" @click="showDeleteModal = false">Annuler</button>
				<button name="submit" @click="deleteSelectedAnimal" class="button-danger">OK</button>
			</div>
		</div>
	</div>
	<table class="data-table">
		<thead class="data-table-header">
			<tr>
				<th @click="sort('name')" :class="defineCarretClass('name')">Nom</th>
				<th v-if="!isMobile" @click="sort('birthdate')" :class="defineCarretClass('birthdate')">Date de naissance </th>
				<th v-if="!isMobile" @click="sort('club')" :class="defineCarretClass('club')">Club</th>
				<th v-if="!isMobile" @click="sort('nbWorkingHoursByWeek')" :class="defineCarretClass('nbWorkingHoursByWeek')">Nb heures prévues</th>
			</tr>
		</thead>
		<tbody v-if="sortedAnimals.length > 0">
			<tr
				v-for="(animal, index) in sortedAnimals"
				v-bind:key="index"
				@mouseover="hover = index"
				@mouseleave="hover = -1"
				@click="redirectEditAnimal(animal.id)" 
			>
				<td>{{ animal.name }}</td>
				<td v-if="!isMobile" style="width:25%">{{ animal.birthdate ? animal.birthdate.join('-').replace(/(\d{4})-(\d{1,2})-(\d{1,2})/, '$3-$2-$1') : '-' }}</td>
				<td v-if="!isMobile" style="width:25%">{{ animal.club ? animal.club.name : '-' }}</td>
				<td v-if="!isMobile" style="width:15%">{{ animal.nbWorkingHoursByWeek }}</td>
				<td v-if="!isMobile" class="data-table-edit"> 
					<div v-if="hover === index" class="update-item">
						<font-awesome-icon icon="pen" class="data-table-edit-icon"/>
						<font-awesome-icon icon="trash-alt" @click="displayDeleteModal(animal.id)" class="data-table-edit-icon"/>	
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="empty-content-container" v-if="sortedAnimals.length === 0">
		Aucun animal répertorié pour l'instant
	</div>
	<div id="global-worktime" v-if="sortedAnimals.length > 0">
		<p>Temps de travail <span class="subtitle-form">(en nombre d'heures par numéro de semaine)</span></p>
		<LineChart :chartData="chartAPData" :options="chartOptions"  ref="lineChart"/>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
	// eslint-disable-next-line
	name: 'Animals',
	components: {
		LineChart
	},
	data() {
		return {
			showDeleteModal: false,
			hover: -1,
			currentSort:'name',
			currentSortDir:'asc',
			chartAPData: {},
			chartOptions: {
				plugins: {
					legend: {
						display: true
					}
				},
			},
			isMobile: this.$store.state.isMobile
		};
	},
	methods: {
		sort(s) {		
			if(s === this.currentSort) {
				this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
			}
			this.currentSort = s;
		},
		defineCarretClass(criteria) {
			if (criteria === this.currentSort) {
				if (this.currentSortDir === 'asc')
					return 'carret-up';

				return 'carret-down';
			}
			return '';
		},
		getLabelsFromActivities() {
			let output = [];
			let infosByWeek = this.animals.map(a => [a.name, a.infosByWeek]);
			for (var i=0; i < infosByWeek.length ; ++i) {
				for(var j = 0; j < infosByWeek[i][1].length; j++)
					output.push(infosByWeek[i][1][j].weekNumber+'-'+ infosByWeek[i][1][j].year);
			}

			output.sort((a, b) => {
				let yearMonthA = a.split('-');
				let yearMonthB = b.split('-');
				if (yearMonthA[1] < yearMonthB[1]) {
					return -1;
				} else if (yearMonthA[1] === yearMonthB[1]) {
				return parseInt(yearMonthA[0]) < parseInt(yearMonthB[0]) ? -1:1;
				} else {
				return 1;
				}
			});
			output = [...new Set(output)];
			return output;
		},
		getDataFromActivities(labels) {
			let infosByWeek = this.animals.map(a => {
									var r = Math.floor(Math.random() * 255);
									var g = Math.floor(Math.random() * 255);
									var b = Math.floor(Math.random() * 255);
									let color = "rgba(" + r + "," + g + "," + b + ", 0.25)";
									
									let data = [];

									for (let i = 0; i < labels.length; i++) {
										let found = false;
										for (let j = 0; j < a.infosByWeek.length; j++) {
											let searchingWeek = a.infosByWeek[j].weekNumber+'-'+a.infosByWeek[j].year;
											if (labels[i] === searchingWeek) {
												data.push(a.infosByWeek[j].nbHours);
												found = true;
												break;
											}
										}
										if (!found) {
											data.push(0);
										}
									}

									return {
										label: a.name, 
										data: data,
										fill: true,
										backgroundColor: [color],
										borderColor: [color]
									}
								});
			return infosByWeek;
		},
		updateChart() {
			let labels = this.getLabelsFromActivities()
			this.chartAPData = {
				labels: labels,
				datasets: this.getDataFromActivities(labels),
			}
			this.$refs.lineChart.value.updateChart();
		},
		sendInitAnimals() {
			this.$store.dispatch('initAnimals')
			.then(() => {
				this.updateChart();
            }).catch(response => {
                if (response.status === 401) {
                    this.$store.dispatch('logout');
					this.$router.push('/');
				}
            });
		},
		redirectAddAnimal() {
			this.$router.push({name: 'CreateAnimal'})
		},
		redirectEditAnimal(animalId) {
			this.$router.push({name: 'EditAnimal', params: { id : animalId }})
		},
        displayDeleteModal(animalId) {
            this.animalId = animalId;
            this.showDeleteModal = true;
        },
		deleteSelectedAnimal() {
			this.$store.dispatch({
				type: 'deleteAnimal',
				id: this.animalId,
			}).then(() => {
				this.$toast.success(`Animal correctement supprimé`);
			}).catch((error) => {
				if (error.response.data.errorCode === 'E106') {
					this.$toast.error(`Animal déjà supprimé`);
					this.$router.push('/pros/animals');
				} else {
					this.$toast.error(`Erreur lors de la suppression de l'animal`);
				}
			});
			this.showDeleteModal = false;
		}
	},
	computed: {
		...mapState(['animals', 'clubs']),
		sortedAnimals() {
			let animals = this.$store.state.animals;
			return animals.sort((a,b) => {
				let modifier = 1;
				if(this.currentSortDir === 'desc') modifier = -1;
				if (this.currentSort === 'club') {
					if(a.club.name < b.club.name) return -1 * modifier;
					if(a.club.name > b.club.name) return 1 * modifier;
				} else {
					if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
				}
				return 0;
			});
		}
	},
	mounted() {
		this.sendInitAnimals();
	},
};
</script>

<style scoped>
#global-worktime {
	margin: 3rem auto 0 auto;
	max-width: 80%;
}
</style>