<template>
<div id="resetPassword">
    <h3>Réinitialiser son mot de passe</h3>
    <p>Entrez votre adresse e-mail et nous vous enverrons un lien de récupération</p>
    <br/>
    <form>
      <input id="reset-email" type="text" tabindex="1" v-model="email" placeholder="Email"/>
      <div id="resetPassword-button-container">
        <button id="resetPassword-button" name="submit" type="submit" @click="askForResetPassword">Envoyer le lien</button>
      </div>
    </form>
</div>    
</template>

<script>
export default ({
  name: 'AskForResetPassword',
  data() {
    return {
      email: ''
    }
  }, 
  methods : {
    askForResetPassword(e) {
      e.preventDefault();
      this.$store.dispatch({
              type: 'askForResetPassword', 
              email: this.email
      }).then(()=> {
        this.$toast.success('Un mail de réinitialisation vient de vous être envoyé');
        this.$router.push('/');
      }).catch((error) => {
        if (error.data.errorCode === "E101")
          this.$toast.error("Utilisateur introuvable");
      });
    }
  }
})
</script>

<style scoped>
#resetPassword {
  text-align: center;
  margin: 0 auto;
  margin-top: 5rem;
}

#reset-email {
  height: 2.3rem;
  width: 16rem;
  font-size: var(--font-regular);
  border: 0;
  border-bottom: 0.063rem solid #BBB;
  margin-right: 2rem;
}

#resetPassword-button-container {
  justify-content: center;
}

#resetPassword-button {
  border: 1px solid var(--secondary-color);
  display: table-cell;

}
</style>
