<template>
    <h3 id="details-title">Détails des paiements dûs au club</h3>
    <table class="data-table">
        <thead class="data-table-header">
            <td>Date</td>
            <td>Heure début</td>
            <td>Heure Fin</td>
            <td>Dû</td>
            <td>Patient</td>
            <td>A payer par le patient</td>
            <td>Séance payée</td>
        </thead>
        <tbody>
            <tr v-for="session in sessions" :key="session">
                <td>{{ session.date }}</td>
                <td>{{ session.startTime }}</td>
                <td>{{ session.endTime }}</td>
                <td>{{ session.animalRent + session.roomRent }}</td>
                <td>{{ session.patient ? (session.patient.type === patientTypes.INDIVIDUAL.value ? session.patient.individual.lastname + ' ' + session.patient.individual.firstname : session.patient.firm.companyName) : '' }}</td>
                <td>{{ session.taxesPaidByPatient }}</td>
                <td><input type="checkbox" v-model="session.paid" disabled="disabled"/></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td><b>Somme</b></td>
                <td>{{ computePricesSum() }}</td>
                <td><b>A payer par les patients</b></td>
                <td>{{ computeTaxesToBePaidByPatientSum() }}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>Restant à payer par les patients</b></td>
                <td>{{ computeTaxesPaidByPatientSum() }}</td>
            </tr>
        </tbody>
    </table>

    <button name="cancel" type="reset" tabindex="15" class="cancel" @click="goBack">Retour</button>
</template>

<script>
import { PATIENTS_TYPES } from '../../../services/patientTypes';

export default ({
    name: 'ClubAmountDueDetails',
    data() {
        return {
            id: {},
            sessions: [],
            patientTypes: PATIENTS_TYPES,
        }
    },
    mounted() {
        this.sessions = this.$store.getters.clubSessionsDetails;
    },
    methods: {
        computePricesSum() {
            if (this.sessions !== [])
                return this.sessions.reduce((n, {animalRent, roomRent}) => n + animalRent + roomRent, 0)
            return 0
        },
        computeTaxesToBePaidByPatientSum() {
            if (this.sessions !== [])
                return this.sessions.reduce((n, {taxesPaidByPatient}) => n + taxesPaidByPatient, 0)
            return 0
        },
        computeTaxesPaidByPatientSum() {
            if (this.sessions !== [])
                return this.sessions.filter(session => !session.paid).reduce((n, {taxesPaidByPatient}) => n + taxesPaidByPatient, 0)
            return 0
        },
        goBack() {
            this.$router.go(-1);
        }
    }
})
</script>

<style>
#details-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
</style>