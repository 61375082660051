<template>
	<div v-if="!isMobile">
		<div class="angles-div">
			<font-awesome-icon icon="angles-left" class='ap-angle' v-if="hideMenu===false" @click="hideMenu = !hideMenu"/>
			<font-awesome-icon icon="angles-right" class='ap-angle' v-else @click="hideMenu = !hideMenu"/>
		</div>
		<div class="menu" v-if="hideMenu===false">
			<h4>
				<router-link class="link" to="/pros/user"><font-awesome-icon icon="user-pen" title="Informations personnelles" class="icon-menu-before"/>Mes informations</router-link>
				<router-link class="link" to="/pros/personalization"><font-awesome-icon icon="sliders" title="Préférences" class="icon-menu-before"/>Mes préférences</router-link>
				<router-link class="link" to="/pros/vacations"><font-awesome-icon icon="clipboard-user" title="Absences" class="icon-menu-before"/>Absences</router-link>
			</h4>
		</div>
		<div class="menu" v-else>
			<h4>
				<router-link class="link" to="/pros/user"><font-awesome-icon icon="user-pen" alt="Informations personnelles"/></router-link>
				<router-link class="link" to="/pros/personalization"><font-awesome-icon icon="sliders" alt="Préférences"/></router-link>
				<router-link class="link" to="/pros/vacations"><font-awesome-icon icon="clipboard-user" title="Absences"/></router-link>
			</h4>
		</div>
	</div>
	<div v-else>
		<div class="sidenav" :class="{'sidenav': true, 'activeSidenav': showBurger}">
			<a id="closeBtn" href="#" class="close" @click="showBurger = false">×</a>
			<ul>
				<router-link class="link" to="/pros/user" @click="showBurger=false">Mes informations</router-link>
				<router-link class="link" to="/pros/personalization" @click="showBurger=false">Mes préférences</router-link>
				<router-link class="link" to="/pros/vacations" @click="showBurger=false">Absences</router-link>
			</ul>
		</div>
		<a href="#" id="openBtn" @click="showBurger = true">
			<span class="burger-icon">
				<span></span>
				<span></span>
				<span></span>
			</span>
		</a>
	</div>

</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'SettingsMenu',
    computed: {
		...mapState(['isMobile']),
	},
    data() {
        return {
            hideMenu: false,
            showBurger: false,
        }
    }
}
</script>

<style>
    @import '../../../assets/styles/menu.css'
</style>
