import axios from 'axios';
import authHeaders from '../services/auth-header';

const SESSIONS_URL = process.env.VUE_APP_BASE_AP_URL+`/sessions`;

export async function sendAudio(sessionId, audioFile) {
    const audioBody = {
        "content": audioFile
    };
    return axios.patch(`${SESSIONS_URL}/${sessionId}/audios`, audioBody, {headers : { 'Authorization':authHeaders()}})
	.then((response) => {
        return Promise.resolve(response.data);
    })
	.catch(error => {
      return Promise.reject(error);
	});
}