import axios from 'axios';
import router from '../router'

axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response !== undefined && error.response.data.errorCode === 'E123') {
        router.push('/pros/payment-blocked');    
      } else {
        return Promise.reject(error);
      }
    }
);