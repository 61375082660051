<template>
    <h3>Mes absences</h3> 
    <div>
        <form class="apform">
            <label name="from">De</label>
            <input name="from" type="datetime-local" v-model="from"/>
            <label name="to">A</label>
            <input name="to" type="datetime-local" v-model="to"/>
            <div class="table-colors-buttons modal-buttons">
                <button name="cancel" class="cancel" type="reset" tabindex="15" @click="backToCalendar">Annuler</button>
                <button name="submit" type="submit" tabindex="16" @click="updateVacations">Valider</button>
            </div>
        </form>
    </div>
</template>

<script>
import { saveVacations, getVacations } from '../../../services/personalization'
export default {
    name: 'APVacations',
    data() {
        return {
            from: '',
            to: ''
        }
    },
    mounted() {
        getVacations().then(response => {
            this.from = new Date(response.from*1000).toISOString().replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}).+/, '$1-$2-$3T$4:$5');
            this.to = new Date(response.to*1000).toISOString().replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}).+/, '$1-$2-$3T$4:$5');
        })
    },
    methods: {
        backToCalendar() {
			this.$router.push({name: 'Sessions', state: {initialDate: this.startDate}});
		},
        updateVacations(e) {
            e.preventDefault()
            saveVacations(this.from, this.to).then(() => {
                this.$toast.success('absences mises à jour')
            })
        }
    }
}
</script>
