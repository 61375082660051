<template>
    <div>
        <h3 class="header-title">{{club.name}}</h3>
        <div><span class="contact-details-title">Email:</span>{{club.email}}</div>
        <div><span class="contact-details-title">Numéro de téléphone:</span>{{club.phoneNumber}}</diV>
        <div v-if="club.address"><span class="contact-details-title">Adresse :</span>{{ club.address.city ? club.address.streetName + '-' + club.address.zipCode + '-' + club.address.city : '' }}</div>
        <div><span class="contact-details-title">Montant dû par séance :</span>{{club.amountPerSession}}</div>
    </div>
    <div id="club-date-selection">
        <label for="startDate">Entre le </label>
        <input name="startDate" type="date" tabindex="1" v-model="startDate" @change="getClubDetails">
        <label for="endDate"> et le </label>
        <input name="endDate" type="date" tabindex="2" v-model="endDate" @change="getClubDetails">
    </div>
    <div id="clubs-patient" v-if="patients.size == 0">
        <p>Aucun patient n'a eu de séance dans ce club</p>
    </div>
    <div id="clubs-patient" v-else>
        <div id="clubs-patients-list">
            <label for="patient-select">Patient </label>
            <select
                class="zoo-input select-patient-club-animal"
                name="patients"
                id="patient-select"
                v-model="selectedPatients"
                tabindex="3"
                multiple
                @change="filterSessions"
            >
                <option
                    v-for="(patient, index) in patients.values()"
                    v-bind:value="patient"
                    v-bind:key="index"
                >
                    {{ patient.type === patientTypes.INDIVIDUAL.value ? patient.individual.lastname + ' ' + patient.individual.firstname : patient.firm.companyName }}

                </option>
            </select>
        </div>
        <div @click="clearSelectedPatients" class="times-circle-container delete-patient-icon-container pointer">
            <font-awesome-layers class="fa-lg">
                <font-awesome-icon icon="fa-regular fa-circle" class="times-circle-style" />
                <font-awesome-icon icon="fa-times" transform="shrink-4" class="times-circle-style" />
            </font-awesome-layers>
        </div> 
    </div>
    <div id="club-sessions">
        <div v-if="!filteredSessions || filteredSessions.length===0">
            <p>Aucune séance enregistrée pour l'instant.</p>
        </div>
        <div v-else>
            <div><b>Heure(s) planifiée(s) :</b> {{effectiveHours.toFixed(2)}}</div>
            <div class="amount-du"><b>Montant dû au club (en euros):</b>  {{clubAmountDue}}<font-awesome-icon class="amount-du-circle" icon="circle-plus" title="plus d'infos" @click="goToAmountDueDetails()"/></div>

            <table class="data-table">
                <thead class="data-table-header">
                    <th @click="sort('date')" :class="defineCarretClass('date')">Date</th>
                    <th>Début</th>
                    <th>Fin</th>
                    <th>Patient</th>
                    <th>Animal</th>
                    <th>Patient présent</th>
                </thead>
                <tbody>
                    <tr v-for="(session, index) in filteredSessions" v-bind:key="index">
                        <td>{{ session.date }}</td>
                        <td>{{ session.startTime }}</td>
                        <td>{{ session.endTime }}</td>
                        <td>{{ session.patient ? (session.patient.type === patientTypes.INDIVIDUAL.value ? session.patient.individual.lastname + ' ' + session.patient.individual.firstname : session.patient.firm.companyName) : '' }}</td>
                        <td>{{session.animal? session.animal.name: ''}}</td>
                        <td><input type="checkbox" v-model="session.patientPresent" disabled="disabled"/></td>
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>
</template>
<script>
import { PATIENTS_TYPES } from '../../../services/patientTypes';
export default {
    // eslint-disable-next-line
    name: 'Club',
	data() {
        return {
            club: {},
            effectiveHours: 0,
            pricesSum: 0,
            clubAmountDue: 0,
            clubId: 0,
            startDate: '',
            endDate: '',
            selectedPatients: [],
            filteredSessions: [],
            patients: new Map(),
            sessions: [],
            patientTypes: PATIENTS_TYPES,
            currentSort: 'date',
            currentSortDir: 'asc'
        }
    },
    methods: {
        sort(s) {		
			if(s === this.currentSort) {
				this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
			}
			this.currentSort = s;

            this.filteredSessions = this.filteredSessions.sort((a,b) => {
                let modifier = 1;
                if (this.currentSortDir === 'desc') modifier = -1;
                let left = a[this.currentSort];
                let right = b[this.currentSort];
                if (this.currentSort === 'date') {
                    left = new Date(left);
                    right = new Date(right);
                }
				if(left < right) return -1 * modifier;
				if(left > right) return 1 * modifier;
                return 0;
            })

            this.$store.dispatch({
                    type: 'updateClubSessionDetails',
                    sessions : this.filteredSessions
                })
		},
        defineCarretClass(criteria) {
			if (criteria === this.currentSort) {
				if (this.currentSortDir === 'asc')
					return 'carret-up';

				return 'carret-down';
			}
			return '';
		},
        editPatient() {
            this.$router.push('/pros/patient')
        },
        getClubDetails() {
            this.$store.dispatch({
                type: 'getClubDetails',
                id: this.clubId
            }).then( response => {
                this.club = response;
                this.$store.dispatch({
                    type: 'getClubSessionDetails',
                    askedInfos: {
                        id: this.clubId,
                        startDate: this.startDate === '' ? null : this.startDate,
                        endDate: this.endDate === '' ? null : this.endDate
                    }
                }).then(response => {
                    this.club.sessions=response.sessions;
                    this.filteredSessions = this.club.sessions;
                    this.updateHoursAndPriceVue();
                    this.getListPatients();
                    this.sort('date');
                });
            });
		},
        computeEffectiveHours() {
            //return this.club.sessions.reduce((n, {price}) => n + price, 0);
            let effectiveTimeInMs = this.filteredSessions.reduce((n, {date, startTime, endTime})=> {
                let startDate = new Date(date + " " + startTime);
                let endDate = new Date(date + " " + endTime);
                return n+(endDate-startDate);
            }, 0);
            this.effectiveHours = effectiveTimeInMs / 1000 / 60 / 60;
        },
        computePricesSum() {
            this.pricesSum = this.filteredSessions.reduce((n, {price}) => n + price, 0);
        },
        computeClubAmountDue() {
            this.clubAmountDue = this.filteredSessions.reduce((n, {animalRent, roomRent}) => n + animalRent + roomRent, 0);
        },
        getListPatients() {
            this.patients.clear();
            this.club.sessions.map( (session) => {
                if (session.patient && !this.patients.has(session.patient.id))
                    this.patients.set(session.patient.id, session.patient);
            });
        },
        filterSessions() {
            if (this.selectedPatients.length !== 0)
                this.filteredSessions = this.club.sessions.filter( s => {
                    let found = this.selectedPatients.find(p => {
                        if (s.patient && p)
                            return s.patient.id === p.id
                        return false;
                    });
                    return found !== undefined;
                });
            else
                this.filteredSessions = this.club.sessions;
            
            this.$store.dispatch({
                    type: 'updateClubSessionDetails',
                    sessions : this.filteredSessions
                })
            this.updateHoursAndPriceVue();
        },
        updateHoursAndPriceVue() {
            this.computeEffectiveHours();
            this.computePricesSum();
            this.computeClubAmountDue();
        },
        clearSelectedPatients() {
            this.selectedPatients=[];
            this.filterSessions();
        },
        goToAmountDueDetails() {
            this.$router.push({'name': 'ClubAmountDueDetails', 
                                'query': {
                                    startDate: this.startDate,
                                    endDate: this.endDate,
                                    clubId: this.clubId
                                }})
        }
    },
    mounted() {
        this.clubId = this.$route.params.id;
        let date = new Date(new Date().toLocaleString("en-US", {timeZone: "UTC"}));
        date.setMonth(date.getMonth()+1);
        date.setDate(0)
        this.endDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3')
        date.setMonth(date.getMonth()-2);
		date.setDate(1);
		this.startDate = date.toISOString().replace(/(\d{4})-(\d{2})-(\d{2}).+/, '$1-$2-$3')
        this.getClubDetails();
    }
}
</script>

<style scoped>
#clubs-patient {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display:flex;
}

#clubs-patient select {
    height: 10rem;
    width: 15rem;
    margin-left: 1rem;
}

#clubs-patients-list {
    display: flex;
}

.delete-patient-icon-container {
    margin-left: 1rem;
}
.amount-du {
    margin-bottom: 2rem;
}
.amount-du-circle {
    margin-left: 1rem;
    font-size: 20px;
}
</style>