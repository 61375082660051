<template>
    <div id="nb-therapists">
      <p v-if="therapists.length > 1">{{ therapists.length }} praticiens peuvent vous intéresser</p>
      <p v-else-if="therapists.length == 1">1 praticien(ne) peut vous intéresser</p>
      <p v-else>Aucun praticien trouvé</p>
    </div>    
    <div id="therapists-locations-container">
        <div id="therapists-list">
            <APTherapist v-for="therapist in therapists" v-bind:key="therapist" :therapist="therapist" :class=" therapist === mouseOverTherapist ? 'therapistborder':''" />
        </div>
        <div id="locations-list">
            <l-map
            :zoom="zoom"
            :minZoom="minZoom"
            :maxZoom="maxZoom"
            :zoomAnimation="true"
            :center="[47.41322, -1.219482]"
            ref="map"
          >
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ></l-tile-layer>
            <l-control-layers />
            <l-marker v-for="therapist in therapists" :key="therapist" :lat-lng=convertLatlon(therapist) @mouseover="displaySelected(therapist)">
                <l-tooltip> {{  therapist.firstname + ' ' + therapist.lastname }}</l-tooltip>
            </l-marker>

          </l-map>
        </div>
    </div>
</template>
<script>
import "leaflet/dist/leaflet.css"
import { useRoute } from 'vue-router'
import APTherapist from './APTherapist.vue'
import { getTherapists } from '../../services/therapists'
import {   
  LMap,
  LTileLayer,
  LControlLayers,
  LMarker,
  LTooltip
} from "@vue-leaflet/vue-leaflet";

export default {
    name: 'APTherapists',
    data() {
        return {
            therapistName: '',
            location: '',
            therapists: [],
            zoom: 6,
            minZoom:2,
            maxZoom: 15,
            geojson: {
              type: "FeatureCollection",
              features: [
                // ...
              ],
            },
            geojsonOptions: {
              // Options that don't rely on Leaflet methods.
            },
            mouseOverTherapist: {}
        }
    },
    components: {
        APTherapist,
        LMap,
        LTileLayer,
        LControlLayers,
        LMarker,
        LTooltip
    },
    async beforeMount() {
        // HERE is where to load Leaflet components!
        const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");

        // And now the Leaflet circleMarker function can be used by the options:
        this.geojsonOptions.pointToLayer = (feature, latLng) => circleMarker(latLng, { radius: 8 });
        //this.mapIsReady = true;
    },
    mounted() {
        const route = useRoute()
        this.therapistName = route.query.name
        this.location = route.query.location
        getTherapists(this.therapistName, this.location)
            .then((response) => {
                this.therapists = response   
            })
            .catch(() => {

            })           
    },
    watch: {
        '$route' (to) {
            if (to.name !== 'APTherapistAvailability')
              getTherapists(to.query.name, to.query.location)
                  .then((response) => {
                      this.therapists = response   
                  })     
        }
    },
    computed: {
        iconUrl() {
            return `https://placekitten.com/${this.iconWidth}/${this.iconHeight}`;
        },
        iconSize() {
            return [this.iconWidth, this.iconHeight];
        },
    },
    methods: {
      log(a) {
        console.log(a);
      },
      displaySelected(therapist) {
        this.mouseOverTherapist = therapist
      },
      convertLatlon(therapist) {
        if (therapist.latitude !== null && therapist.longitude !== null) {
          return [therapist.latitude, therapist.longitude]
        }
        return [48.866669,2.33333]; //Paris
      },
      changeIcon() {
        this.iconWidth += 2;
        if (this.iconWidth > this.iconHeight) {
          this.iconWidth = Math.floor(this.iconHeight / 2);
        }
      },
    }
}
</script>

<style scoped>
#nb-therapists {
  margin-left: 1rem;
}

#therapists-list {
    width: 40%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#therapists-locations-container {
    display: flex;
}

#locations-list {
    width: 70%;
    min-height: 35rem;
    margin-right: 2rem;
}

.therapistborder {
  border: 0.25rem solid var(--xl-light-color);
}

@media (max-width: 800px) {
  #therapists-locations-container {
    display: block;
  }

  #therapists-list {
    width: 100%;
  }
}
</style>
